import { useContext } from "react"
import { ControllableContext } from "../containers/Controllable"

const useControllable = () => {
  const { interactable } = useContext(ControllableContext)

  return {
    // Todo: There are cases where we want to be able to set multiple
    // callbacks for the same object and interaction type.
    // We should also consider using refs rather the mesh object3d since
    // these objects can change on component updates.
    setInteractable: (object3D, type, callback) => {
      const previousInteractable = interactable.current.filter((object) => object.object3D === object3D)

      const includesType = previousInteractable.length !== 0 && !previousInteractable[0].types.includes(type)

      if (includesType) {
        const index = interactable.current.indexOf(previousInteractable.shift())
        interactable.current[index].types.push(type)
        interactable.current[index].callbacks.push(callback)
      } else {
        interactable.current.push({
          object3D,
          types: [type],
          callbacks: [callback],
        })
      }
    },

    clearInteractable: (object3D, type) => {
      const existing = interactable.current.filter((object) => object3D === object.object3D).shift()
      if (existing && existing.types && existing.types.includes(type)) {
        const index = interactable.current.indexOf(existing)
        const typeIndex = interactable.current[index].types.indexOf(type)
        interactable.current[index].types.splice(typeIndex, 1)
        interactable.current[index].callbacks.splice(typeIndex, 1)
      }
    },
  }
}

export default useControllable
