import React, { useState, useEffect } from "react"
import { Group, Rect, Text } from "react-konva"
import { formatAMPM, getTimeInEST } from "../helpers/TimeHelper"
import CanvasImage from "./CanvasImage"
import settingsIcon from "../../images/icons/svg/settings.svg"

const nowAMPM = formatAMPM(getTimeInEST(new Date()))

const UserListHeader = ({ width, height, fill, cornerRadius, fontColor, updateTextures, toggleSettings, ...props }) => {
  const [time, setTime] = useState(nowAMPM)

  useEffect(() => {
    const timeInterval = setInterval(() => {
      const newTime = formatAMPM(getTimeInEST(new Date()))
      if (time !== newTime) setTime(newTime)
    }, 2000)

    return () => clearInterval(timeInterval)
  }, [])

  useEffect(updateTextures, [time])

  return (
    <Group>
      <Rect width={width} height={height} fill={fill} cornerRadius={cornerRadius} />
      <Text {...props} width={width} height={height} fill={fontColor} text={`${time} EST`} />
      <CanvasImage width={25} height={25} x={15} y={10} src={settingsIcon} onMouseUp={toggleSettings} />
    </Group>
  )
}

export default UserListHeader
