// The DatePicker is based on moment, so we need to keep it for now.
import moment from "moment"

import "moment/locale/en-gb"
import "moment/locale/en-ca"
import "moment/locale/es"
import "moment/locale/fr"
import "moment/locale/fr-ca"

import i18n from "i18next"

import "./init" // Make sure i18n is loaded.

moment.locale(i18n.languages?.[0] ?? "en")

const localeData = moment.localeData()

const firstDayOfWeek = localeData.firstDayOfWeek()
const weekdaysShort = [...localeData.weekdaysShort()]

if (firstDayOfWeek !== 0) {
  const tmp = weekdaysShort[0]
  weekdaysShort.shift()
  weekdaysShort.push(tmp)
}

export const momentLocaleData = {
  weekdaysShort: weekdaysShort.map((elem) => elem.replace(/\.$/, "")),
  months: localeData.months(),
  firstDayOfWeek,
}
