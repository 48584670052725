import config from "./config"
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"

const cfg = {
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  databaseURL: config.firebase.databaseURL,
  projectId: config.firebase.projectId,
}

const app = initializeApp(cfg)

export const auth = getAuth(app)
