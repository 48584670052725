import React, { createContext, useContext, useEffect, useReducer, useRef } from "react"
import { MediaContext } from "./Media"
import { UserContext } from "./User"
import { AuthContext } from "./Auth"
import logger from "../etc/logger"
import { enums, useGetInstructorQuery, useListUsersQuery } from "../libs/api"
import displayFullName from "../helpers/displayFullName"
import { skipToken } from "@reduxjs/toolkit/query"

export const PeerContext = createContext()
const throttleTime = 2000 // in seconds
const nameMaxLength = 30

const getIdenticalAttendees = (attendees, attendee) =>
  Boolean(attendees.filter(({ id, name }) => id === attendee.id && name === attendee.name))

const sortByParens = (arr, label) => {
  return arr.sort((a, b) => {
    const aParens = a.ui?.parens
    const bParens = b.ui?.parens
    if (aParens?.endsWith(label) && !bParens?.endsWith(label)) {
      return -1
    } else if (!aParens?.endsWith(label) && bParens?.endsWith(label)) {
      return 1
    }
    return 0
  })
}

const sortByMuted = (arr) => {
  return arr.sort((a, b) => {
    const aMuted = a.muted
    const bMuted = b.muted
    if (aMuted && !bMuted) {
      return 1
    } else if (!aMuted && bMuted) {
      return -1
    }
    return 0
  })
}

const sortByDisplayName = (arr) => {
  return arr.sort((a, b) => {
    // Convert to lowercase and remove non-alphanumeric characters (for sorting only).
    const aName = a.ui?.displayName?.replace(/[^A-Za-z0-9]/g, "")?.toLowerCase() ?? ""
    const bName = b.ui?.displayName?.replace(/[^A-Za-z0-9]/g, "")?.toLowerCase() ?? ""
    if (aName < bName) {
      return -1
    } else if (aName > bName) {
      return 1
    }
    return 0
  })
}

const getUniqueAttendees = (peers, users, event, instructor, userId, connectionId) => {
  const unique = []
  const duplicates = []
  const { HOST, IMMERTEC_EMPLOYEE, CUSTOMER_SUPPORT } = enums.EventUserRole
  const instructorName = instructor?.name || event.guestInstructors[0].name
  const attendees = peers.reduce((attendees, peer) => {
    const user = users.find(({ id }) => peer.uid === id) || users[0]
    const eventUser = event.users.find(({ user }) => peer.uid === user)
    attendees.push({
      ...user,
      name: peer.client.type === enums.ClientType.MS ? displayFullName(instructorName) : displayFullName(user.name),
      connectionId: peer.id,
      eventRole: eventUser?.role || enums.EventUserRole.TRAINEE,
      client: peer.client,
      muted: peer.muted,
    })
    return attendees
  }, [])
  const userPeer = attendees.filter(
    (a) =>
      (userId === a.id || a.connectionId === connectionId) &&
      a.client.type !== enums.ClientType.MS &&
      a.eventRole !== HOST,
  )?.[0]

  // 1. Check for duplicate peers with the same name and id.
  attendees.forEach((attendee) => {
    const identicalAttendees = getIdenticalAttendees(attendees, attendee)

    if (!identicalAttendees.length || identicalAttendees.length === 1) {
      return unique.push(attendee)
    }

    if (identicalAttendees.length > 1) {
      const uniqueDuplicates = [...new Set(duplicates)]
      if (!uniqueDuplicates.includes(attendee.id)) {
        duplicates.push(attendee.id)
      } else {
        unique.push(attendee)
      }
    }
  })

  // 2. Update attendee name to be displayed.
  const updatedPeerWithDisplayName = unique.map((attendee) => {
    const isStreamer = attendee.client.type === enums.ClientType.MS
    const isHost = attendee.eventRole === HOST
    const fullName =
      attendee.name.length > nameMaxLength ? attendee.name.substring(0, nameMaxLength - 1) + "..." : attendee.name
    const roleLabel = userClassification[attendee.eventRole]
    const peerIsUser = attendee.id === userPeer?.id
    const peerIsImmertecEmployee = attendee.eventRole === IMMERTEC_EMPLOYEE
    const userIsImmertecEmployee = userPeer?.eventRole === IMMERTEC_EMPLOYEE
    const userIsCustomerSupport = userPeer?.eventRole === CUSTOMER_SUPPORT
    const userLogic = userIsImmertecEmployee || userIsCustomerSupport
    const hiddenPeerName = !peerIsUser && !userLogic && peerIsImmertecEmployee && !isStreamer && !isHost
    const displayName = hiddenPeerName ? roleLabel : fullName
    let parens = ""
    if (isHost && !isStreamer) {
      parens = "(Host)"
    } else if (isStreamer) {
      parens = "(Instructor)"
    } else if (peerIsUser && !isStreamer) {
      parens = "(You)"
    }

    return {
      ...attendee,
      ui: { displayName, parens, nameIsHidden: hiddenPeerName },
    }
  })

  // 3. Sort.
  // NOTE: You need to sort last label first in order to get the correct order.
  // Sort muted/unmuted.
  const sortedByMuted = sortByMuted(updatedPeerWithDisplayName)

  // Sort alphabetically.
  const sortedDisplayName = sortByDisplayName(sortedByMuted)

  // Sort by group (Instructor, Host, You).
  const sortedByHost = sortByParens(sortedDisplayName, "(Host)")
  const sortedByInstructor = sortByParens(sortedByHost, "(Instructor)")
  const sortedByYou = sortByParens(sortedByInstructor, "(You)")
  return sortedByYou
}

const peerReducer = (peers, action) => {
  switch (action.type) {
    case "peer-add-all":
      return action.payload
    case "peers-speaking": {
      const peersSpeaking = action.payload.speaking
      const peersMapped = action.payload.mapped
      const activeSpeakers = peers.filter((peer) => peer.speaking === true).map((p) => p.connectionId)
      if (
        activeSpeakers.length === peersSpeaking.length &&
        activeSpeakers.every((connectionId) => peersSpeaking.includes(connectionId)) &&
        peersSpeaking.every((connectionId) => activeSpeakers.includes(connectionId))
      ) {
        // If speaking peers are same, do not trigger state update
        return peers
      }
      const oldPeers = peers.map((p) => Object.assign({}, p))
      const newPeers = peers.map((p) => {
        const mps = peersMapped[p.connectionId] && peersMapped[p.connectionId].find((id) => peersSpeaking.includes(id))
        p.speaking = peersSpeaking.includes(p.connectionId) || !!mps
        return p
      })
      const peersIdentical = newPeers.every((newPeer) =>
        oldPeers.find((p) => p.connectionId === newPeer.connectionId && p.speaking === newPeer.speaking),
      )
      if (peersIdentical) {
        return peers
      }
      return newPeers
    }
    case "peer-mute-toggle":
      return peers.map((peer) => {
        if (peer.connectionId === action.payload.id) {
          peer.muted = action.payload.mute
          peer.muteLockedByModerator = action.payload.muteLockedByModerator
        }
        return peer
      })
  }
}

const userClassification = {
  UNKNOWN: "Unknown",
  TRAINEE: "Participant",
  PARTICIPANT: "Participant",
  IMMERTEC_EMPLOYEE: "Immertec Employee",
  HOST: "Host",
}

const PeerContainer = ({ children }) => {
  const [peers, dispatchPeers] = useReducer(peerReducer, [])
  const { socket } = useContext(MediaContext)
  const { event } = useContext(UserContext)
  const { user } = useContext(AuthContext)
  const latestAttendees = useRef([])
  const mappedPeers = useRef({})
  const latestUpdate = useRef(0)
  const updateTimeout = useRef(null)

  const userIds = event.users.map(({ user }) => user)
  const { data: users } = useListUsersQuery({ filter: { userIds } })
  const { data: instructor } = useGetInstructorQuery(event.instructors?.[0] ? event.instructors[0] : skipToken)

  useEffect(() => {
    if (!socket.current || event.streamSettings?.userListDisabled) {
      return
    }
    if (!user.id) return

    socket.current.on("room-joined", async ({ peers }) => {
      const attendees = getUniqueAttendees(peers, users, event, instructor, user.id, socket.current.id)
      logger.debug("Peers in the room:", attendees)
      latestAttendees.current = attendees
      dispatchPeers({ type: "peer-add-all", payload: attendees })
    })

    socket.current.on("peer-connect", (peer) => {
      const attendee = getUniqueAttendees([peer], users, event, instructor, user.id)[0]
      logger.debug(`Peer ${attendee.ui?.displayName} joined the room.`, attendee)
      latestAttendees.current = latestAttendees.current.concat(attendee)
      updateAttendees()
    })

    socket.current.on("peer-disconnect", async (peer) => {
      const currentAttendee = latestAttendees.current.find((a) => a.connectionId === peer.id)
      if (currentAttendee) {
        logger.debug(`Peer ${currentAttendee.ui?.displayName} left the room.`, currentAttendee)
        latestAttendees.current = latestAttendees.current.filter((a) => a.connectionId !== peer.id)
        updateAttendees()
      }
    })

    socket.current.on("peer-mute-toggle", ({ peerId, mute, muteLockedByModerator }) => {
      logger.debug(`Peer "${peerId}" mute state: ${mute}`)
      dispatchPeers({ type: "peer-mute-toggle", payload: { id: peerId, mute, muteLockedByModerator } })
    })

    socket.current.on("active-speakers", (peers) =>
      dispatchPeers({
        type: "peers-speaking",
        payload: {
          speaking: peers.map((peer) => peer.id),
          mapped: mappedPeers.current,
        },
      }),
    )
  }, [socket.current, user.id, event, instructor, users])

  // Returns attendees with the same name and id as one peer with quantity in parentheses.
  const groupAttendees = (attendees) => {
    const grouped = []
    const duplicated = []

    attendees.forEach((attendee) => {
      const identicalAttendees = attendees.filter((a) => a.id === attendee.id && a.name === attendee.name)
      if (identicalAttendees.length > 1) {
        if (duplicated.includes(attendee.id)) {
          return
        }
        mappedPeers.current[attendee.connectionId] = identicalAttendees.map((a) => a.connectionId)
        grouped.push({
          ...attendee,
          name: `${attendee.name} (${identicalAttendees.length})`,
        })
        duplicated.push(attendee.id)
      } else {
        grouped.push(attendee)
      }
    })

    return grouped
  }

  const updateAttendees = () => {
    const now = new Date().getTime()

    if (now - latestUpdate.current < throttleTime) {
      if (updateTimeout.current) return
      // logger.debug(`Too fast, updating in ${throttleTime - (now - latestUpdate.current)}...`);
      updateTimeout.current = setTimeout(() => {
        latestUpdate.current = now
        dispatchPeers({ type: "peer-add-all", payload: groupAttendees(latestAttendees.current) })
      }, throttleTime - (now - latestUpdate.current))
    } else {
      if (updateTimeout.current) {
        clearTimeout(updateTimeout.current)
        updateTimeout.current = null
      }
      latestUpdate.current = now
      dispatchPeers({ type: "peer-add-all", payload: groupAttendees(latestAttendees.current) })
    }
  }

  return (
    <PeerContext.Provider
      value={{
        peers,
      }}
    >
      {children}
    </PeerContext.Provider>
  )
}

export default PeerContainer
