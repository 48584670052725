import React, { useContext, useCallback, useEffect } from "react"
import { Flex, Button, Text } from "rebass"
import EventCodeForm from "./EventCodeForm"
import { AuthContext } from "../../containers/Auth"
import { UserContext } from "../../containers/User"
import { UIContext } from "../../containers/UI"
import { useExchangeJoinCodeMutation } from "../../libs/api"
import { VR_PASSCODE_LENGTH } from "../../constants"
import { getDeviceUUID } from "../../helpers/CommonHelper"

const EventCode = () => {
  const { error, setError, setAuthToken } = useContext(AuthContext)
  const { setPasscodeEventId } = useContext(UserContext)
  const { vrPasscode } = useContext(UIContext)

  const [exchangeJoinCode, mutation] = useExchangeJoinCodeMutation()

  const handleExchangeJoinCode = useCallback(async () => {
    await exchangeJoinCode({
      vrPasscode: Number(vrPasscode),
      serialNumber: getDeviceUUID(),
    })
      .then((res) => {
        if (res.data) {
          return res.data
        }
      })
      .then(({ eventId, token }) => {
        if (!(token || eventId)) {
          throw new Error()
        }

        setPasscodeEventId(eventId)
        setAuthToken(token)
      })
      .catch(() => {
        setError("Invalid Event Passcode. Please double check your email.")
      })
  }, [vrPasscode])

  useEffect(() => {
    if (!(vrPasscode.length === VR_PASSCODE_LENGTH)) return

    handleExchangeJoinCode()
  }, [vrPasscode, handleExchangeJoinCode])

  return (
    <>
      {error ? (
        <Flex height="100vh" flexDirection="column" alignItems="center" justifyContent="center">
          <Text>{error}</Text>
          <Button onClick={() => location.reload()} my="25px" fontSize="18px" p="10px 15px">
            Try again
          </Button>
        </Flex>
      ) : (
        <EventCodeForm isLoading={mutation.isLoading} />
      )}
    </>
  )
}

export default EventCode
