import React, { useContext } from "react"
import { Flex, Button, Text } from "rebass"
import PairingCode from "./PairingCode"
import { AuthContext } from "../../containers/Auth"

const Pairing = () => {
  const { error } = useContext(AuthContext)

  return (
    <>
      {error ? (
        <Flex height="100vh" flexDirection="column" alignItems="center" justifyContent="center">
          <Text>{error}</Text>
          <Button onClick={() => location.reload()} my="25px" fontSize="18px" p="10px 15px">
            Try again
          </Button>
        </Flex>
      ) : (
        <PairingCode />
      )}
    </>
  )
}

export default Pairing
