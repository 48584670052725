import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import React, { useRef } from "react"
import { useFrame, useThree, extend } from "react-three-fiber"

// Make OrbitControls known as <orbitControls />
extend({ OrbitControls })

const Controls = ({ enabled = false, ...props }) => {
  if (!enabled) {
    return null
  }

  const ref = useRef(null)
  const { camera } = useThree()

  useFrame(() => {
    if (ref.current) {
      ref.current.update()
    }
  })

  return <orbitControls ref={ref} args={[camera, document.body]} {...props} />
}

export default Controls
