import React from "react"
import ReactDOM from "react-dom"
import whyDidYouRender from "@welldone-software/why-did-you-render"
import * as Sentry from "@sentry/browser"
import { ThemeProvider } from "emotion-theming"
import { Provider } from "react-redux"
import config from "./js/etc/config"
import ErrorBoundary from "./js/components/ErrorBoundary"
import theme from "./js/etc/theme"
import { GlobalStyles } from "./js/styles"
import AuthContainer from "./js/containers/Auth"
import UserContainer from "./js/containers/User"
import MediaContainer from "./js/containers/Media"
import MicContainer from "./js/containers/Mic"
import { SceneContainer } from "./js/containers/Scene"
import { CanvasUIContainer } from "./js/containers/CanvasUI"
import UIContainer from "./js/containers/UI"
import Onboard from "./js/components/Onboard"
import PeerContainer from "./js/containers/Peer"
import UserListCanvas from "./js/components/UserListCanvas"
import MessageCanvas from "./js/components/MessageCanvas"
import PopupCanvas from "./js/components/PopupCanvas"
import SettingsCanvas from "./js/components/SettingsCanvas"
import VideoElements from "./js/components/VideoElements"
import VRScene from "./js/components/vr/Scene"
import MediaPrompts from "./js/components/MediaPrompts"
import { store } from "./js/redux/store"
import "webrtc-adapter"

if (config.sentry.dsn) {
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: config.environment,
  })
}

if (config.logger.level === "trace") {
  whyDidYouRender(React)
}

const App = () => (
  <ErrorBoundary>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Provider store={store}>
        <AuthContainer>
          <UserContainer>
            <MediaContainer>
              <MicContainer>
                <SceneContainer>
                  <CanvasUIContainer>
                    <UIContainer>
                      <Onboard>
                        <PeerContainer>
                          <UserListCanvas />
                        </PeerContainer>
                        <MessageCanvas />
                        <PopupCanvas />
                        <SettingsCanvas />
                        <VideoElements />
                        <VRScene />
                        <MediaPrompts />
                      </Onboard>
                    </UIContainer>
                  </CanvasUIContainer>
                </SceneContainer>
              </MicContainer>
            </MediaContainer>
          </UserContainer>
        </AuthContainer>
      </Provider>
    </ThemeProvider>
  </ErrorBoundary>
)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
)
