import type { ReadonlyDeep } from "type-fest"
import { signOut } from "firebase/auth"
import { auth } from "../../etc/firebase"
import { resetCredentials } from "../../redux/authSlice"
import { getDeviceUUID } from "../../helpers/CommonHelper"

import { queriesApi, removeEmptyFields, tagTypes } from "./queries"
import { mappers } from "./mappings"
import { enums } from "./enums"

import type * as model from "./model"
import type { ReadonlyCamelCaseDeep } from "./utils_types"

export type ApiResponse = {
  success: boolean
  message?: string
}

export type ApiErrorResponse = {
  status: number
  data: ApiResponse & { success: false }
}

export interface Resource {
  id: string
}

export type CreateMutationApiResponse = ApiResponse & {
  id: string
}

function transformCreateMutationApiResponse<T extends CreateMutationApiResponse = CreateMutationApiResponse>(
  idName: string,
) {
  return (baseValue: { data: ApiResponse & Record<string, string> }): T => {
    return { ...baseValue?.data, id: baseValue?.data?.[idName] ?? "" } as T
  }
}

const mapEdgeSpecialty = (data: model.NormalizedUserSpecialty) => {
  const out: ReadonlyDeep<model.generated.UserSpecialty> = {
    certification:
      data.certification?.status && data.certification?.status !== enums.SpecialtyCertificationStatus.UNKNOWN
        ? { status: data.certification.status }
        : undefined,
    child_ids: data.subspecialtyIds?.length ? data.subspecialtyIds : undefined,
  }
  return removeEmptyFields(out)
}

const mapUserSpecialties = (data: model.NormalizedUser["specialties"]) => {
  if (!data?.length) return undefined

  const out: ReadonlyDeep<model.generated.UserSpecialties> = data.reduce((prev, elem) => {
    if (!elem?.specialty) {
      // Critical, developer error.
      throw new Error("invalid user specialty: missing specialty id")
    }
    return { ...prev, [elem.specialty]: mapEdgeSpecialty(elem) }
  }, {})

  return out
}

export type UpdateOrganizationRequestData = Omit<model.NormalizedOrganization, keyof model.NormalizedIds>

const mapUpdateOrganizationRequestData = (
  data: UpdateOrganizationRequestData,
): ReadonlyDeep<model.generated.UpdateOrganizationRequest> => ({
  address: mappers.mustDenormalizeAddress(data.address),
  description: data.description,
  name: data.name,
  status: data.status,
  photo: data.photo,
  coordinates: mappers.denormalizeCoordinates(data.coordinates),
  digital_footprint: mappers.denormalizeDigitalFootprint(data.digitalFootprint),
  owner: mappers.mustDenormalizeUserInfo(data.owner),
  cs_rep_user_id: data.customerSuccessRep,
})

export type CreateOrganizationRequestData = Omit<model.NormalizedOrganization, keyof model.NormalizedIds | "events">

const mapCreateOrganizationRequest = (
  data: CreateOrganizationRequestData,
): ReadonlyDeep<model.generated.CreateOrganizationRequest> => ({
  ...mapUpdateOrganizationRequestData(data),
})

type UpdateUserRequestData = Omit<
  model.NormalizedUser,
  "id" | "email" | "events" | "organizations" | keyof model.UserClaims
>

const mapUpdateUserRequestData = (
  data: UpdateUserRequestData | CreateUserRequestData,
): ReadonlyDeep<model.generated.UpdateUserRequest> => {
  return {
    user_specialties: mapUserSpecialties(data.specialties),
    user_role: !data.roles.length ? undefined : (data.roles.join(",") as model.generated.UserRole),
    name: mappers.mustDenormalizeUserName(data.name),
    address: mappers.denormalizeAddress(data.address),
    photo: data.photo,
    industry_id: !data.industry?.industry ? undefined : data.industry.industry,
    job_type_id: !data.industry?.jobTypeId ? undefined : data.industry.jobTypeId,
    phone: mappers.denormalizePhone(data.phone),
    preferences: mappers.denormalizeUserPreferences(data.preferences),
    assistant: mappers.denormalizeUserInfo(data.assistant),
    accepted_terms: data.acceptedTerms,
    classification: data.classification,
  }
}

const mapUserOrganizations = (
  data: model.NormalizedUser["organizations"],
): undefined | ReadonlyDeep<model.generated.UserOrganizations> => {
  if (!data?.length) return undefined

  const out: model.generated.UserOrganizations = {}

  data.forEach((elem) => {
    if (!elem?.organization) {
      // Critical, developer error.
      throw new Error("invalid user organization: missing organization id")
    }
    out[elem.organization] = {
      org_role: elem.role === enums.OrganizationRole.UNKNOWN ? undefined : elem.role,
    }
  })

  return out
}

type createEventStatuses = typeof enums.EventStatus.REGISTRATION_OPEN | typeof enums.EventStatus.REGISTRATION_CLOSED

type CreateUserRequestData = Omit<model.NormalizedUser, "id" | "events" | "industry" | keyof model.UserClaims> & {
  industry?: model.NormalizedUserIndustry
}

type CreateUserResponseData = CreateMutationApiResponse

const mapCreateUserRequest = (data: CreateUserRequestData): ReadonlyDeep<model.generated.CreateUserRequest> => ({
  ...mapUpdateUserRequestData(data),
  email: data.email.trim().toLowerCase(),
  user_organizations: mapUserOrganizations(data.organizations),
})

// We only allow registration open/close and cancel as status change from the app.
export type updateEventStatuses = createEventStatuses | typeof enums.EventStatus.CANCELLED

export type UpdateEventRequestData = Omit<
  model.NormalizedEvent,
  keyof model.NormalizedIds | "users" | "organizations" | "status" | "cancelled"
> & { status: updateEventStatuses; skipNotifications?: boolean }

const mapUpdateEventRequestData = (data: UpdateEventRequestData): ReadonlyDeep<model.generated.UpdateEventRequest> => ({
  location_id: data.location ?? "",
  instructor_ids: data.instructors,
  specialty_id: data.specialty?.specialty ?? "",
  sub_specialty_id: data.specialty?.subspecialtyId ?? "",
  cs_rep_user_id: data.customerSuccessRep,
  start_time: new Date(data.startTime).toISOString(),
  end_time: new Date(data.endTime).toISOString(),
  title: data.title,
  description: data.description,
  seat_count: typeof data.seatCount === "string" ? parseInt(data.seatCount) : data.seatCount,
  privacy: data.privacy,
  photo: data.photo,
  stream_settings: mappers.denormalizeStreamSettings(data.streamSettings),
  guest_instructors: data.instructors?.length ? [] : mappers.denormalizeGuestInstructors(data.guestInstructors),
  status: data.status,
  type: data.type,
  funding: data.funding,
  skip_notifications: data.skipNotifications,
  flexible_time: data.flexibleTime,
})

export type CreateEventRequestData = Omit<
  model.NormalizedEvent,
  keyof model.NormalizedIds | "users" | "status" | "cancelled"
> & {
  status: createEventStatuses
  skipNotifications?: boolean
}

const mapCreateEventRequest = (data: CreateEventRequestData): ReadonlyDeep<model.generated.CreateEventRequest> => ({
  ...mapUpdateEventRequestData(data),
  status: data.status,
  organization_id: data.organization,
})

export type UpdateLocationRequestData = Omit<model.NormalizedLocation, keyof model.NormalizedIds>

const mapUpdateLocationRequestData = (
  data: UpdateLocationRequestData,
): ReadonlyDeep<model.generated.UpdateLocationRequest> => ({
  instructor_ids: data.instructors,
  address: mappers.denormalizeAddress(data.address) as Readonly<model.generated.Address>,
  category: data.category,
  name: data.name,
  description: data.description,
  status: data.status,
  coordinates: data.coordinates,
  photo: data.photo,
  digital_footprint: data.digitalFootprint,
  display: data.display,
  slug: data.slug,
})

export type CreateLocationRequestData = Omit<model.NormalizedLocation, keyof model.NormalizedIds>

const mapCreateLocationRequest = (
  data: CreateLocationRequestData,
): ReadonlyDeep<model.generated.CreateLocationRequest> => ({
  ...mapUpdateLocationRequestData(data),
})

export type UpdateInstructorRequestData = Omit<model.NormalizedInstructor, keyof model.NormalizedIds>

const mapUpdateInstructorRequestData = (
  data: UpdateInstructorRequestData,
): ReadonlyDeep<model.generated.UpdateInstructorRequest> => ({
  organization_ids: data.organizations,
  location_ids: data.locations,
  instructor_specialties: mappers.mustDenormalizeInstructorSpecialties(data.specialties),
  email: data.email.trim().toLowerCase(),
  name: data.name,
  photo: data.photo,
  phone: data.phone,
  status: data.status,
  slug: data.slug,
  quote: data.quote,
  practice: data.practice,
  description: data.description,
  digital_footprint: data.digitalFootprint,
  display: data.display,
})

export type CreateInstructorRequestData = Omit<model.NormalizedInstructor, keyof model.NormalizedIds>

const mapCreateInstructorRequest = (
  data: CreateInstructorRequestData,
): ReadonlyDeep<model.generated.CreateInstructorRequest> => ({
  ...mapUpdateInstructorRequestData(data),
})

type PairDeviceRequestData = Omit<model.NormalizedPairing, "id" | "user" | "event" | "device"> & {
  event?: string
}

const mapPairDeviceRequest = (data: PairDeviceRequestData): ReadonlyDeep<model.generated.PairDeviceRequest> => ({
  pairing_code: data.code,
  event_id: data.event,
})

type UpdateDeviceRequestData = Omit<model.NormalizedDevice, "id">

const mapUpdateDeviceRequest = (data: UpdateDeviceRequestData): ReadonlyDeep<model.generated.UpdateDeviceRequest> => ({
  name: !data.name ? undefined : data.name,
  pairing_code: data.pairingCode,
  serial_number: data.serialNumber,
})

type CreateDeviceRequestData = Omit<model.NormalizedDevice, "id">

const mapCreateDeviceRequest = (data: CreateDeviceRequestData): ReadonlyDeep<model.generated.CreateDeviceRequest> => ({
  ...mapUpdateDeviceRequest(data),
})

type UpdateFcmTokenRequestData = Omit<model.NormalizedFcmToken, "id" | "user">

const mapUpdateFcmTokenRequest = (
  data: UpdateFcmTokenRequestData,
): ReadonlyDeep<model.generated.UpdateFcmTokenRequest> => ({
  token: data.token,
  device_id: data.device,
})

type CreateFcmTokenRequestData = Omit<model.NormalizedFcmToken, "id" | "user">

const mapCreateFcmTokenRequest = (
  data: CreateFcmTokenRequestData,
): ReadonlyDeep<model.generated.CreateFcmTokenRequest> => ({
  ...mapUpdateFcmTokenRequest(data),
})

type UpdateImageRequestData = Omit<model.NormalizedImage, "id">

const mapUpdateImageRequest = (data: UpdateImageRequestData): ReadonlyDeep<model.generated.UpdateImageRequest> => ({
  name: data.name,
  url: data.url,
  tags: data.tags,
  organization_id: data.organization,
  public: data.public === undefined ? !data.organization : data.public,
})

type CreateImageRequestData = Omit<model.NormalizedImage, "id">

const mapCreateImageRequest = (data: CreateImageRequestData): ReadonlyDeep<model.generated.CreateImageRequest> => ({
  ...mapUpdateImageRequest(data),
})

type CreateDemoRequestData = Omit<model.NormalizedDemo, "id">
type UpdateDemoRequestData = Omit<model.NormalizedDemo, "id">

const mapUpdateDemoRequest = (data: UpdateDemoRequestData): ReadonlyDeep<model.generated.UpdateDemoRequest> => ({
  organization_ids: data.organizations,
  title: data.title,
  description: data.description,
  photo: data.photo,
  duration: data.duration,
  instructor_ids: data.instructors,
  main_video: mappers.denormalizeDemoMainVideo(data.mainVideo),
  overlays: data.overlays,
  attendees: data.attendees,
})

const mapCreateDemoRequest = (data: CreateDemoRequestData): ReadonlyDeep<model.generated.CreateDemoRequest> => ({
  ...mapUpdateDemoRequest(data),
})

type UpdateSpecialtyRequestData = Omit<model.NormalizedSpecialty, "id" | "subspecialties"> & {
  parentId?: string
}

const mapUpdateSpecialtyRequest = (
  data: UpdateSpecialtyRequestData,
): ReadonlyDeep<model.generated.UpdateSpecialtyRequest> => ({
  name: data.name,
  photo: data.photo,
  parent_id: data.parentId,
})

type CreateSpecialtyRequestData = Omit<model.NormalizedSpecialty, "id" | "subspecialties"> & {
  parentId?: string
}

const mapCreateSpecialtyRequest = (
  data: CreateSpecialtyRequestData,
): ReadonlyDeep<model.generated.CreateSpecialtyRequest> => ({
  ...mapUpdateSpecialtyRequest(data),
})

type UpdateIndustryRequestData = Omit<model.NormalizedIndustry, "id" | "jobTypes"> & {
  parentId?: string
}

const mapUpdateIndustryRequest = (
  data: UpdateIndustryRequestData,
): ReadonlyDeep<model.generated.UpdateIndustryRequest> => ({
  name: data.name,
  photo: data.photo,
  parent_id: data.parentId,
})

type CreateIndustryRequestData = Omit<model.NormalizedIndustry, "id" | "jobTypes"> & {
  parentId?: string
}

const mapCreateIndustryRequest = (
  data: CreateIndustryRequestData,
): ReadonlyDeep<model.generated.CreateIndustryRequest> => ({
  ...mapUpdateIndustryRequest(data),
})

type CreateCloudXRServerRequestData = Partial<
  ReadonlyCamelCaseDeep<model.generated.CreateCxrServerApiArg["createCxrServerRequest"]>
>

const mapCreateCloudXRServerRequest = (
  data: CreateCloudXRServerRequestData,
): ReadonlyDeep<model.generated.CreateCxrServerRequest> => ({
  ami_image_id: data.amiImageId,
  instance_type: data.instanceType,
})

type UpdateHeadsetRequestRequestData = Omit<model.NormalizedHeadsetRequest, "id">

const mapUpdateHeadsetRequestRequest = (
  data: UpdateHeadsetRequestRequestData,
): ReadonlyDeep<model.generated.UpdateHeadsetRequestRequest> => ({
  user_id: data.user,
  event_ids: data.events,
  status: data.status,
  device_type: data.type,
  loan_type: data.loanType,
  user_classification: data.userClassification,
  order_date: data.orderDate ? new Date(data.orderDate).toISOString() : undefined,
  expected_delivery_date: !data.expectedDeliveryDate ? undefined : new Date(data.expectedDeliveryDate).toISOString(),
  quantity: data.quantity ?? 0,
  headsets_serial_numbers: data.headsetsSerialNumbers,
  name: mappers.denormalizeUserName(data.name),
  address: mappers.denormalizeAddress(data.address),
  tracking_info: mappers.denormalizeTrackingInfo(data.trackingInfo),
  late_fee_ack: !data.lateFeeAck ? undefined : new Date(data.lateFeeAck).toISOString(),
})

type CreateHeadsetRequestRequestData = Omit<model.NormalizedHeadsetRequest, "id">

const mapCreateHeadsetRequestRequest = (
  data: CreateHeadsetRequestRequestData,
): ReadonlyDeep<model.generated.CreateHeadsetRequestRequest> => ({
  ...mapUpdateHeadsetRequestRequest(data),
})

const mapCreateJoinCodeRequest = (
  data: CreateJoinCodeRequestData,
): ReadonlyDeep<model.generated.CreateJoinCodeRequest> => ({
  user_id: data.userId,
  event_id: data.eventId,
})

const mapCreateJoinCodeResponse = ({
  data,
}: ReadonlyDeep<model.generated.CreateJoinCodeResponse>): model.NormalizedJoinCode => {
  if ("meeting_id" in data && data.meeting_id) {
    return {
      id: data.join_code_id,
      passcode: data.passcode,
      meetingId: data.meeting_id,
      expiration: new Date(data.expiration).getTime(),
    }
  }
  return {
    id: data.join_code_id,
    passcode: data.passcode,
    expiration: new Date(data.expiration).getTime(),
  }
}

export type CreateJoinCodeRequestData = ReadonlyCamelCaseDeep<model.generated.CreateJoinCodeRequest>

export type ExchangeJoinCodeRequestData = ReadonlyCamelCaseDeep<model.generated.ExchangeJoinCodeRequest>

const mapExchangeJoinCodeRequest = (
  data: ExchangeJoinCodeRequestData,
): ReadonlyDeep<model.generated.ExchangeJoinCodeRequest> => {
  if ("meetingId" in data && data.meetingId) {
    return {
      passcode: data.passcode,
      meeting_id: data.meetingId,
    }
  }
  if ("vrPasscode" in data) {
    return {
      vr_passcode: data.vrPasscode,
      serial_number: data.serialNumber,
    }
  }
  throw new Error("invalid request data")
}

export type ExchangeJoinCodeResponse = ReadonlyCamelCaseDeep<model.generated.ExchangeJoinCodeApiResponse["data"]>

const mapExchangeJoinCodeResponse = ({
  data,
}: ReadonlyDeep<model.generated.ExchangeJoinCodeApiResponse>): ExchangeJoinCodeResponse => ({
  token: data.token,
  eventId: data.event_id,
  userId: data.user_id,
})

export const mutationsApi = queriesApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    // Organizations.
    createOrganization: build.mutation<CreateMutationApiResponse, CreateOrganizationRequestData>({
      invalidatesTags: [{ type: "Organizations", id: "LIST" }],
      query: (args) => ({
        url: `/v0/organizations`,
        method: "POST",
        body: mapCreateOrganizationRequest(args),
      }),
      transformResponse: transformCreateMutationApiResponse("organization_id"),
    }),
    updateOrganization: build.mutation<ApiResponse, Resource & UpdateOrganizationRequestData>({
      invalidatesTags: (_results, _error, arg) => {
        return [
          { type: "Organizations", id: "LIST" },
          { type: "Organizations", id: arg.id },
        ]
      },
      query: ({ id, ...args }) => {
        return {
          url: `/v0/organizations/${id}`,
          method: "PUT",
          body: mapUpdateOrganizationRequestData(args),
        }
      },
    }),
    deleteOrganization: build.mutation<ApiResponse, Resource>({
      invalidatesTags: [
        { type: "Organizations", id: "LIST" },
        { type: "Users", id: "LIST" },
      ],
      query: ({ id }) => ({
        url: `/v0/organizations/${id}`,
        method: "DELETE",
      }),
    }),

    // Users.
    createUser: build.mutation<CreateUserResponseData, CreateUserRequestData>({
      invalidatesTags: (result) => {
        if (!result?.id) {
          return [{ type: "Users", id: "LIST" }]
        }
        return [
          { type: "Users", id: "LIST" },
          { type: "Users", id: result.id },
        ]
      },
      query: (args) => ({
        url: `/v0/users`,
        method: "POST",
        body: mapCreateUserRequest(args),
      }),
      transformResponse: transformCreateMutationApiResponse<CreateUserResponseData>("user_id"),
    }),
    updateUser: build.mutation<ApiResponse, Resource & UpdateUserRequestData>({
      invalidatesTags: (_results, _error, arg) => {
        return [
          { type: "Users", id: "LIST" },
          { type: "Users", id: arg.id },
        ]
      },
      query: ({ id, ...args }) => ({
        url: `/v0/users/${id}`,
        method: "PUT",
        body: mapUpdateUserRequestData(args),
      }),
    }),
    deleteUser: build.mutation<ApiResponse, Resource>({
      invalidatesTags: (_results, _error, arg) => {
        return [
          { type: "Users", id: "LIST" },
          { type: "Users", id: arg.id },
        ]
      },
      query: ({ id }) => ({
        url: `/v0/users/${id}`,
        method: "DELETE",
      }),
    }),
    verifyUser: build.mutation<ApiResponse, model.generated.VerifyUserApiArg>({
      invalidatesTags: (_results, _error, arg) => {
        return [
          { type: "Users", id: "LIST" },
          { type: "Users", id: arg.userId },
        ]
      },
      query: ({ userId }) => ({
        url: `/v0/users/${userId}/verify`,
        method: "PUT",
      }),
    }),
    verifyEmail: build.mutation<ApiResponse, { email: string }>({
      query: ({ email }) => ({
        url: `/v0/users/email/verify`,
        method: "PUT",
        body: { email },
      }),
    }),

    // Events.
    createEvent: build.mutation<CreateMutationApiResponse, CreateEventRequestData>({
      invalidatesTags: [{ type: "Events", id: "LIST" }],
      query: (args) => ({
        url: `/v0/events`,
        method: "POST",
        body: mapCreateEventRequest(args),
      }),
      transformResponse: transformCreateMutationApiResponse("event_id"),
    }),
    updateEvent: build.mutation<ApiResponse, Resource & UpdateEventRequestData>({
      invalidatesTags: (_results, _error, arg) => {
        return [
          { type: "Events", id: "LIST" },
          { type: "Events", id: arg.id },
        ]
      },
      query: ({ id, ...args }) => ({
        url: `/v0/events/${id}`,
        method: "PUT",
        body: mapUpdateEventRequestData(args),
      }),
    }),
    deleteEvent: build.mutation<ApiResponse, Resource>({
      invalidatesTags: [{ type: "Events", id: "LIST" }],
      query: ({ id }) => ({
        url: `/v0/events/${id}`,
        method: "DELETE",
      }),
    }),

    // Locations.
    createLocation: build.mutation<CreateMutationApiResponse, CreateLocationRequestData>({
      invalidatesTags: [{ type: "Locations", id: "LIST" }],
      query: (args) => ({
        url: `/v0/locations`,
        method: "POST",
        body: mapCreateLocationRequest(args),
      }),
      transformResponse: transformCreateMutationApiResponse("location_id"),
    }),
    updateLocation: build.mutation<ApiResponse, Resource & UpdateLocationRequestData>({
      invalidatesTags: (_results, _error, arg) => {
        return [
          { type: "Locations", id: "LIST" },
          { type: "Locations", id: arg.id },
        ]
      },
      query: ({ id, ...args }) => ({
        url: `/v0/locations/${id}`,
        method: "PUT",
        body: mapUpdateLocationRequestData(args),
      }),
    }),
    deleteLocation: build.mutation<ApiResponse, Resource>({
      invalidatesTags: [{ type: "Locations", id: "LIST" }],
      query: ({ id }) => ({
        url: `/v0/locations/${id}`,
        method: "DELETE",
      }),
    }),

    // Instructors.
    createInstructor: build.mutation<CreateMutationApiResponse, CreateInstructorRequestData>({
      invalidatesTags: [{ type: "Instructors", id: "LIST" }],
      query: (args) => ({
        url: `/v0/instructors`,
        method: "POST",
        body: mapCreateInstructorRequest(args),
      }),
      transformResponse: transformCreateMutationApiResponse("instructor_id"),
    }),
    updateInstructor: build.mutation<ApiResponse, Resource & UpdateInstructorRequestData>({
      invalidatesTags: (_results, _error, arg) => {
        return [
          { type: "Instructors", id: "LIST" },
          { type: "Instructors", id: arg.id },
        ]
      },
      query: ({ id, ...args }) => ({
        url: `/v0/instructors/${id}`,
        method: "PUT",
        body: mapUpdateInstructorRequestData(args),
      }),
    }),
    deleteInstructor: build.mutation<ApiResponse, Resource>({
      invalidatesTags: [{ type: "Instructors", id: "LIST" }],
      query: ({ id }) => ({
        url: `/v0/instructors/${id}`,
        method: "DELETE",
      }),
    }),

    // Devices.
    createDevice: build.mutation<CreateMutationApiResponse, CreateDeviceRequestData>({
      invalidatesTags: [{ type: "Devices", id: "LIST" }],
      query: (args) => ({
        url: `/v0/devices`,
        method: "POST",
        body: mapCreateDeviceRequest(args),
      }),
      transformResponse: transformCreateMutationApiResponse("device_id"),
    }),
    updateDevice: build.mutation<ApiResponse, Resource & UpdateDeviceRequestData>({
      invalidatesTags: (_results, _error, arg) => {
        return [
          { type: "Devices", id: "LIST" },
          { type: "Devices", id: arg.id },
          { type: "Devices", id: arg.serialNumber },
        ]
      },
      query: ({ id, ...args }) => ({
        url: `/v0/devices/${id}`,
        method: "PUT",
        body: mapUpdateDeviceRequest(args),
      }),
    }),
    deleteDevice: build.mutation<ApiResponse, string>({
      invalidatesTags: [{ type: "Devices", id: "LIST" }, { type: "Devices" }],
      query: (id) => ({
        url: `/v0/devices/${id}`,
        method: "DELETE",
      }),
    }),

    // FcmTokens.
    createFcmToken: build.mutation<CreateMutationApiResponse, CreateFcmTokenRequestData>({
      invalidatesTags: [{ type: "FcmTokens", id: "LIST" }],
      query: (args) => ({
        url: `/v0/fcm_tokens`,
        method: "POST",
        body: mapCreateFcmTokenRequest(args),
      }),
      transformResponse: transformCreateMutationApiResponse("fcmtoken_id"),
    }),
    updateFcmToken: build.mutation<ApiResponse, Resource & UpdateFcmTokenRequestData>({
      invalidatesTags: (_results, _error, arg) => {
        return [
          { type: "FcmTokens", id: "LIST" },
          { type: "FcmTokens", id: arg.id },
        ]
      },
      query: ({ id, ...args }) => ({
        url: `/v0/fcm_tokens/${id}`,
        method: "PUT",
        body: mapUpdateFcmTokenRequest(args),
      }),
    }),
    deleteFcmToken: build.mutation<ApiResponse, string>({
      invalidatesTags: [{ type: "FcmTokens", id: "LIST" }],
      query: (id) => ({
        url: `/v0/fcm_tokens/${id}`,
        method: "DELETE",
      }),
    }),

    // Pairings.
    pairDevice: build.mutation<ApiResponse, PairDeviceRequestData>({
      invalidatesTags: () => {
        // TODO: Get the actual IDs instead of invalidating the whole list.
        return [
          { type: "Devices", id: "LIST" },
          { type: "Users", id: "LIST" },
        ]
      },
      query: (args) => ({
        url: `/v0/pairings`,
        method: "POST",
        body: mapPairDeviceRequest(args),
      }),
    }),

    // Images.
    createImage: build.mutation<CreateMutationApiResponse, CreateImageRequestData>({
      invalidatesTags: [{ type: "Images", id: "LIST" }],
      query: (args) => ({
        url: `/v0/images`,
        method: "POST",
        body: mapCreateImageRequest(args),
      }),
      transformResponse: transformCreateMutationApiResponse("image_id"),
    }),
    updateImage: build.mutation<ApiResponse, Resource & UpdateImageRequestData>({
      invalidatesTags: [{ type: "Images", id: "LIST" }],
      query: ({ id, ...args }) => ({
        url: `/v0/images/${id}`,
        method: "PUT",
        body: mapUpdateImageRequest(args),
      }),
    }),
    deleteImage: build.mutation<ApiResponse, Resource>({
      invalidatesTags: [{ type: "Images", id: "LIST" }],
      query: ({ id }) => ({
        url: `/v0/images/${id}`,
        method: "DELETE",
      }),
    }),

    // Demos.
    createDemo: build.mutation<CreateMutationApiResponse, CreateDemoRequestData>({
      invalidatesTags: [{ type: "Demos", id: "LIST" }],
      query: (args) => ({
        url: `/v0/demos`,
        method: "POST",
        body: mapCreateDemoRequest(args),
      }),
      transformResponse: transformCreateMutationApiResponse("demo_id"),
    }),
    updateDemo: build.mutation<ApiResponse, Resource & UpdateDemoRequestData>({
      invalidatesTags: [{ type: "Demos", id: "LIST" }],
      query: ({ id, ...args }) => ({
        url: `/v0/demos/${id}`,
        method: "PUT",
        body: mapUpdateDemoRequest(args),
      }),
    }),
    deleteDemo: build.mutation<ApiResponse, Resource>({
      invalidatesTags: [{ type: "Demos", id: "LIST" }],
      query: ({ id }) => ({
        url: `/v0/demos/${id}`,
        method: "DELETE",
      }),
    }),

    // Headset Requests.
    createHeadsetRequest: build.mutation<CreateMutationApiResponse, CreateHeadsetRequestRequestData>({
      invalidatesTags: [{ type: "HeadsetRequests", id: "LIST" }],
      query: (args) => ({
        url: `/v0/headset_requests`,
        method: "POST",
        body: mapCreateHeadsetRequestRequest(args),
      }),
      transformResponse: transformCreateMutationApiResponse("headset_request_id"),
    }),
    updateHeadsetRequest: build.mutation<ApiResponse, Resource & UpdateHeadsetRequestRequestData>({
      invalidatesTags: [{ type: "HeadsetRequests", id: "LIST" }],
      query: ({ id, ...args }) => ({
        url: `/v0/headset_requests/${id}`,
        method: "PUT",
        body: mapUpdateHeadsetRequestRequest(args),
      }),
    }),
    deleteHeadsetRequest: build.mutation<ApiResponse, Resource>({
      invalidatesTags: [{ type: "HeadsetRequests", id: "LIST" }],
      query: ({ id }) => ({
        url: `/v0/headset_requests/${id}`,
        method: "DELETE",
      }),
    }),

    // Join Codes.
    createJoinCode: build.mutation<model.NormalizedJoinCode, CreateJoinCodeRequestData>({
      invalidatesTags: [{ type: "JoinCodes", id: "LIST" }],
      query: (args) => ({
        url: `/v0/join_code`,
        method: "POST",
        body: mapCreateJoinCodeRequest(args),
      }),
      transformResponse: mapCreateJoinCodeResponse,
    }),
    exchangeJoinCode: build.mutation<ExchangeJoinCodeResponse, ExchangeJoinCodeRequestData>({
      query: (args) => ({
        url: `/v0/join_codes/exchange`,
        method: "POST",
        body: mapExchangeJoinCodeRequest(args),
      }),
      transformResponse: mapExchangeJoinCodeResponse,
    }),

    // Specialties.
    createSpecialty: build.mutation<CreateMutationApiResponse, CreateSpecialtyRequestData>({
      invalidatesTags: [{ type: "Specialties", id: "LIST" }],
      query: (args) => ({
        url: `/v0/specialties`,
        method: "POST",
        body: mapCreateSpecialtyRequest(args),
      }),
      transformResponse: transformCreateMutationApiResponse("id"),
    }),
    updateSpecialty: build.mutation<ApiResponse, Resource & UpdateSpecialtyRequestData>({
      invalidatesTags: [{ type: "Specialties", id: "LIST" }],
      query: ({ id, ...args }) => ({
        url: `/v0/specialties/${id}`,
        method: "PUT",
        body: mapUpdateSpecialtyRequest(args),
      }),
    }),
    deleteSpecialty: build.mutation<ApiResponse, Resource>({
      invalidatesTags: [{ type: "Specialties", id: "LIST" }],
      query: ({ id }) => ({
        url: `/v0/specialties/${id}`,
        method: "DELETE",
      }),
    }),

    // Industries.
    createIndustry: build.mutation<CreateMutationApiResponse, CreateIndustryRequestData>({
      invalidatesTags: [{ type: "Industries", id: "LIST" }],
      query: (args) => ({
        url: `/v0/industries`,
        method: "POST",
        body: mapCreateIndustryRequest(args),
      }),
      transformResponse: transformCreateMutationApiResponse("id"),
    }),
    updateIndustry: build.mutation<ApiResponse, Resource & UpdateIndustryRequestData>({
      invalidatesTags: [{ type: "Industries", id: "LIST" }],
      query: ({ id, ...args }) => ({
        url: `/v0/industries/${id}`,
        method: "PUT",
        body: mapUpdateIndustryRequest(args),
      }),
    }),
    deleteIndustry: build.mutation<ApiResponse, Resource>({
      invalidatesTags: [{ type: "Industries", id: "LIST" }],
      query: ({ id }) => ({
        url: `/v0/industries/${id}`,
        method: "DELETE",
      }),
    }),

    // Auth.
    logout: build.mutation<null, null>({
      invalidatesTags: tagTypes,
      queryFn: async (_, api, _extraOptions, fetchWithBQ) => {
        // Unpair the device first.
        await fetchWithBQ({
          url: "/pair",
          method: "DELETE",
          body: JSON.stringify({ deviceId: getDeviceUUID() }),
        })

        // Clear credentials and logout from Firebase.
        api.dispatch(resetCredentials())
        api.dispatch(queriesApi.internalActions.resetApiState)
        await signOut(auth)
        return { data: null }
      },
    }),

    // StreamServers.
    startEventStreamServer: build.mutation<void, string>({
      invalidatesTags: ["StreamServers", "Events"],
      query: (eventId) => ({
        url: `/v0/events/${eventId}/servers/start`,
        method: "POST",
      }),
    }),
    terminateEventStreamServer: build.mutation<void, string>({
      invalidatesTags: ["StreamServers", "Events"],
      query: (eventId) => ({
        url: `/v0/events/${eventId}/servers/terminate`,
        method: "DELETE",
      }),
    }),

    // CloduXRServers.
    createCxrServer: build.mutation<CreateMutationApiResponse, CreateCloudXRServerRequestData>({
      invalidatesTags: [{ type: "CloudXRServers", id: "LIST" }],
      query: (args) => ({
        url: `/v0/cxr_servers`,
        method: "POST",
        body: mapCreateCloudXRServerRequest(args),
      }),
      transformResponse: transformCreateMutationApiResponse("cxrServerId"),
    }),
    deleteCxrServer: build.mutation<void, string>({
      invalidatesTags: ["CloudXRServers"],
      query: (id) => ({
        url: `/v0/cxr_servers/${id}`,
        method: "DELETE",
      }),
    }),
    startCxrServer: build.mutation<void, string>({
      invalidatesTags: ["CloudXRServers"],
      query: (id) => ({
        url: `/v0/cxr_servers/${id}/start`,
        method: "POST",
      }),
    }),
    stopCxrServer: build.mutation<void, string>({
      invalidatesTags: ["CloudXRServers"],
      query: (id) => ({
        url: `/v0/cxr_servers/${id}/stop`,
        method: "POST",
      }),
    }),
    waitCxrServer: build.mutation<void, { id: string; status: string }>({
      invalidatesTags: ["CloudXRServers"],
      query: ({ id, status }) => ({
        url: `/v0/cxr_servers/${id}/wait`,
        method: "POST",
        body: { status },
      }),
    }),
    unlinkCxrServerUser: build.mutation<void, string>({
      invalidatesTags: ["CloudXRServers"],
      query: (id) => ({
        url: `/v0/cxr_servers/${id}/unlink`,
        method: "DELETE",
      }),
    }),
  }),
})
