import React from "react"
import { Flex } from "rebass"
import { colors } from "../../etc/theme"

export const KeypadButton = ({ dark, ...rest }) => {
  const color = dark ? colors.gray11 : colors.blue

  return (
    <Flex
      width={60}
      height={60}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      py={16}
      m={20}
      backgroundColor={color}
      border="1px solid transparent"
      textAlign="center"
      fontSize="1.5rem"
      css={{
        userSelect: "none",
        borderRadius: 8,
        cursor: "pointer",
        ":hover": {
          backgroundColor: colors.lightblue,
        },
      }}
      {...rest}
    />
  )
}
