import React, { useEffect, useRef, useContext } from "react"
import { Group, Stage, Layer, Rect } from "react-konva"
import { useCanvasUI } from "../containers/CanvasUI"
import { UIContext } from "../containers/UI"
import theme from "../../data/canvasTheme"
import PopupButtons from "./PopupButtons"

const PopupCanvas = () => {
  const stage = useRef(null)
  const layer = useRef(null)
  const [, { dispatch, updateTexture }] = useCanvasUI()
  const { popup, setPopup, shouldPopupTexturesUpdate } = useContext(UIContext)

  const onMount = () => {
    dispatch({ type: "addStage", payload: { stage: stage.current } })
  }

  const onDismount = () => {
    dispatch({ type: "removeStage", payload: { id: stage.current.attrs.id } })
  }

  const updateTextures = () => {
    if (stage.current) {
      layer.current.draw()
      updateTexture(stage.current.attrs.id)
    }
  }

  useEffect(() => {
    onMount()
    return onDismount
  }, [])

  useEffect(updateTextures, [popup])
  useEffect(() => {
    if (shouldPopupTexturesUpdate) updateTextures()
  }, [shouldPopupTexturesUpdate])

  return (
    <Stage
      ref={stage}
      id="popup"
      width={popup.width}
      height={popup.height}
      style={{ position: "absolute", top: 0, left: 0, visibility: "hidden" }}
    >
      <Layer ref={layer}>
        <Rect width={popup.width} height={popup.height} fill={theme.color.grey.hex} cornerRadius={10} />
        <Group x={popup.padding} y={popup.padding}>
          {popup.content}
        </Group>
        <PopupButtons popup={popup} setPopup={setPopup} updateTextures={updateTextures} />
      </Layer>
    </Stage>
  )
}

export default PopupCanvas
