import React from "react"
import { skipToken, useGetLocationQuery } from "../../libs/api"

type LocationByIdProps = {
  id?: string
}

export const LocationById: React.FC<LocationByIdProps> = ({ id }) => {
  // API:
  const { data, isLoading } = useGetLocationQuery(id ?? skipToken)

  if (!id) return null

  if (isLoading || !data) return <>...</>

  return <>{data.name}</>
}
