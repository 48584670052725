import React, { useRef, useEffect, useContext, useMemo } from "react"
import { Stage, Layer, Rect, Group, Text } from "react-konva"
import { useCanvasUI } from "../containers/CanvasUI"
import { MediaContext } from "../containers/Media"
import CanvasImage from "./CanvasImage"
import theme from "../../data/canvasTheme"
import warning from "../../images/icons/svg/ios-warning.svg"
import info from "../../images/icons/svg/ios-information-circle.svg"

const settings = {
  width: 320,
  height: 30,
  fontSize: 16,
  iconSize: 24,
  margin: 10,
  padding: 7,
  symbolsPerLine: 35, // if you change fontSize, this one should be also changed
}

const MessageCanvas = () => {
  const stage = useRef(null)
  const layer = useRef(null)
  const [, { dispatch, updateTexture }] = useCanvasUI()
  const { messages } = useContext(MediaContext)
  let nextY = 0

  const onMount = () => {
    dispatch({ type: "addStage", payload: { stage: stage.current } })
  }

  const onDismount = () => {
    dispatch({ type: "removeStage", payload: { id: stage.current.attrs.id } })
  }

  const updateTextures = () => {
    if (stage.current) {
      layer.current.draw()
      updateTexture(stage.current.attrs.id)
    }
  }

  const getTextHeight = (text) => {
    const rows = Math.ceil(parseFloat(text.length / settings.symbolsPerLine))
    return settings.height * (rows <= 0 ? 1 : rows)
  }

  useEffect(() => {
    onMount()
    return onDismount
  }, [])

  const [width, height] = useMemo(() => {
    // NOTE: width less than 2 creates a black square effect.
    const width = messages.length ? settings.width : 2
    const cHeight = messages.reduce((acc, { message }) => acc + getTextHeight(message) + settings.margin, 0)
    // NOTE: height less than 2 creates a black square effect.
    const height = messages.length === 0 ? 2 : cHeight

    if (stage.current) {
      dispatch({ type: "setSize", payload: { id: stage.current.attrs.id, width, height } })
    }
    return [width, height]
  }, [messages, stage])

  useEffect(updateTextures, [messages])

  return (
    <Stage
      ref={stage}
      id="messages"
      width={width}
      height={height}
      style={{ position: "absolute", top: 0, left: 0, visibility: "hidden" }}
    >
      <Layer ref={layer}>
        {messages.map(({ kind, message }, index) => {
          nextY = index > 0 ? nextY + getTextHeight(messages[index - 1].message) + settings.margin : 0

          return (
            <Group key={`message-${index}`}>
              <Rect
                width={settings.width}
                height={getTextHeight(message)}
                y={nextY}
                fill={theme.color.grey.hex}
                cornerRadius={4}
                shadowBlur={5}
                shadowOffset={{ x: 0, y: 3 }}
                shadowOpacity={0.5}
                listening={false}
              />
              {kind && (
                <CanvasImage
                  x={settings.padding}
                  y={nextY + (getTextHeight(message) - settings.iconSize) / 2}
                  width={settings.iconSize}
                  height={settings.iconSize}
                  listening={false}
                  src={kind === "warning" ? warning : info}
                  onLoaded={updateTextures}
                />
              )}
              <Text
                x={settings.padding + (kind ? settings.iconSize + settings.padding : 0)}
                y={nextY + settings.padding * Math.round(getTextHeight(message) / settings.height)}
                width={settings.width - settings.padding * 2 - (kind ? settings.iconSize : 0)}
                height={getTextHeight(message)}
                fill="white"
                fontFamily={"Gilroy"}
                fontSize={settings.fontSize}
                listening={false}
                text={message}
              />
            </Group>
          )
        })}
      </Layer>
    </Stage>
  )
}

export default MessageCanvas
