import React, { useRef, useCallback, useEffect } from "react"
import useCanvasUIasInteractable from "../../hooks/useCanvasUIasInteractable"
import PopupBg from "./PopupBg"

const PopupMesh = ({ stage, onTexture, visible, ...props }) => {
  const mesh = useRef(null)
  const { width, height, texture, makeInteractable } = useCanvasUIasInteractable({
    stage,
  })

  useEffect(() => {
    makeInteractable(mesh)
    mesh.current.renderOrder = 2
    onTexture(texture)
  }, [])

  return (
    <group visible={visible}>
      <PopupBg radius={6} opacity={0.8} renderOrder={1} />
      <mesh ref={mesh} name="canvas-ui" {...props}>
        <planeBufferGeometry attach="geometry" args={[width, height]} />
        <meshBasicMaterial attach="material" map={texture} transparent={true} />
      </mesh>
    </group>
  )
}

const Popup = ({ fromCanvas, ...props }) => {
  const [canvases, { dispatch }] = fromCanvas
  const id = "popup"
  const setTexture = useCallback((texture) => dispatch({ type: "setTexture", payload: { id, texture } }))

  return canvases[id] && <PopupMesh stage={canvases[id].stage} onTexture={setTexture} {...props} />
}

export default Popup
