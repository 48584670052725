import React from "react"
import { Group, Rect, Text } from "react-konva"
import CanvasImage from "./CanvasImage"
import theme from "../../data/canvasTheme"
import overlaysResetIcon from "../../images/icons/svg/refresh-fill.svg"
import micOffIcon from "../../images/icons/svg/mic-off-fill.svg"
import micOnIcon from "../../images/icons/svg/mic-fill.svg"
import exitIcon from "../../images/icons/svg/logout-box-r-fill.svg"

const UserListMenu = ({
  width,
  height,
  paddingTop,
  updateTextures,
  resetOverlay,
  muted,
  toggleMute,
  setPopup,
  exitStream,
  sceneDispatch,
  x = 0,
  y,
}) => {
  const endSession = () => {
    setPopup((prevPopup) => ({ ...prevPopup, show: false }))
    sceneDispatch({ type: "exitXR" })
    exitStream()
  }

  const showPopup = () => {
    const content = (
      <Text
        width={260}
        y={5}
        align="center"
        fontFamily="Gilroy"
        fontSize={18}
        fill="white"
        text="Are you sure you want to exit the session?"
      />
    )

    setPopup((prevPopup) => ({
      ...prevPopup,
      width: 300,
      height: 150,
      padding: 20,
      closeTitle: "Cancel",
      show: true,
      buttons: [
        {
          title: "Yes, exit",
          bg: theme.color.darkgray.hex,
          bgHover: theme.color.semigrey.hex,
          onClick: endSession,
        },
      ],
      content,
    }))
  }

  return (
    <Group>
      <Rect width={width} height={height} x={x} y={y} fill={theme.color.grey.hex} listening={false} />
      <CanvasImage width={50} height={50} x={95} y={paddingTop + 40} src={overlaysResetIcon} onMouseUp={resetOverlay} />
      <Rect width={1} height={82} x={175} y={paddingTop + 20} fill={theme.color.lightgrey.hex} />
      <CanvasImage
        width={50}
        height={50}
        x={200}
        y={paddingTop + 40}
        src={muted ? micOffIcon : micOnIcon}
        onMouseUp={toggleMute}
        onLoaded={updateTextures}
      />
      <Rect width={1} height={82} x={273} y={paddingTop + 20} fill={theme.color.lightgrey.hex} />
      <CanvasImage width={50} height={50} x={300} y={paddingTop + 40} src={exitIcon} onMouseUp={showPopup} />
    </Group>
  )
}

export default UserListMenu
