import { setLocale } from "yup"

type yupProps = {
  path: string // Field name, with '.' denoting nesting.
  min?: number
  max?: number
  length?: number
  value?: number | string | string[]
  values?: string
  label?: string
}

const field = (key: string, values?: Record<string, string>) => (props: yupProps) => ({
  key,
  values: { context: props.path, ...props, ...values },
})

setLocale({
  // Use constant translation keys for messages without values.
  mixed: {
    default: field("field_invalid"),
    required: field("field_required"),
    oneOf: ({ values, ...val }: yupProps) =>
      values?.startsWith("Ref(")
        ? field("field_ref", { ref: values?.replace(/Ref\((.*)\)/, "$1") ?? "" })(val)
        : field("field_oneOf")({ values, ...val }),
  },
  // Use functions to generate an error object that includes the value from the schema.
  number: {
    min: field("field_number_too_small"),
    max: field("field_number_too_big"),
  },
  string: {
    email: field("field_string_invalid_email"),
    min: field("field_string_too_short"),
  },
  array: {
    min: field("field_list_too_short"),
    max: field("field_list_too_big"),
    length: field("field_list_invalid_size"),
  },
})
