import React, { useEffect, useState, createContext, useRef, useMemo } from "react"
import { Flex } from "rebass"
import * as Sentry from "@sentry/browser"
import { onAuthStateChanged, signInWithCustomToken } from "firebase/auth"
import { useDispatch } from "react-redux"
import { AUTH_TYPES } from "../constants"

import Loader from "../components/Loader"
import config from "../etc/config"
import { auth } from "../etc/firebase"
import logger from "../etc/logger"
import { getDeviceUUID } from "../helpers/CommonHelper"
import { skipToken, useGetUserQuery, useGetDeviceQuery, useLogoutMutation } from "../libs/api"
import { resetCredentials, setCredentials } from "../redux/authSlice"

export const AuthContext = createContext({})

const AuthContainer = ({ children }) => {
  const [authType, setAuthType] = useState(null)
  const [userId, setUserId] = useState(null)
  const [authToken, setAuthToken] = useState(null)
  const [isAuthLoading, setIsAuthLoading] = useState(true)
  const [error, setError] = useState(null)
  const [pairingCode, setPairingCode] = useState(null)
  const [isGuestMode, setIsGuestMode] = useState(false)
  const pairingCodePollingTimeout = useRef(null)
  const dispatch = useDispatch()

  const { isError: isDeviceQueryError } = useGetDeviceQuery(
    !!userId && authType === AUTH_TYPES.PAIRING_CODE ? getDeviceUUID() : skipToken,
  )
  const { data: userData, isError: isUserQueryError, isFetching: isUserFetching } = useGetUserQuery(userId || skipToken)
  const [logout] = useLogoutMutation()

  const isLoading = isAuthLoading || isUserFetching

  const handleLogout = async () => {
    await logout()
    location.reload()
  }

  useEffect(() => {
    if (isUserQueryError || (authType === AUTH_TYPES.PAIRING_CODE && isDeviceQueryError)) {
      handleLogout()
    }
  }, [isDeviceQueryError, isUserQueryError, handleLogout])

  useEffect(() => {
    if (!userData) return
    logger.debug("User:", userData)

    Sentry.configureScope((scope) => {
      scope.setTag("event", null)
      scope.setUser({
        username: `${userData.name.first} ${userData.name.last}`,
      })
    })
    setIsAuthLoading(false)
  }, [userData])

  useEffect(() => {
    if (authToken) {
      setIsAuthLoading(true)
      signInWithCustomToken(auth, authToken).catch((err) => logger.error(err))
    }
  }, [authToken])

  useEffect(() => {
    if (authType !== AUTH_TYPES.PAIRING_CODE) {
      window.clearTimeout(pairingCodePollingTimeout.current)
      return
    }

    const requestPairingCode = () => {
      logger.debug("Checking user for auth...")
      const deviceId = getDeviceUUID()

      fetch(`${config.api.url}/pair/${deviceId}`)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }
          throw new Error(res.statusText ? res.statusText : "Couldn't get a pairing code.")
        })
        .then(({ success, data }) => {
          if (!success) {
            throw new Error("Unsuccessful response from the auth server.")
          }
          const { pairingCode, pollingInterval, token } = data

          if (pairingCode && pollingInterval) {
            setPairingCode(pairingCode)
            pairingCodePollingTimeout.current = window.setTimeout(requestPairingCode, pollingInterval)
          } else if (token) {
            setAuthToken(token)
          } else {
            throw new Error("Wrong response from the auth server.")
          }
        })
        .catch((error) => setError(error.message))
    }

    requestPairingCode()
  }, [authType])

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (authUser) => {
      if (authUser?.uid && authUser?.email) {
        // Set authenticated user data to state.
        dispatch(
          setCredentials({
            user: { id: authUser.uid, email: authUser.email },
            token: await authUser.getIdToken(),
          }),
        )
        setUserId(authUser.uid)
      } else {
        dispatch(resetCredentials())
        setUserId(null)
        setIsAuthLoading(false)

        // If we're under the /test path, set the guest mode
        const path = window.location.pathname.split("/").pop()
        if (path === "test") {
          setIsGuestMode(true)
        }
      }
    })

    return () => unsubscribeAuth()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        authType,
        error,
        isGuestMode,
        loggedIn: !!userData,
        logout: handleLogout,
        pairingCode,
        setAuthToken,
        setAuthType,
        setError,
        user: userData,
      }}
    >
      {isLoading ? (
        <Flex alignItems="center" height="100vh">
          <Loader />
        </Flex>
      ) : (
        <>{children}</>
      )}
    </AuthContext.Provider>
  )
}

export default AuthContainer
