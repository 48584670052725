import { enUSEnums } from "../i18n"

import type { EventUserRegistrationStatus } from "./api.generated"

export type enumsType = typeof enUSEnums

export function keys<T extends enumsType[keyof enumsType]>(elem: T): Array<keyof T> {
  return Object.keys(elem) as Array<keyof T>
}

export const enums = Object.entries(enUSEnums).reduce(
  (prev, [kind, vals]) => ({
    ...prev,
    [kind]: Object.keys(vals).reduce((prev, cur) => ({ ...prev, [cur]: cur }), {}),
  }),
  {},
) as { [Property in keyof enumsType]: { [Sub in keyof enumsType[Property]]: Sub } }

// TODO: Create a test file to enforce that the i18n keys matches the api generated ones.

export type registrationStatusParts = {
  actor: null | "USER" | "CLIENT"
  kind: null | "REQUEST" | "INVITE"
  state: keyof typeof enums.EventUserStatusAction
  wasAccepted?: boolean
}

export const decomposeRegistrationStatus = (
  status?: null | EventUserRegistrationStatus | "REQUEST_PENDING" | "",
): registrationStatusParts | null => {
  if (!status) return null
  const m: Record<EventUserRegistrationStatus | "REQUEST_PENDING", registrationStatusParts | null> = {
    REQUEST_PENDING: null,
    [enums.EventUserRegistrationStatus.UNKNOWN]: null,
    [enums.EventUserRegistrationStatus.PENDING_USER]: {
      actor: "USER",
      kind: "INVITE",
      state: enums.EventUserStatusAction.INITIATE,
    },
    [enums.EventUserRegistrationStatus.PENDING_CLIENT]: {
      actor: "CLIENT",
      kind: "REQUEST",
      state: enums.EventUserStatusAction.INITIATE,
    },
    [enums.EventUserRegistrationStatus.CANCELLED_USER]: {
      actor: "USER",
      kind: "INVITE",
      state: enums.EventUserStatusAction.CANCEL,
    },
    [enums.EventUserRegistrationStatus.CANCELLED_CLIENT]: {
      actor: "CLIENT",
      kind: "REQUEST",
      state: enums.EventUserStatusAction.CANCEL,
    },
    [enums.EventUserRegistrationStatus.REJECTED_USER]: {
      actor: "USER",
      kind: "INVITE",
      state: enums.EventUserStatusAction.CANCEL,
    },
    [enums.EventUserRegistrationStatus.REJECTED_CLIENT]: {
      actor: "CLIENT",
      kind: "REQUEST",
      state: enums.EventUserStatusAction.CANCEL,
    },
    [enums.EventUserRegistrationStatus.ACCEPTED]: {
      actor: null,
      kind: null,
      state: enums.EventUserStatusAction.ACCEPT,
    },
    [enums.EventUserRegistrationStatus.CANCELLED_ACCEPTED_USER]: {
      actor: "USER",
      kind: null,
      state: enums.EventUserStatusAction.CANCEL,
      wasAccepted: true,
    },
    [enums.EventUserRegistrationStatus.CANCELLED_ACCEPTED_CLIENT]: {
      actor: "CLIENT",
      kind: null,
      state: enums.EventUserStatusAction.CANCEL,
      wasAccepted: true,
    },
    [enums.EventUserRegistrationStatus.CANCELLED_EVENT]: null,
  }
  return m[status] ?? null
}
