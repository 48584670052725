import { configureStore } from "@reduxjs/toolkit"
import { authReducer } from "./authSlice"
import { api } from "../libs/api"

const middlewares = [api.middleware]

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [api.reducerPath]: api.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
})

// Infer the `RootState` and `AppDispatch` types from the store itself.
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
