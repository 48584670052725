import { useMemo, useEffect, useRef, useState } from "react"
import * as THREE from "three"
import useControllable from "./useControllable"

const settings = {
  pixelRatio: 150,
}

const useCanvasUIasInteractable = ({ stage }) => {
  const { setInteractable, clearInteractable } = useControllable()
  const lastPointerPosition = useRef(null)
  const moveCount = useRef(null)
  const [mesh, setMesh] = useState(null)

  const width = stage.attrs.width / settings.pixelRatio
  const height = stage.attrs.height / settings.pixelRatio

  const texture = useMemo(() => {
    const canvasElement = stage.getLayers()[0].canvas._canvas
    const texture = new THREE.CanvasTexture(canvasElement)
    texture.minFilter = THREE.LinearFilter
    texture.maxFilter = THREE.LinearFilter
    texture.encoding = THREE.sRGBEncoding
    return texture
  }, [stage])

  const makeInteractable = (mesh) => {
    setMesh(mesh)
  }

  const getCordsFromIntersection = (intersection) => {
    // todo: we should also add content offset so we don't assume TL placement since canvas can be anywhere on page.
    const clientX = intersection.uv.x * stage.attrs.width
    const clientY = stage.attrs.height - intersection.uv.y * stage.attrs.height
    return { clientX, clientY }
  }

  const onMount = () => {
    setInteractable(mesh.current, "pointer-move", ({ intersection, controller }) => {
      // will skip frames to preserve some performance.
      if (moveCount.current % 5 === 0) {
        if (lastPointerPosition.current && lastPointerPosition.current.controller === controller) {
          const clientCords = getCordsFromIntersection(intersection)
          stage._mousemove(clientCords)

          lastPointerPosition.current = {
            clientCords,
            controller,
          }
        }
      }

      moveCount.current++
    })

    setInteractable(mesh.current, "pointer-enter", ({ intersection, controller }) => {
      if (lastPointerPosition.current === null) {
        const clientCords = getCordsFromIntersection(intersection)
        stage._mouseenter(clientCords)

        lastPointerPosition.current = {
          clientCords,
          controller,
        }
      }
    })

    setInteractable(mesh.current, "pointer-out", () => {
      if (lastPointerPosition.current) {
        stage._mouseout({
          ...lastPointerPosition.current.clientCords,
        })
        lastPointerPosition.current = null
      }
    })

    // We use 'click' instead of 'select-up' so that it does not
    // interfere with useCyclindrical hook that may also be applied to
    // the mesh of this component.
    setInteractable(mesh.current, "click", ({ intersection }) => {
      const clientCords = getCordsFromIntersection(intersection)
      stage._mouseup(clientCords)
    })
  }

  const onDismount = () => {
    clearInteractable(mesh.current, "pointer-move")
    clearInteractable(mesh.current, "pointer-enter")
    clearInteractable(mesh.current, "pointer-out")
    clearInteractable(mesh.current, "click")
  }

  useEffect(() => {
    if (mesh && mesh.current) {
      onMount()
      return onDismount
    }
  }, [mesh])

  return {
    texture,
    width,
    height,
    makeInteractable,
  }
}

export default useCanvasUIasInteractable
