import React, { createContext, useReducer, useContext, useCallback } from "react"

const CanvasUIStateContext = createContext()
const CanvasUIDispatchContext = createContext()

const stageReducer = (state, action) => {
  switch (action.type) {
    case "addStage":
      return {
        ...state,
        [action.payload.stage.attrs.id]: {
          stage: action.payload.stage,
          width: action.payload.stage.attrs.width,
          height: action.payload.stage.attrs.height,
        },
      }
    case "removeStage":
      delete state[action.payload.id]
      return {
        ...state,
      }
    case "setSize":
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          width: action.payload.width,
          height: action.payload.height,
        },
      }
    case "setTexture":
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          texture: action.payload.texture,
        },
      }
  }
}

const CanvasUIContainer = ({ children }) => {
  const [state, dispatch] = useReducer(stageReducer, {})

  const updateTexture = useCallback((id) => {
    if (state[id] && state[id].texture) {
      state[id].texture.needsUpdate = true
    }
  })

  return (
    <CanvasUIStateContext.Provider value={state}>
      <CanvasUIDispatchContext.Provider value={{ updateTexture, dispatch }}>
        {children}
      </CanvasUIDispatchContext.Provider>
    </CanvasUIStateContext.Provider>
  )
}

const useCanvasUIState = () => {
  const context = useContext(CanvasUIStateContext)
  if (context === undefined) {
    throw new Error("useCanvasUIState must be within CanvasUIContainer")
  }
  return context
}

const useCanvasUIDispatcher = () => {
  const context = useContext(CanvasUIDispatchContext)
  if (context === undefined) {
    throw new Error("useCanvasUIDispatcher must be within CanvasUIContainer")
  }
  return context
}

const useCanvasUI = () => [useCanvasUIState(), useCanvasUIDispatcher()]

export { CanvasUIContainer, useCanvasUI }
