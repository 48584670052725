import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import type { InitOptions } from "i18next"

// Load yup locale config.
import "./yup"

import enUSCommon from "./assets/locales/en-US/common.json"
import enUSNavigation from "./assets/locales/en-US/navigation.json"
import enUSAuth from "./assets/locales/en-US/auth.json"
import enUSApp from "./assets/locales/en-US/app.json"
import enUSErrors from "./assets/locales/en-US/errors.json"
import enUSNames from "./assets/locales/en-US/names.json"
import enUSEnums from "./assets/locales/en-US/enums.json"
import enUSEventsTable from "./assets/locales/en-US/eventsTable.json"

import frFRCommon from "./assets/locales/fr-FR/common.json"

// Used for type checks.
export { enUSNames, enUSEnums }

export const resources = {
  en: {
    common: enUSCommon,
    navigation: enUSNavigation,
    auth: enUSAuth,
    app: enUSApp,
    errors: enUSErrors,
    names: enUSNames,
    enums: enUSEnums,
    eventsTable: enUSEventsTable,
  },
  fr: {
    common: frFRCommon,
  },
}

const options: InitOptions = {
  lng: "en",
  fallbackLng: "en",
  defaultNS: "common",
  resources,
  interpolation: {
    escapeValue: false, // React already escapes.
    format: (value, format) => {
      switch (format) {
        case "uppercase":
          return value.toUpperCase()
        case "lowercase":
          return value.toLowerCase()
        case "capitalize":
          return value.charAt(0).toUpperCase() + value.slice(1)
      }
      return value
    },
  },
}

i18n.use(initReactI18next).init(options)
