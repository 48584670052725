import React, { useMemo, useState, useEffect, useRef } from "react"
import * as THREE from "three"
import { useThree } from "react-three-fiber"
import logger from "../../etc/logger"

const VRVideo = React.memo(
  ({
    element,
    fps = 29.97,
    hShift = 0,
    vShift = 0,
    fov = 180,
    step = 1.0, // initial only, will be updated by real resolution
    swap = false,
  }) => {
    const [shaders, setShaders] = useState()
    const { camera } = useThree()
    const leftEye = useRef(null)
    const rightEye = useRef(null)

    const videoTexture = useMemo(() => {
      if (!element || !element.current) {
        return null
      }
      const videoTexture = new THREE.Texture(element.current)
      videoTexture.generateMipmaps = false
      videoTexture.minFilter = THREE.LinearFilter
      videoTexture.maxFilter = THREE.LinearFilter
      videoTexture.wrapS = THREE.ClampToEdgeWrapping
      videoTexture.wrapT = THREE.ClampToEdgeWrapping
      videoTexture.encoding = THREE.sRGBEncoding
      return videoTexture
    }, [element])

    const createRawShaderMaterial = ({ eye, texture, shaders }) => {
      return new THREE.RawShaderMaterial({
        side: THREE.BackSide,
        uniforms: {
          map: {
            type: "t",
            value: texture,
          },
          videoFOV: {
            type: "f",
            value: 180,
          },
          side: {
            type: "f",
            value: eye === "left" ? -1 : 1,
          },
          hshift: {
            type: "f",
            value: hShift,
          },
          vshift: {
            type: "f",
            value: vShift,
          },
          stepSize: {
            type: "f",
            value: texture.image.videoHeight ? 1.0 / texture.image.videoHeight : 1.0, // TODO: Remove me because width/height passed
          },
          texWidth: {
            type: "f",
            value: texture.image.videoWidth,
          },
          texHeight: {
            type: "f",
            value: texture.image.videoHeight,
          },
        },
        vertexShader: shaders.vertex.data,
        fragmentShader: shaders.fragment.data,
      })
    }

    const [leftMaterial, rightMaterial] = useMemo(() => {
      if (videoTexture && shaders) {
        logger.debug("Creating camera shaders", { vShift, hShift, step })
        const leftMaterial = createRawShaderMaterial({
          eye: "left",
          texture: videoTexture,
          shaders,
        })
        const rightMaterial = createRawShaderMaterial({
          eye: "right",
          texture: videoTexture,
          shaders,
        })

        return [leftMaterial, rightMaterial]
      }

      // material until video texture is available.
      return [
        new THREE.MeshBasicMaterial({ color: "#07161e", side: THREE.FrontSide }),
        new THREE.MeshBasicMaterial({ color: "#07161e", side: THREE.FrontSide }),
      ]
    }, [videoTexture, shaders, hShift, vShift, step])

    // Load Shaders.
    useEffect(() => {
      let loaded = 0
      const loader = new THREE.FileLoader()
      const shaderFiles = {
        vertex: {
          file: `/shaders/${fov}.vert`,
        },
        fragment: {
          file: `/shaders/${fov}.frag`,
        },
      }

      Object.keys(shaderFiles).map((key) => {
        loader.load(shaderFiles[key].file, (data) => {
          shaderFiles[key].data = data
          loaded++

          if (loaded === Object.keys(shaderFiles).length) {
            setShaders(shaderFiles)
          }
        })
      })
    }, [fov])

    useEffect(() => {
      // render mesh on appropriate left/right layers for eye cameras.
      leftEye.current.layers.set(swap ? 2 : 1)
      rightEye.current.layers.set(swap ? 1 : 2)

      // renders left view when no stereo available
      camera.layers.enable(1)

      leftEye.current.updateMatrix()
      rightEye.current.updateMatrix()
    }, [swap])

    useEffect(() => {
      // Update video texture at set fps rather than every VR frame to save performance.
      // https://github.com/mrdoob/three.js/issues/13379
      if (!videoTexture) {
        return
      }
      const intervalId = window.setInterval(() => {
        if (element.current && element.current.readyState >= element.current.HAVE_CURRENT_DATA) {
          videoTexture.needsUpdate = true
        }
      }, 1000 / fps)

      return () => {
        window.clearInterval(intervalId)
      }
    }, [videoTexture])

    return (
      <group>
        <mesh
          ref={leftEye}
          rotation={[0, Math.PI, 0]}
          scale={[-1, 1, 1]}
          material={leftMaterial}
          matrixAutoUpdate={false}
        >
          <sphereBufferGeometry attach="geometry" args={[100, 60, 40]} />
        </mesh>
        <mesh ref={rightEye} scale={[-1, 1, 1]} material={rightMaterial} matrixAutoUpdate={false}>
          <sphereBufferGeometry attach="geometry" args={[100, 60, 40]} />
        </mesh>
      </group>
    )
  },
)

export default VRVideo
