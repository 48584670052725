import type { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes"
import type { FetchArgs } from "@reduxjs/toolkit/query"

import { api as generatedApi } from "./api.generated"
import { mappers } from "./mappings"
import { entityMappers } from "./schema"
import { cacher } from "./rtkCacheUtils"

import type { ReadonlyDeep, ReadonlyCamelCaseDeep } from "./utils_types"
import type * as model from "./model"

// NOTE: Unused, works well, but too well, need to fix the cache invalidation.
//       Keeping for reference.
function getResourceQueryFn<InT, OutT extends { id: string }>(endpointType: string, mapper: (elem: InT) => OutT) {
  return async (
    queryArg: GetResourceApiArg & { include?: readonly string[] },
    api: BaseQueryApi,
    _extraOptions: unknown,
    baseQuery: (args: FetchArgs) => PromiseLike<{ data?: unknown }>,
  ) => {
    const store = (api.getState() as Record<typeof queriesApi.reducerPath, unknown>)[queriesApi.reducerPath] as {
      provided: Record<string, Record<string, string[]>>
      queries: Record<
        string,
        {
          status: string
          fulfilledTimeStamp: number
          data?: (OutT | readonly OutT[]) & { included?: Record<string, readonly OutT[]> }
        }
      >
    }

    const id = mapGetApiArg(queryArg)[0]

    const tmp = !store.queries || !store.provided?.[endpointType]?.[id] ? undefined : store.provided[endpointType][id]

    const pending = tmp?.find((k) => store.queries[k].status === "pending")

    // If we have an entry flagged as pending, we need to refetch.
    if (!pending) {
      const ret = tmp
        ?.filter((k) => store.queries[k].status === "fulfilled")
        .sort((b, a) => store.queries[a].fulfilledTimeStamp - store.queries[b].fulfilledTimeStamp)?.[0]

      if (ret) {
        // Potential cache hit.
        const elem = store.queries[ret]
        const nested = elem?.data?.included?.[endpointType.toLowerCase()]

        if (nested) {
          const data = nested.filter((elem) => elem.id === id)
          if (data) {
            // Cache hit on the included nested field.
            return { ...elem, data }
          }
        }
        if (Array.isArray(elem.data)) {
          const data = elem.data.find((elem) => elem.id === id)
          if (data) {
            // Cache hit from the cached list. Most common case.
            return { ...elem, data }
          }
        }
        if (elem.data && typeof elem.data === "object" && "id" in elem.data && elem.data.id === id) {
          // Cache hit from the cached get.
          return { ...elem, data: elem.data as OutT }
        }
      }
    } else {
      const out = store.queries[pending]
      const foo = tmp?.map?.((k) => store?.queries?.[k].status) ?? []
      console.log("Refetch", endpointType, id, out, foo)

      if (endpointType === "Events" && foo.filter((s) => s === "pending").length > 1) {
        return { error: "pending" }
      }
    }

    // Cache miss.
    const result = (await baseQuery({
      url: `/v0/${endpointType.toLowerCase()}/${id}`,
      params: {
        include: typeof queryArg !== "string" && "include" in queryArg ? queryArg.include : undefined,
      },
    })) as undefined | { data?: { data?: InT; included?: Record<string, Record<string, model.generated.User>> } }

    const out = {
      ...result?.data,
      data: !result?.data?.data
        ? undefined
        : {
            ...mapper(result.data.data),
            ...(!result?.data?.included?.users
              ? undefined
              : {
                  // TODO: Consider supporting more includes.
                  included: {
                    users: Object.values(result.data.included.users).map(entityMappers.normalizeUser),
                  },
                }),
          },
    }

    return out
  }
}
export const noop = (): void => {
  console.log(getResourceQueryFn)
}

// Generic function applying the given 'trans' to each elements.
function transformListResponse<InT, OutT>(
  trans: (elem: InT) => OutT,
): (baseValue: { data?: readonly InT[] }) => readonly OutT[] {
  return ({ data }) => (data ?? []).map(trans)
}

// Generic function removing falsy fields from the object.
export function removeEmptyFields<InT extends Record<string, unknown>>(input: InT): Partial<InT> {
  return Object.fromEntries(Object.entries(input).filter(([, v]) => !!v)) as Partial<InT>
}

export type GetResourceApiArg = { id: string } | string

export const mapGetApiArg = (arg: GetResourceApiArg): string[] => {
  if (typeof arg === "string") {
    if (!arg) {
      throw new Error("get query missing id")
    }
    return [arg]
  }
  if ("id" in arg) {
    if (!arg.id) {
      throw new Error("get query missing id")
    }
    return [arg.id]
  }

  throw new Error("get query missing id")
}

export type ListOrganizationsApiArg = ReadonlyCamelCaseDeep<model.generated.ListOrganizationsApiArg>

const mapOrganizationFilter = (
  filter?: ListOrganizationsApiArg["filter"],
): model.generated.ListOrganizationsApiArg["filter"] => {
  if (!filter) return undefined
  if (!Object.values(filter).find((v) => v !== undefined)) {
    return undefined
  }

  return Object.fromEntries(
    Object.entries({
      self: filter.self,
      organization_ids: filter.organizationIds,
      name: filter.name,
      slug: filter.slug,
    }).filter(([, v]) => v !== undefined),
  )
}

export type ListUsersApiArg = ReadonlyCamelCaseDeep<model.generated.ListUsersApiArg>

const mapUserFilter = (filter?: ListUsersApiArg["filter"]): model.generated.ListUsersApiArg["filter"] => {
  if (!filter) return undefined
  if (!Object.values(filter).find((v) => v !== undefined)) {
    return undefined
  }
  return Object.fromEntries(
    Object.entries({
      email: filter.email,
      self: filter.self,
      user_ids: filter.userIds,
      specialty_ids: filter.specialtyIds,
      organization_ids: filter.organizationIds,
      user_role: filter.userRole,
      event_id: filter.eventId,
    }).filter(([, v]) => v !== undefined),
  )
}

export type ListEventsApiArg = ReadonlyCamelCaseDeep<model.generated.ListEventsApiArg> & {
  includeUsers?: boolean
}

export const mapEventFilter = (filter?: ListEventsApiArg["filter"]): model.generated.ListEventsApiArg["filter"] => {
  if (!filter) return undefined
  if (!Object.values(filter).find((v) => v !== undefined)) {
    return undefined
  }
  return Object.fromEntries(
    Object.entries({
      public_only: filter.publicOnly,
      self: filter.self,
      event_ids: filter.eventIds,
      user_ids: filter.userIds,
      instructor_ids: filter.instructorIds,
      organization_ids: filter.organizationIds,
      end_before: filter.endBefore,
      end_after: filter.endAfter,
      start_before: filter.startBefore,
      start_after: filter.startAfter,
      specialty_ids: filter.specialtyIds,
    }).filter(([, v]) => v !== undefined),
  )
}

export type ListInstructorsApiArg = ReadonlyCamelCaseDeep<model.generated.ListInstructorsApiArg>

const mapInstructorFilter = (
  filter?: ListInstructorsApiArg["filter"],
): model.generated.ListInstructorsApiArg["filter"] => {
  if (!filter) return undefined
  if (!Object.values(filter).find((v) => v !== undefined)) {
    return undefined
  }
  return Object.fromEntries(
    Object.entries({
      public_only: filter.publicOnly,
      organization_ids: filter.organizationIds,
      location_ids: filter.locationIds,
      instructor_ids: filter.instructorIds,
      specialty_ids: filter.specialtyIds,
      slug: filter.slug,
    }).filter(([, v]) => v !== undefined),
  )
}

export type ListDemosApiArg = ReadonlyCamelCaseDeep<model.generated.ListDemosApiArg>

const mapDemoFilter = (filter?: ListDemosApiArg["filter"]): model.generated.ListDemosApiArg["filter"] => {
  if (!filter) return undefined
  if (!Object.values(filter).find((v) => v !== undefined)) {
    return undefined
  }
  return Object.fromEntries(
    Object.entries({
      organization_ids: filter.organizationIds,
      demo_ids: filter.demoIds,
    }).filter(([, v]) => v !== undefined),
  )
}

export type ListLocationsApiArg = ReadonlyCamelCaseDeep<model.generated.ListLocationsApiArg>

const mapLocationFilter = (filter?: ListLocationsApiArg["filter"]): model.generated.ListLocationsApiArg["filter"] => {
  if (!filter) return undefined
  if (!Object.values(filter).find((v) => v !== undefined)) {
    return undefined
  }
  return Object.fromEntries(
    Object.entries({
      public_only: filter.publicOnly,
      name: filter.name,
      location_ids: filter.locationIds,
      instructor_ids: filter.instructorIds,
      slug: filter.slug,
    }).filter(([, v]) => v !== undefined),
  )
}

export type ListSpecialtiesApiArg = ReadonlyCamelCaseDeep<model.generated.ListSpecialtiesApiArg>

const mapSpecialtyFilter = (
  filter?: ListSpecialtiesApiArg["filter"],
): model.generated.ListSpecialtiesApiArg["filter"] => {
  if (!filter) return undefined
  if (!Object.values(filter).find((v) => v !== undefined)) {
    return undefined
  }
  return Object.fromEntries(
    Object.entries({
      specialty_ids: filter.specialtyIds,
      name: filter.name,
      subspec_name: filter.subspecName,
    }).filter(([, v]) => v !== undefined),
  )
}

export type ListIndustriesApiArg = ReadonlyCamelCaseDeep<model.generated.ListIndustriesApiArg>

const mapIndustryFilter = (filter?: ListIndustriesApiArg["filter"]): model.generated.ListIndustriesApiArg["filter"] => {
  if (!filter) return undefined
  if (!Object.values(filter).find((v) => v !== undefined)) {
    return undefined
  }
  return Object.fromEntries(
    Object.entries({
      industry_ids: filter.industryIds,
      name: filter.name,
      job_type_name: filter.jobTypeName,
    }).filter(([, v]) => v !== undefined),
  )
}

export type ListDevicesApiArg = ReadonlyCamelCaseDeep<model.generated.ListDevicesApiArg>
export type GetDeviceApiArg = GetResourceApiArg | { serialNumber: string }

const mapDeviceFilter = (filter: ListDevicesApiArg["filter"]) => {
  if (!filter) return undefined
  if (!filter.deviceIds?.length && !filter.serialNumber && !filter.userIds?.length) return undefined

  const out: ReadonlyDeep<model.generated.ListDevicesApiArg["filter"]> = {
    device_ids: filter.deviceIds?.length ? filter.deviceIds : undefined,
    serial_number: filter.serialNumber,
    user_ids: filter.userIds?.length ? filter.userIds : undefined,
  }

  return removeEmptyFields(out)
}

export type ListFcmTokensApiArg = ReadonlyCamelCaseDeep<model.generated.ListFcmTokensApiArg>

const mapFcmTokenFilter = (filter: ListFcmTokensApiArg["filter"]) => {
  if (!filter) return undefined
  if (!filter.fcmTokenIds?.length && !filter.userIds?.length && !filter.deviceIds?.length) return undefined

  const out: ReadonlyDeep<model.generated.ListFcmTokensApiArg["filter"]> = {
    fcm_token_ids: filter.fcmTokenIds?.length ? filter.fcmTokenIds : undefined,
    user_ids: filter.userIds?.length ? filter.userIds : undefined,
    device_ids: filter.deviceIds?.length ? filter.deviceIds : undefined,
  }

  return removeEmptyFields(out)
}

export type ListImagesApiArg = ReadonlyCamelCaseDeep<model.generated.ListImagesApiArg>
export type GetImageApiArg = GetResourceApiArg | { name: string }

const mapImageFilter = (filter: ListImagesApiArg["filter"]) => {
  if (!filter) return undefined
  if (!filter.imageIds?.length && !filter.name && !filter.tags?.length) return undefined

  const out: ReadonlyDeep<model.generated.ListImagesApiArg["filter"]> = {
    image_ids: filter.imageIds?.length ? filter.imageIds : undefined,
    name: filter.name,
    tags: filter.tags?.length ? filter.tags : undefined,
  }
  return removeEmptyFields(out)
}

export type ListHeadsetRequestsApiArg = ReadonlyCamelCaseDeep<model.generated.ListHeadsetRequestsApiArg>
export type GetHeadsetRequestApiArg = GetResourceApiArg | { id: string }

const mapHeadsetRequestFilter = (filter: ListHeadsetRequestsApiArg["filter"]) => {
  if (!filter) return undefined
  if (!filter.headsetRequestIds?.length && !filter.eventIds?.length) return undefined

  const out: ReadonlyDeep<model.generated.ListHeadsetRequestsApiArg["filter"]> = {
    headset_request_ids: filter.headsetRequestIds,
    event_ids: filter.eventIds,
  }
  return removeEmptyFields(out)
}

const mapOrganizations = (ids: readonly string[], organizations: model.NormalizedOrganization[]) =>
  ids.reduce((res, id) => {
    const org = organizations.find((org) => org.id === id)
    if (org) {
      res.push(org)
    }
    return res
  }, [] as model.NormalizedOrganization[])

const mapInstructors = (ids: readonly string[], instructors: model.NormalizedInstructor[]) =>
  ids.reduce((res, id) => {
    const instructor = instructors.find((i) => i.id === id)
    if (instructor) {
      res.push(instructor)
    }
    return res
  }, [] as model.NormalizedInstructor[])

export const tagTypes = [
  "Organizations",
  "Users",
  "Events",
  "Instructors",
  "Demos",
  "Locations",
  "Specialties",
  "Industries",
  "Devices",
  "FcmTokens",
  "Pairings",
  "Images",
  "HeadsetRequests",
  "JoinCodes",
  "StreamServers",
  "CloudXRServers",
] as const

const enhancedApi = generatedApi.enhanceEndpoints({
  addTagTypes: [...cacher.defaultTags, ...tagTypes],
})

export const queriesApi = enhancedApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    // Organizations.
    listOrganizations: build.query<readonly model.NormalizedOrganization[], ListOrganizationsApiArg | null>({
      providesTags: cacher.providesList("Organizations"),
      query: (queryArg) => ({
        url: `/v0/organizations`,
        params: {
          filter: mapOrganizationFilter(queryArg?.filter),
        },
      }),
      transformResponse: transformListResponse(entityMappers.normalizeOrganization),
    }),
    getOrganization: build.query<model.NormalizedOrganization | undefined, GetResourceApiArg>({
      providesTags: cacher.cacheByIdArg("Organizations"),
      query: (queryArg) => ({
        url: `/v0/organizations`,
        params: {
          filter: mapOrganizationFilter({
            organizationIds: mapGetApiArg(queryArg),
          }),
        },
      }),
      transformResponse: (baseValue: model.generated.ListOrganizationsApiResponse) => {
        return transformListResponse(entityMappers.normalizeOrganization)(baseValue)[0] ?? null
      },
    }),

    // Users.
    listUsers: build.query<readonly model.NormalizedUser[], ListUsersApiArg | null>({
      providesTags: cacher.providesList("Users"),
      query: (queryArg) => ({
        url: `/v0/users`,
        params: {
          filter: mapUserFilter(queryArg?.filter),
        },
      }),
      transformResponse: transformListResponse(entityMappers.normalizeUser),
    }),
    listEventUsers: build.query<
      readonly Readonly<model.UserEventEdge & { user: model.NormalizedUser; event: model.NormalizedEvent }>[],
      GetResourceApiArg
    >({
      providesTags: (results, _error, args) => {
        const eventTags = [{ type: "Events", id: mapGetApiArg(args)[0] } as const]
        if (!results?.length) {
          return eventTags
        }
        return [...eventTags, ...results.map((elem) => ({ type: "Users", id: elem.user.id } as const))]
      },
      query: (queryArg) => {
        const eventId = mapGetApiArg(queryArg)[0]
        if (!eventId) {
          throw new Error("missing event id")
        }
        return {
          url: `/v0/events/` + eventId,
          params: {
            include: ["users"],
          },
        }
      },
      transformResponse: ({
        data,
        included,
      }: {
        data?: model.generated.Event
        included?: { users?: Record<string, model.generated.User> }
      }) => {
        if (!data || !data?.event_users) return []
        const event = entityMappers.normalizeEvent(data)
        const users = Object.fromEntries(
          Object.entries(included?.users ?? {}).map(([id, user]) => [id, entityMappers.normalizeUser(user)]),
        )
        return Object.entries(data.event_users)
          .filter(([userId]) => !!users[userId])
          .map(([userId, eventUser]) => {
            return {
              ...entityMappers.normalizeEventUser(userId, eventUser),
              event,
              user: users[userId],
            }
          })
      },
    }),
    listPartialUsers: build.query<readonly model.NormalizedPartialUser[], ListUsersApiArg | null>({
      // TODO: Reconsider this, or double check it doesn't mess with the regular users cache.
      providesTags: cacher.providesList("Users"),
      query: (queryArg) => ({
        url: `/v0/users`,
        params: {
          filter: mapUserFilter(queryArg?.filter),
        },
      }),
      transformResponse: (baseValues?: model.generated.ListUsersApiResponse) => {
        return (baseValues?.data ?? []).map((elem) => {
          const userinfo = mappers.normalizeUserInfo(elem)
          return {
            id: elem.user_id,
            name: userinfo.name,
            email: userinfo.email,
            photo: elem.photo,
          }
        })
      },
    }),
    listUsersByUserRole: build.query<readonly model.NormalizedUser[], model.generated.UserRole>({
      providesTags: cacher.providesList("Users"),
      query: (userRole) => ({
        url: `/v0/users`,
        params: {
          filter: mapUserFilter({
            userRole,
          }),
        },
      }),
      transformResponse: transformListResponse(entityMappers.normalizeUser),
    }),
    getUser: build.query<model.NormalizedUser | undefined, GetResourceApiArg>({
      providesTags: cacher.cacheByIdArg("Users"),
      query: (queryArg) => ({
        url: `/v0/users`,
        params: {
          filter: mapUserFilter({
            userIds: mapGetApiArg(queryArg),
          }),
        },
      }),
      transformResponse: (baseValue: model.generated.ListUsersApiResponse) => {
        return transformListResponse(entityMappers.normalizeUser)(baseValue)[0] ?? null
      },
    }),

    // Events.
    listEvents: build.query<readonly model.NormalizedEvent[], ListEventsApiArg | null>({
      providesTags: (res, error) => {
        const results = res as readonly model.NormalizedEvent[] & {
          included?: { users?: readonly model.NormalizedEvent[] }
        }

        const eventTags = cacher.providesList("Events")(results, error)
        if (!results?.included?.users?.length) {
          return eventTags
        }
        return [...eventTags, ...results.included.users.map((elem) => ({ type: "Users", id: elem.id } as const))]
      },
      query: (queryArg) => ({
        url: `/v0/events`,
        params: {
          filter: mapEventFilter(queryArg?.filter),
          ...(queryArg?.includeUsers
            ? {
                include: ["users"] as const,
              }
            : undefined),
        },
      }),
      transformResponse: (
        baseValue: model.generated.ListEventsApiResponse,
      ): model.NormalizedEvent[] & { included?: { users?: readonly model.NormalizedUser[] } } => {
        const out = transformListResponse(entityMappers.normalizeEvent)(baseValue) as model.NormalizedEvent[] & {
          included?: { users?: model.NormalizedUser[] }
        }
        if (baseValue?.included?.users) {
          out.included = {
            users: Object.values(baseValue.included.users).map(entityMappers.normalizeUser),
          }
        }
        return out
      },
    }),
    listUserEvents: build.query<readonly model.NormalizedEvent[], string>({
      providesTags: ["Events", "Users"],
      query: (userId) => ({
        url: `/v0/events`,
        params: {
          filter: mapEventFilter({
            userIds: [userId],
          }),
        },
      }),
      transformResponse: transformListResponse(entityMappers.normalizeEvent),
    }),
    getEvent: build.query<model.NormalizedEvent | undefined, GetResourceApiArg & { include?: readonly ["users"] }>({
      providesTags: (res, error, args) => {
        const results = res as model.NormalizedEvent & {
          included?: { users?: model.NormalizedUser[] }
        }
        const eventTags = cacher.cacheByIdArg("Events")(results, error, args)
        if (!results?.included?.users?.length) {
          return eventTags
        }
        return [
          ...eventTags,
          ...(results?.included?.users?.map((elem) => ({ type: "Users", id: elem.id } as const)) ?? []),
        ]
      },
      query: (queryArg) => ({
        url: `/v0/events`,
        params: {
          filter: mapEventFilter({
            eventIds: mapGetApiArg(queryArg),
          }),
        },
      }),
      transformResponse: (baseValue: model.generated.ListEventsApiResponse) => {
        return transformListResponse(entityMappers.normalizeEvent)(baseValue)[0] ?? null
      },
    }),

    // Instructors.
    listInstructors: build.query<readonly model.NormalizedInstructor[], ListInstructorsApiArg | null>({
      providesTags: cacher.providesList("Instructors"),
      query: (queryArg) => ({
        url: `/v0/instructors`,
        params: {
          filter: mapInstructorFilter(queryArg?.filter),
        },
      }),
      transformResponse: transformListResponse(entityMappers.normalizeInstructor),
    }),
    getInstructor: build.query<model.NormalizedInstructor | undefined, GetResourceApiArg>({
      providesTags: cacher.cacheByIdArg("Instructors"),
      query: (queryArg) => ({
        url: `/v0/instructors`,
        params: {
          filter: mapInstructorFilter({
            instructorIds: mapGetApiArg(queryArg),
          }),
        },
      }),
      transformResponse: (baseValue: model.generated.ListInstructorsApiResponse) => {
        return transformListResponse(entityMappers.normalizeInstructor)(baseValue)[0] ?? null
      },
    }),

    // Demos.
    listEventDemos: build.query<
      readonly model.NormalizedDemo[] | readonly model.NormalizedDemoWIncludes[],
      ListDemosApiArg | null
    >({
      providesTags: cacher.providesList("Demos"),
      query: (queryArg) => ({
        url: `/v0/demos`,
        params: {
          filter: mapDemoFilter(queryArg?.filter),
          include: queryArg?.include,
        },
      }),
      transformResponse: (
        baseValue: model.generated.ListDemosApiResponse,
      ): model.NormalizedDemo[] | model.NormalizedDemoWIncludes[] => {
        const demos = transformListResponse(entityMappers.normalizeDemo)(baseValue) as model.NormalizedDemo[]
        const organizations = Object.values(baseValue.included?.organizations || []).map(
          entityMappers.normalizeOrganization,
        )
        const instructors = Object.values(baseValue.included?.instructors || []).map(entityMappers.normalizeInstructor)

        if (!organizations.length && !instructors.length) {
          return demos
        }

        return demos.map((demo) => ({
          ...demo,
          organizations: mapOrganizations(demo.organizations, organizations),
          instructors: mapInstructors(demo.instructors, instructors),
        }))
      },
    }),
    getEventDemo: build.query<
      model.NormalizedDemo | model.NormalizedDemoWIncludes | undefined,
      model.generated.GetDemoApiArg
    >({
      providesTags: (result) => (result ? [{ type: "Demos", id: result.id }] : ["Demos"]),
      query: (queryArg) => ({
        url: `/v0/demos/${queryArg.demoId}`,
        params: {
          include: queryArg?.include,
        },
      }),
      transformResponse: (
        baseValue: model.generated.GetDemoResponse,
      ): model.NormalizedDemo | model.NormalizedDemoWIncludes | undefined => {
        const demo = baseValue.data ? entityMappers.normalizeDemo(baseValue.data) : undefined
        let organizations: model.NormalizedOrganization[] = []
        let instructors: model.NormalizedInstructor[] = []

        if (demo) {
          if (baseValue.included?.organizations) {
            organizations = Object.values(baseValue.included.organizations).map(entityMappers.normalizeOrganization)
          }
          if (baseValue.included?.instructors) {
            instructors = Object.values(baseValue.included.instructors).map(entityMappers.normalizeInstructor)
          }

          return {
            ...demo,
            organizations: mapOrganizations(demo.organizations, organizations),
            instructors: mapInstructors(demo.instructors, instructors),
          }
        }

        return demo
      },
    }),

    // Locations.
    listLocations: build.query<readonly model.NormalizedLocation[], ListLocationsApiArg | null>({
      providesTags: cacher.providesList("Locations"),
      query: (queryArg) => ({
        url: `/v0/locations`,
        params: {
          filter: mapLocationFilter(queryArg?.filter),
        },
      }),
      transformResponse: transformListResponse(entityMappers.normalizeLocation),
    }),
    getLocation: build.query<model.NormalizedLocation | undefined, GetResourceApiArg>({
      providesTags: cacher.cacheByIdArg("Locations"),
      query: (queryArg) => ({
        url: `/v0/locations`,
        params: {
          filter: mapLocationFilter({
            locationIds: mapGetApiArg(queryArg),
          }),
        },
      }),
      transformResponse: (baseValue: model.generated.ListLocationsApiResponse) => {
        return transformListResponse(entityMappers.normalizeLocation)(baseValue)[0] ?? null
      },
    }),

    // Specialties.
    listSpecialties: build.query<readonly model.NormalizedSpecialty[], ListSpecialtiesApiArg | null>({
      providesTags: cacher.providesList("Specialties"),
      query: (queryArg) => ({
        url: `/v0/specialties`,
        params: {
          filter: mapSpecialtyFilter(queryArg?.filter),
        },
      }),
      transformResponse: transformListResponse(entityMappers.normalizeSpecialty),
    }),
    getSpecialty: build.query<model.NormalizedSpecialty | undefined, GetResourceApiArg>({
      providesTags: cacher.cacheByIdArg("Specialties"),
      query: (queryArg) => ({
        url: `/v0/specialties`,
        params: {
          filter: mapSpecialtyFilter({
            specialtyIds: mapGetApiArg(queryArg),
          }),
        },
      }),
      transformResponse: (baseValue: model.generated.ListSpecialtiesApiResponse) => {
        return transformListResponse(entityMappers.normalizeSpecialty)(baseValue)[0] ?? null
      },
    }),

    // Industries.
    listIndustries: build.query<readonly model.NormalizedIndustry[], ListIndustriesApiArg | null>({
      providesTags: cacher.providesList("Industries"),
      query: (queryArg) => ({
        url: `/v0/industries`,
        params: {
          filter: mapIndustryFilter(queryArg?.filter),
        },
      }),
      transformResponse: transformListResponse(entityMappers.normalizeIndustry),
    }),
    getIndustry: build.query<model.NormalizedIndustry | undefined, GetResourceApiArg>({
      providesTags: cacher.cacheByIdArg("Industries"),
      query: (queryArg) => ({
        url: `/v0/industries`,
        params: {
          filter: mapIndustryFilter({
            industryIds: mapGetApiArg(queryArg),
          }),
        },
      }),
      transformResponse: (baseValue: model.generated.ListIndustriesApiResponse) => {
        return transformListResponse(entityMappers.normalizeIndustry)(baseValue)[0] ?? null
      },
    }),

    // Devices.
    listDevices: build.query<readonly model.NormalizedDevice[], ListDevicesApiArg | null>({
      // TODO: Also provide serial number as IDs.
      providesTags: cacher.providesList("Devices"),
      query: (queryArg) => ({
        url: `/v0/devices`,
        params: {
          filter: mapDeviceFilter(queryArg?.filter),
        },
      }),
      transformResponse: transformListResponse(entityMappers.normalizeDevice),
    }),
    listUserDevices: build.query<readonly model.NormalizedDevice[], string>({
      providesTags: ["Devices", "Users"],
      query: (userId) => ({
        url: `/v0/devices`,
        params: {
          filter: mapDeviceFilter({
            userIds: [userId],
          }),
        },
      }),
      transformResponse: transformListResponse(entityMappers.normalizeDevice),
    }),
    getDevice: build.query<model.NormalizedDevice, GetDeviceApiArg>({
      providesTags: (_result, _error, id) =>
        [
          {
            type: "Devices",
            id: typeof id === "string" ? id : "id" in id ? id.id : id.serialNumber,
          },
        ] as const,
      query: (queryArg) => ({
        url: `/v0/devices`,
        params: {
          filter: mapDeviceFilter({
            deviceIds: typeof queryArg !== "string" && "serialNumber" in queryArg ? undefined : mapGetApiArg(queryArg),
            serialNumber:
              typeof queryArg !== "string" && "serialNumber" in queryArg ? queryArg.serialNumber : undefined,
          }),
        },
      }),
      transformResponse: (baseValue: model.generated.ListDevicesApiResponse) => {
        return transformListResponse(entityMappers.normalizeDevice)(baseValue)[0] ?? null
      },
    }),

    // FcmTokens.
    listFcmTokens: build.query<readonly model.NormalizedFcmToken[], ListFcmTokensApiArg | null>({
      // TODO: Also provide serial number as IDs.
      providesTags: cacher.providesList("FcmTokens"),
      query: (queryArg) => ({
        url: `/v0/fcm_tokens`,
        params: {
          filter: mapFcmTokenFilter(queryArg?.filter),
        },
      }),
      transformResponse: transformListResponse(entityMappers.normalizeFcmToken),
    }),
    getFcmtoken: build.query<model.NormalizedFcmToken, GetResourceApiArg>({
      providesTags: (_result, _error, id) => [{ type: "FcmTokens", id: typeof id === "string" ? id : id.id }] as const,
      query: (queryArg) => ({
        url: `/v0/fcm_tokens`,
        params: {
          filter: mapFcmTokenFilter({
            fcmTokenIds: mapGetApiArg(queryArg),
          }),
        },
      }),
      transformResponse: (baseValue: model.generated.ListFcmTokensApiResponse) => {
        return transformListResponse(entityMappers.normalizeFcmToken)(baseValue)[0] ?? null
      },
    }),

    // Images.
    listImages: build.query<readonly model.NormalizedImage[], ListImagesApiArg | null>({
      providesTags: cacher.providesList("Images"),
      query: (queryArg) => ({
        url: `/v0/images`,
        params: {
          filter: mapImageFilter(queryArg?.filter),
        },
      }),
      transformResponse: transformListResponse(entityMappers.normalizeImage),
    }),
    listImagesByTag: build.query<readonly model.NormalizedImage[], string>({
      providesTags: cacher.providesList("Images"),
      query: (tag) => ({
        url: `/v0/images`,
        params: {
          filter: mapImageFilter({
            tags: [tag],
          }),
        },
      }),
      transformResponse: transformListResponse(entityMappers.normalizeImage),
    }),
    getImage: build.query<model.NormalizedImage, GetImageApiArg>({
      providesTags: (_result, _error, id) =>
        [{ type: "Images", id: typeof id === "string" ? id : "id" in id ? id.id : id.name }] as const,
      query: (queryArg) => ({
        url: `/v0/images`,
        params: {
          filter: mapImageFilter({
            imageIds: typeof queryArg !== "string" && "name" in queryArg ? undefined : mapGetApiArg(queryArg),
            name: typeof queryArg !== "string" && "name" in queryArg ? queryArg.name : undefined,
          }),
        },
      }),
      transformResponse: (baseValue: model.generated.ListImagesApiResponse) => {
        return transformListResponse(entityMappers.normalizeImage)(baseValue)[0] ?? null
      },
    }),

    // Reports.
    getHeadsetRequestsReports: build.query<string, string | string[] | null>({
      providesTags: [
        { type: "Devices", id: "LIST" },
        { type: "Events", id: "LIST" },
        { type: "Users", id: "LIST" },
      ],
      query: (eventId) => {
        const filter: model.generated.GetHeadsetRequestsReportsApiArg["filter"] = {}
        if (eventId) {
          filter.event_ids = Array.isArray(eventId) ? eventId : [eventId]
        }
        return {
          url: `/v0/reports/headset_requests`,
          params: {
            filter,
          },
          responseHandler: (response) => response.text(),
        }
      },
    }),

    // Connection Reports.
    getEventConnectionsReport: build.query<readonly model.NormalizedConnectionReport[], string>({
      providesTags: ["Events", "Users"],
      query: (eventId) => ({
        url: `/v0/events/${eventId}/reports/connections`,
      }),
      transformResponse: transformListResponse(entityMappers.normalizeConnectionReport),
    }),

    // Speedtest
    speedTest: build.query<{ status: number }, model.generated.SpeedTestApiArg>({
      query: (args) => {
        return {
          url: `/v0/speedtest`,
          params: args,
        }
      },
      transformResponse: (_, meta) => {
        return { status: (meta as { response: { status: number } })?.response?.status }
      },
    }),

    // Headset Requests.
    getHeadsetRequest: build.query<model.NormalizedHeadsetRequest, GetHeadsetRequestApiArg>({
      providesTags: (_result, _error, id) =>
        [{ type: "HeadsetRequests", id: typeof id === "string" ? id : id.id }] as const,
      query: (queryArg) => ({
        url: `/v0/headset_requests`,
        params: {
          filter: {
            headset_request_ids: mapGetApiArg(queryArg),
          },
        },
      }),
      transformResponse: (baseValue: model.generated.ListHeadsetRequestsApiResponse) => {
        return transformListResponse(entityMappers.normalizeHeadsetRequest)(baseValue)[0] ?? null
      },
    }),
    listHeadsetRequests: build.query<readonly model.NormalizedHeadsetRequest[], ListHeadsetRequestsApiArg | null>({
      providesTags: cacher.providesList("HeadsetRequests"),
      query: (queryArg) => ({
        url: `/v0/headset_requests`,
        params: {
          filter: mapHeadsetRequestFilter(queryArg?.filter),
        },
      }),
      transformResponse: transformListResponse(entityMappers.normalizeHeadsetRequest),
    }),
    listHeadsetRequestHistory: build.query<readonly model.NormalizedHeadsetRequest[], GetHeadsetRequestApiArg>({
      providesTags: (_result, _error, id) =>
        [{ type: "HeadsetRequests", id: typeof id === "string" ? id : id.id }] as const,
      query: (queryArg) => ({
        url: `/v0/headset_requests/${typeof queryArg === "string" ? queryArg : queryArg.id}/history`,
      }),
      transformResponse: transformListResponse(entityMappers.normalizeHeadsetRequest),
    }),

    listStreamServers: build.query<readonly model.NormalizedStreamServer[], void>({
      providesTags: () => [{ type: "StreamServers", id: "LIST" }] as const,
      query: () => ({
        url: "/v0/stream_servers",
      }),
      transformResponse: transformListResponse(entityMappers.normalizeStreamServer),
    }),
    listCxrServers: build.query<readonly model.NormalizedCloudXRServer[], void>({
      providesTags: () => [{ type: "CloudXRServers", id: "LIST" }] as const,
      query: () => ({
        url: "/v0/cxr_servers",
      }),
      transformResponse: transformListResponse(entityMappers.normalizeCloudXRServer),
    }),
    getCxrServerAmIs: build.query<readonly { id: string }[], void>({
      query: () => ({
        url: "/v0/cxr_servers/meta/amis",
      }),
      transformResponse: (raw: { data: { ami_image_id: string }[] }) => {
        return !raw.data ? [] : raw.data.map((elem) => ({ id: elem.ami_image_id }))
      },
    }),
    getCxrServerInstanceTypes: build.query<readonly string[], void>({
      query: () => ({
        url: "/v0/cxr_servers/meta/instance_types",
      }),
      transformResponse: (raw: { data: string[] }) => {
        return raw.data ?? []
      },
    }),
  }),
})
