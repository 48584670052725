import React from "react"
import { Input as RebassInput } from "@rebass/forms"
import { colors } from "../../etc/theme"

export const Input = ({ highlight, ...rest }) => {
  return (
    <RebassInput
      type="number"
      disabled
      width={56}
      height={80}
      mx="10px"
      p="8px 0px 8px 14px"
      backgroundColor={colors.gray11}
      textAlign="center"
      fontSize="2.25rem"
      css={{ border: "1px solid", borderColor: highlight ? colors.primary : "transparent", borderRadius: 8 }}
      {...rest}
    />
  )
}
