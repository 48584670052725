/**
 * Converts seconds to 'hh:mm:ss' format
 *
 * @param {Number} input seconds
 * @param {String} separator
 * @returns {String}
 */
const convertSecToHHMMSS = (input, separator = ":") => {
  const pad = (input) => (input < 10 ? "0" + input : input)

  return [pad(Math.floor(input / 3600)), pad(Math.floor((input % 3600) / 60)), pad(Math.floor(input % 60))].join(
    separator,
  )
}

/**
 * Converts date to hh:mm AM/PM format
 *
 * @param {Date} date
 * @returns {string}
 */
const formatAMPM = (date) => {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? "PM" : "AM"
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes

  return hours + ":" + minutes + " " + ampm
}

const getTimeInEST = (date) => {
  return new Date(date.toLocaleString("en-US", { timeZone: "America/New_York" }))
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "Jun",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

export { convertSecToHHMMSS, formatAMPM, getTimeInEST, months }
