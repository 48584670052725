import React from "react"
import { Button as RebassButton } from "rebass"
import { colors } from "../../etc/theme"

export const Button = ({ variant, ...rest }) => {
  const baseProps = {
    fontWeight: 600,
    fontSize: 20,
    css: {
      userSelect: "none",
      borderRadius: 8,
      cursor: "pointer",
      ":disabled": {
        color: `${colors.gray4} !important`,
        backgroundColor: `${colors.gray10} !important`,
        borderColor: "transparent !important",
        cursor: "not-allowed",
        ":hover": {
          backgroundColor: `${colors.gray10} !important`,
        },
      },
    },
  }

  const primaryProps = {
    color: colors.white,
    backgroundColor: colors.blue,
    css: {
      ...baseProps.css,
      border: "1px solid transparent",
      ":hover": {
        backgroundColor: colors.blueHover,
      },
    },
  }

  const secondaryProps = {
    color: colors.white,
    backgroundColor: colors.gray9,
    css: {
      ...baseProps.css,
      border: "1px solid transparent",
      ":hover": {
        backgroundColor: colors.gray8,
      },
    },
  }

  const outlineProps = {
    color: colors.white,
    backgroundColor: "transparent",
    css: {
      ...baseProps.css,
      border: `1px solid ${colors.blue}`,
      ":hover": {
        color: colors.blue,
        borderColor: colors.blueHover,
        backgroundColor: "transparent",
      },
    },
  }

  const buttonProps = variant === "outline" ? outlineProps : variant === "secondary" ? secondaryProps : primaryProps

  return <RebassButton py={16} {...buttonProps} {...rest} />
}
