import React, { createContext, useRef, useState } from "react"
import { isEqual } from "lodash"

export const DraggableContext = createContext({})

const Draggable = ({ children }) => {
  const boxes = useRef([])
  const [intersections, setIntersections] = useState([])

  const prevIntersections = useRef([])

  const setBox = ({ mesh, boundingBox }) => {
    // filter intersections and order them so the laset interacted is first.
    boxes.current = boxes.current.filter((box) => box.mesh !== mesh)
    boxes.current.unshift({
      mesh,
      boundingBox,
    })

    const getBoxIntersections = () => {
      let boxIntersects = []

      // always return the top overlay
      boxIntersects[0] = boxes.current[0]

      // test each overlay against
      for (let i = 0; i < boxes.current.length; i++) {
        for (let j = 0; j < boxes.current.length; j++) {
          // no need to test against itself
          if (i !== j) {
            const hasIntersection = boxes.current[i].boundingBox.intersectsBox(boxes.current[j].boundingBox)

            if (hasIntersection) {
              boxIntersects[i] = boxes.current[i]
              break
            }
          }
        }
      }
      return boxIntersects
    }

    const intersectedBoxes = getBoxIntersections()

    // we'll compare current intersections with pervious, and update state only on change.
    if (intersectedBoxes.length > 0 && !isEqual(intersectedBoxes, prevIntersections.current)) {
      prevIntersections.current = intersectedBoxes
      setIntersections(prevIntersections.current)
    }
  }

  return (
    <DraggableContext.Provider
      value={{
        setBox,
        intersections,
      }}
    >
      {children}
    </DraggableContext.Provider>
  )
}

export default Draggable
