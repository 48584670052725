import { api } from "./api.empty"
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    whoAmI: build.query<WhoAmIApiResponse, WhoAmIApiArg>({
      query: () => ({ url: `/v0/whoami` }),
    }),
    listEvents: build.query<ListEventsApiResponse, ListEventsApiArg>({
      query: (queryArg) => ({
        url: `/v0/events`,
        params: {
          include: queryArg.include,
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    createEvent: build.mutation<CreateEventApiResponse, CreateEventApiArg>({
      query: (queryArg) => ({
        url: `/v0/events`,
        method: "POST",
        body: queryArg.createEventRequest,
      }),
    }),
    countEvents: build.query<CountEventsApiResponse, CountEventsApiArg>({
      query: () => ({ url: `/v0/events/count` }),
    }),
    getEvent: build.query<GetEventApiResponse, GetEventApiArg>({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}`,
        params: { include: queryArg.include },
      }),
    }),
    updateEvent: build.mutation<UpdateEventApiResponse, UpdateEventApiArg>({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}`,
        method: "PUT",
        body: queryArg.updateEventRequest,
      }),
    }),
    deleteEvent: build.mutation<DeleteEventApiResponse, DeleteEventApiArg>({
      query: (queryArg) => ({ url: `/v0/events/${queryArg.eventId}`, method: "DELETE" }),
    }),
    updateEventRegistrationState: build.mutation<
      UpdateEventRegistrationStateApiResponse,
      UpdateEventRegistrationStateApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/registration-state`,
        method: "POST",
        body: queryArg.updateEventRegistrationStateRequest,
      }),
    }),
    updateEventStatus: build.mutation<UpdateEventStatusApiResponse, UpdateEventStatusApiArg>({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/status`,
        method: "POST",
        body: queryArg.updateEventStatusRequest,
      }),
    }),
    updateEventSettings: build.mutation<UpdateEventSettingsApiResponse, UpdateEventSettingsApiArg>({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/settings`,
        method: "POST",
        body: queryArg.updateEventSettingsRequest,
      }),
    }),
    startEventStreamServer: build.mutation<StartEventStreamServerApiResponse, StartEventStreamServerApiArg>({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/servers/start`,
        method: "POST",
      }),
    }),
    terminateEventStreamServer: build.mutation<TerminateEventStreamServerApiResponse, TerminateEventStreamServerApiArg>(
      {
        query: (queryArg) => ({
          url: `/v0/events/${queryArg.eventId}/servers/terminate`,
          method: "DELETE",
        }),
      },
    ),
    upsertEventUser: build.mutation<UpsertEventUserApiResponse, UpsertEventUserApiArg>({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/users/${queryArg.userId}`,
        method: "POST",
      }),
    }),
    deleteEventUser: build.mutation<DeleteEventUserApiResponse, DeleteEventUserApiArg>({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/users/${queryArg.userId}`,
        method: "DELETE",
      }),
    }),
    postEventUserStatusActions: build.mutation<PostEventUserStatusActionsApiResponse, PostEventUserStatusActionsApiArg>(
      {
        query: (queryArg) => ({
          url: `/v0/events/${queryArg.eventId}/users/${queryArg.userId}/status/${queryArg.action}`,
          method: "POST",
        }),
      },
    ),
    postEventUserRoleAction: build.mutation<PostEventUserRoleActionApiResponse, PostEventUserRoleActionApiArg>({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/users/${queryArg.userId}/role`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postEventUserNpsAction: build.mutation<PostEventUserNpsActionApiResponse, PostEventUserNpsActionApiArg>({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/users/${queryArg.userId}/nps`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    listUsers: build.query<ListUsersApiResponse, ListUsersApiArg>({
      query: (queryArg) => ({
        url: `/v0/users`,
        params: {
          include: queryArg.include,
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
      query: (queryArg) => ({ url: `/v0/users`, method: "POST", body: queryArg.createUserRequest }),
    }),
    countUsers: build.query<CountUsersApiResponse, CountUsersApiArg>({
      query: () => ({ url: `/v0/users/count` }),
    }),
    inviteEmail: build.mutation<InviteEmailApiResponse, InviteEmailApiArg>({
      query: (queryArg) => ({
        url: `/v0/users/email/invite`,
        method: "POST",
        body: queryArg.emailInviteRequest,
      }),
    }),
    verifyEmail: build.mutation<VerifyEmailApiResponse, VerifyEmailApiArg>({
      query: (queryArg) => ({
        url: `/v0/users/email/verify`,
        method: "PUT",
        body: queryArg.emailVerifyRequest,
      }),
    }),
    getUser: build.query<GetUserApiResponse, GetUserApiArg>({
      query: (queryArg) => ({
        url: `/v0/users/${queryArg.userId}`,
        params: { include: queryArg.include },
      }),
    }),
    updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
      query: (queryArg) => ({
        url: `/v0/users/${queryArg.userId}`,
        method: "PUT",
        body: queryArg.updateUserRequest,
      }),
    }),
    deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
      query: (queryArg) => ({ url: `/v0/users/${queryArg.userId}`, method: "DELETE" }),
    }),
    upsertUserOrganization: build.mutation<UpsertUserOrganizationApiResponse, UpsertUserOrganizationApiArg>({
      query: (queryArg) => ({
        url: `/v0/users/${queryArg.userId}/organizations/${queryArg.organizationId}`,
        method: "POST",
        body: queryArg.upsertUserOrganizationRequest,
      }),
    }),
    deleteUserOrganization: build.mutation<DeleteUserOrganizationApiResponse, DeleteUserOrganizationApiArg>({
      query: (queryArg) => ({
        url: `/v0/users/${queryArg.userId}/organizations/${queryArg.organizationId}`,
        method: "DELETE",
      }),
    }),
    sendEmailInviteGeneral: build.mutation<SendEmailInviteGeneralApiResponse, SendEmailInviteGeneralApiArg>({
      query: (queryArg) => ({ url: `/v0/users/${queryArg.userId}/invites`, method: "POST" }),
    }),
    sendEmailInviteEvent: build.mutation<SendEmailInviteEventApiResponse, SendEmailInviteEventApiArg>({
      query: (queryArg) => ({
        url: `/v0/users/${queryArg.userId}/invites/events/${queryArg.eventId}`,
        method: "POST",
        body: queryArg.sendInvitationRequest,
      }),
    }),
    userRegistrationStatus: build.query<UserRegistrationStatusApiResponse, UserRegistrationStatusApiArg>({
      query: (queryArg) => ({ url: `/v0/users/${queryArg.email}/status` }),
    }),
    verifyUser: build.mutation<VerifyUserApiResponse, VerifyUserApiArg>({
      query: (queryArg) => ({ url: `/v0/users/${queryArg.userId}/verify`, method: "PUT" }),
    }),
    sendEmailVerification: build.mutation<SendEmailVerificationApiResponse, SendEmailVerificationApiArg>({
      query: () => ({ url: `/v0/users/verification`, method: "POST" }),
    }),
    sendAccountRemovalRequestNotifications: build.mutation<
      SendAccountRemovalRequestNotificationsApiResponse,
      SendAccountRemovalRequestNotificationsApiArg
    >({
      query: () => ({ url: `/v0/users/removal`, method: "POST" }),
    }),
    listOrganizations: build.query<ListOrganizationsApiResponse, ListOrganizationsApiArg>({
      query: (queryArg) => ({
        url: `/v0/organizations`,
        params: { sort: queryArg.sort, page: queryArg.page, filter: queryArg.filter },
      }),
    }),
    createOrganization: build.mutation<CreateOrganizationApiResponse, CreateOrganizationApiArg>({
      query: (queryArg) => ({
        url: `/v0/organizations`,
        method: "POST",
        body: queryArg.createOrganizationRequest,
      }),
    }),
    countOrganizations: build.query<CountOrganizationsApiResponse, CountOrganizationsApiArg>({
      query: () => ({ url: `/v0/organizations/count` }),
    }),
    getOrganization: build.query<GetOrganizationApiResponse, GetOrganizationApiArg>({
      query: (queryArg) => ({ url: `/v0/organizations/${queryArg.orgId}` }),
    }),
    updateOrganization: build.mutation<UpdateOrganizationApiResponse, UpdateOrganizationApiArg>({
      query: (queryArg) => ({
        url: `/v0/organizations/${queryArg.orgId}`,
        method: "PUT",
        body: queryArg.updateOrganizationRequest,
      }),
    }),
    deleteOrganization: build.mutation<DeleteOrganizationApiResponse, DeleteOrganizationApiArg>({
      query: (queryArg) => ({ url: `/v0/organizations/${queryArg.orgId}`, method: "DELETE" }),
    }),
    listInstructors: build.query<ListInstructorsApiResponse, ListInstructorsApiArg>({
      query: (queryArg) => ({
        url: `/v0/instructors`,
        params: {
          include: queryArg.include,
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    createInstructor: build.mutation<CreateInstructorApiResponse, CreateInstructorApiArg>({
      query: (queryArg) => ({
        url: `/v0/instructors`,
        method: "POST",
        body: queryArg.createInstructorRequest,
      }),
    }),
    countInstructors: build.query<CountInstructorsApiResponse, CountInstructorsApiArg>({
      query: () => ({ url: `/v0/instructors/count` }),
    }),
    getInstructor: build.query<GetInstructorApiResponse, GetInstructorApiArg>({
      query: (queryArg) => ({
        url: `/v0/instructors/${queryArg.instructorId}`,
        params: { include: queryArg.include },
      }),
    }),
    updateInstructor: build.mutation<UpdateInstructorApiResponse, UpdateInstructorApiArg>({
      query: (queryArg) => ({
        url: `/v0/instructors/${queryArg.instructorId}`,
        method: "PUT",
        body: queryArg.updateInstructorRequest,
      }),
    }),
    deleteInstructor: build.mutation<DeleteInstructorApiResponse, DeleteInstructorApiArg>({
      query: (queryArg) => ({ url: `/v0/instructors/${queryArg.instructorId}`, method: "DELETE" }),
    }),
    listLocations: build.query<ListLocationsApiResponse, ListLocationsApiArg>({
      query: (queryArg) => ({
        url: `/v0/locations`,
        params: {
          include: queryArg.include,
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    createLocation: build.mutation<CreateLocationApiResponse, CreateLocationApiArg>({
      query: (queryArg) => ({
        url: `/v0/locations`,
        method: "POST",
        body: queryArg.createLocationRequest,
      }),
    }),
    countLocations: build.query<CountLocationsApiResponse, CountLocationsApiArg>({
      query: () => ({ url: `/v0/locations/count` }),
    }),
    getLocation: build.query<GetLocationApiResponse, GetLocationApiArg>({
      query: (queryArg) => ({
        url: `/v0/locations/${queryArg.locationId}`,
        params: { include: queryArg.include },
      }),
    }),
    updateLocation: build.mutation<UpdateLocationApiResponse, UpdateLocationApiArg>({
      query: (queryArg) => ({
        url: `/v0/locations/${queryArg.locationId}`,
        method: "PUT",
        body: queryArg.updateLocationRequest,
      }),
    }),
    deleteLocation: build.mutation<DeleteLocationApiResponse, DeleteLocationApiArg>({
      query: (queryArg) => ({ url: `/v0/locations/${queryArg.locationId}`, method: "DELETE" }),
    }),
    listSpecialties: build.query<ListSpecialtiesApiResponse, ListSpecialtiesApiArg>({
      query: (queryArg) => ({
        url: `/v0/specialties`,
        params: { sort: queryArg.sort, page: queryArg.page, filter: queryArg.filter },
      }),
    }),
    createSpecialty: build.mutation<CreateSpecialtyApiResponse, CreateSpecialtyApiArg>({
      query: (queryArg) => ({
        url: `/v0/specialties`,
        method: "POST",
        body: queryArg.createSpecialtyRequest,
      }),
    }),
    getSpecialty: build.query<GetSpecialtyApiResponse, GetSpecialtyApiArg>({
      query: (queryArg) => ({ url: `/v0/specialties/${queryArg.specialtyId}` }),
    }),
    updateSpecialty: build.mutation<UpdateSpecialtyApiResponse, UpdateSpecialtyApiArg>({
      query: (queryArg) => ({
        url: `/v0/specialties/${queryArg.specialtyId}`,
        method: "PUT",
        body: queryArg.updateSpecialtyRequest,
      }),
    }),
    deleteSpecialty: build.mutation<DeleteSpecialtyApiResponse, DeleteSpecialtyApiArg>({
      query: (queryArg) => ({ url: `/v0/specialties/${queryArg.specialtyId}`, method: "DELETE" }),
    }),
    getSubSpecialty: build.query<GetSubSpecialtyApiResponse, GetSubSpecialtyApiArg>({
      query: (queryArg) => ({
        url: `/v0/specialties/${queryArg.specialtyId}/${queryArg.subSpecialtyId}`,
      }),
    }),
    updateSubSpecialty: build.mutation<UpdateSubSpecialtyApiResponse, UpdateSubSpecialtyApiArg>({
      query: (queryArg) => ({
        url: `/v0/specialties/${queryArg.specialtyId}/${queryArg.subSpecialtyId}`,
        method: "PUT",
        body: queryArg.updateSpecialtyRequest,
      }),
    }),
    deleteSubSpecialty: build.mutation<DeleteSubSpecialtyApiResponse, DeleteSubSpecialtyApiArg>({
      query: (queryArg) => ({
        url: `/v0/specialties/${queryArg.specialtyId}/${queryArg.subSpecialtyId}`,
        method: "DELETE",
      }),
    }),
    listIndustries: build.query<ListIndustriesApiResponse, ListIndustriesApiArg>({
      query: (queryArg) => ({
        url: `/v0/industries`,
        params: { sort: queryArg.sort, page: queryArg.page, filter: queryArg.filter },
      }),
    }),
    createIndustry: build.mutation<CreateIndustryApiResponse, CreateIndustryApiArg>({
      query: (queryArg) => ({
        url: `/v0/industries`,
        method: "POST",
        body: queryArg.createIndustryRequest,
      }),
    }),
    getIndustry: build.query<GetIndustryApiResponse, GetIndustryApiArg>({
      query: (queryArg) => ({ url: `/v0/industries/${queryArg.industryId}` }),
    }),
    updateIndustry: build.mutation<UpdateIndustryApiResponse, UpdateIndustryApiArg>({
      query: (queryArg) => ({
        url: `/v0/industries/${queryArg.industryId}`,
        method: "PUT",
        body: queryArg.updateIndustryRequest,
      }),
    }),
    deleteIndustry: build.mutation<DeleteIndustryApiResponse, DeleteIndustryApiArg>({
      query: (queryArg) => ({ url: `/v0/industries/${queryArg.industryId}`, method: "DELETE" }),
    }),
    getIndustryJobType: build.query<GetIndustryJobTypeApiResponse, GetIndustryJobTypeApiArg>({
      query: (queryArg) => ({ url: `/v0/industries/${queryArg.industryId}/${queryArg.jobTypeId}` }),
    }),
    updateIndustryJobType: build.mutation<UpdateIndustryJobTypeApiResponse, UpdateIndustryJobTypeApiArg>({
      query: (queryArg) => ({
        url: `/v0/industries/${queryArg.industryId}/${queryArg.jobTypeId}`,
        method: "PUT",
        body: queryArg.updateIndustryRequest,
      }),
    }),
    deleteIndustryJobType: build.mutation<DeleteIndustryJobTypeApiResponse, DeleteIndustryJobTypeApiArg>({
      query: (queryArg) => ({
        url: `/v0/industries/${queryArg.industryId}/${queryArg.jobTypeId}`,
        method: "DELETE",
      }),
    }),
    listDevices: build.query<ListDevicesApiResponse, ListDevicesApiArg>({
      query: (queryArg) => ({
        url: `/v0/devices`,
        params: {
          include: queryArg.include,
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    createDevice: build.mutation<CreateDeviceApiResponse, CreateDeviceApiArg>({
      query: (queryArg) => ({
        url: `/v0/devices`,
        method: "POST",
        body: queryArg.createDeviceRequest,
      }),
    }),
    pairDevice: build.mutation<PairDeviceApiResponse, PairDeviceApiArg>({
      query: (queryArg) => ({
        url: `/v0/pairings`,
        method: "POST",
        body: queryArg.pairDeviceRequest,
      }),
    }),
    getDevice: build.query<GetDeviceApiResponse, GetDeviceApiArg>({
      query: (queryArg) => ({
        url: `/v0/devices/${queryArg.deviceId}`,
        params: { include: queryArg.include },
      }),
    }),
    updateDevice: build.mutation<UpdateDeviceApiResponse, UpdateDeviceApiArg>({
      query: (queryArg) => ({
        url: `/v0/devices/${queryArg.deviceId}`,
        method: "PUT",
        body: queryArg.updateDeviceRequest,
      }),
    }),
    deleteDevice: build.mutation<DeleteDeviceApiResponse, DeleteDeviceApiArg>({
      query: (queryArg) => ({ url: `/v0/devices/${queryArg.deviceId}`, method: "DELETE" }),
    }),
    listFcmTokens: build.query<ListFcmTokensApiResponse, ListFcmTokensApiArg>({
      query: (queryArg) => ({
        url: `/v0/fcm_tokens`,
        params: {
          include: queryArg.include,
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    createFcmToken: build.mutation<CreateFcmTokenApiResponse, CreateFcmTokenApiArg>({
      query: (queryArg) => ({
        url: `/v0/fcm_tokens`,
        method: "POST",
        body: queryArg.createFcmTokenRequest,
      }),
    }),
    getFcmToken: build.query<GetFcmTokenApiResponse, GetFcmTokenApiArg>({
      query: (queryArg) => ({
        url: `/v0/fcm_tokens/${queryArg.fcmTokenId}`,
        params: { include: queryArg.include },
      }),
    }),
    updateFcmToken: build.mutation<UpdateFcmTokenApiResponse, UpdateFcmTokenApiArg>({
      query: (queryArg) => ({
        url: `/v0/fcm_tokens/${queryArg.fcmTokenId}`,
        method: "PUT",
        body: queryArg.updateFcmTokenRequest,
      }),
    }),
    deleteFcmToken: build.mutation<DeleteFcmTokenApiResponse, DeleteFcmTokenApiArg>({
      query: (queryArg) => ({ url: `/v0/fcm_tokens/${queryArg.fcmTokenId}`, method: "DELETE" }),
    }),
    getHeadsetRequestsReports: build.query<GetHeadsetRequestsReportsApiResponse, GetHeadsetRequestsReportsApiArg>({
      query: (queryArg) => ({
        url: `/v0/reports/headset_requests`,
        params: { filter: queryArg.filter },
      }),
    }),
    getConnectionsReport: build.query<GetConnectionsReportApiResponse, GetConnectionsReportApiArg>({
      query: (queryArg) => ({ url: `/v0/events/${queryArg.eventId}/reports/connections` }),
    }),
    createConnectionReport: build.mutation<CreateConnectionReportApiResponse, CreateConnectionReportApiArg>({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/reports/connections`,
        method: "POST",
        body: queryArg.createEventConnectionsReportRequest,
      }),
    }),
    getCo2EmissionsReport: build.query<GetCo2EmissionsReportApiResponse, GetCo2EmissionsReportApiArg>({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/reports/co2_emission_reductions`,
      }),
    }),
    createCo2EmissionReport: build.mutation<CreateCo2EmissionReportApiResponse, CreateCo2EmissionReportApiArg>({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/reports/co2_emission_reductions`,
        method: "POST",
      }),
    }),
    listImages: build.query<ListImagesApiResponse, ListImagesApiArg>({
      query: (queryArg) => ({
        url: `/v0/images`,
        params: {
          include: queryArg.include,
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    createImage: build.mutation<CreateImageApiResponse, CreateImageApiArg>({
      query: (queryArg) => ({
        url: `/v0/images`,
        method: "POST",
        body: queryArg.createImageRequest,
      }),
    }),
    getImage: build.query<GetImageApiResponse, GetImageApiArg>({
      query: (queryArg) => ({
        url: `/v0/images/${queryArg.imageId}`,
        params: { include: queryArg.include },
      }),
    }),
    updateImage: build.mutation<UpdateImageApiResponse, UpdateImageApiArg>({
      query: (queryArg) => ({
        url: `/v0/images/${queryArg.imageId}`,
        method: "PUT",
        body: queryArg.updateImageRequest,
      }),
    }),
    deleteImage: build.mutation<DeleteImageApiResponse, DeleteImageApiArg>({
      query: (queryArg) => ({ url: `/v0/images/${queryArg.imageId}`, method: "DELETE" }),
    }),
    listDemos: build.query<ListDemosApiResponse, ListDemosApiArg>({
      query: (queryArg) => ({
        url: `/v0/demos`,
        params: {
          include: queryArg.include,
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    createDemo: build.mutation<CreateDemoApiResponse, CreateDemoApiArg>({
      query: (queryArg) => ({ url: `/v0/demos`, method: "POST", body: queryArg.createDemoRequest }),
    }),
    getDemo: build.query<GetDemoApiResponse, GetDemoApiArg>({
      query: (queryArg) => ({
        url: `/v0/demos/${queryArg.demoId}`,
        params: { include: queryArg.include },
      }),
    }),
    updateDemo: build.mutation<UpdateDemoApiResponse, UpdateDemoApiArg>({
      query: (queryArg) => ({
        url: `/v0/demos/${queryArg.demoId}`,
        method: "PUT",
        body: queryArg.updateDemoRequest,
      }),
    }),
    deleteDemo: build.mutation<DeleteDemoApiResponse, DeleteDemoApiArg>({
      query: (queryArg) => ({ url: `/v0/demos/${queryArg.demoId}`, method: "DELETE" }),
    }),
    speedTest: build.query<SpeedTestApiResponse, SpeedTestApiArg>({
      query: (queryArg) => ({
        url: `/v0/speedtest`,
        params: { size: queryArg.size, code: queryArg.code },
      }),
    }),
    listHeadsetRequests: build.query<ListHeadsetRequestsApiResponse, ListHeadsetRequestsApiArg>({
      query: (queryArg) => ({
        url: `/v0/headset_requests`,
        params: { page: queryArg.page, filter: queryArg.filter },
      }),
    }),
    createHeadsetRequest: build.mutation<CreateHeadsetRequestApiResponse, CreateHeadsetRequestApiArg>({
      query: (queryArg) => ({
        url: `/v0/headset_requests`,
        method: "POST",
        body: queryArg.createHeadsetRequestRequest,
      }),
    }),
    getHeadsetRequest: build.query<GetHeadsetRequestApiResponse, GetHeadsetRequestApiArg>({
      query: (queryArg) => ({ url: `/v0/headset_requests/${queryArg.headsetRequestId}` }),
    }),
    updateHeadsetRequest: build.mutation<UpdateHeadsetRequestApiResponse, UpdateHeadsetRequestApiArg>({
      query: (queryArg) => ({
        url: `/v0/headset_requests/${queryArg.headsetRequestId}`,
        method: "PUT",
        body: queryArg.updateHeadsetRequestRequest,
      }),
    }),
    deleteHeadsetRequest: build.mutation<DeleteHeadsetRequestApiResponse, DeleteHeadsetRequestApiArg>({
      query: (queryArg) => ({
        url: `/v0/headset_requests/${queryArg.headsetRequestId}`,
        method: "DELETE",
      }),
    }),
    linkHeadsetRequestEvents: build.mutation<LinkHeadsetRequestEventsApiResponse, LinkHeadsetRequestEventsApiArg>({
      query: (queryArg) => ({
        url: `/v0/headset_requests/${queryArg.headsetRequestId}/link_events`,
        method: "PUT",
        body: queryArg.linkHeadsetRequestEventsRequest,
      }),
    }),
    listHeadsetRequestHistory: build.query<ListHeadsetRequestHistoryApiResponse, ListHeadsetRequestHistoryApiArg>({
      query: (queryArg) => ({ url: `/v0/headset_requests/${queryArg.headsetRequestId}/history` }),
    }),
    createJoinCode: build.mutation<CreateJoinCodeApiResponse, CreateJoinCodeApiArg>({
      query: (queryArg) => ({
        url: `/v0/join_codes`,
        method: "POST",
        body: queryArg.createJoinCodeRequest,
      }),
    }),
    exchangeJoinCode: build.mutation<ExchangeJoinCodeApiResponse, ExchangeJoinCodeApiArg>({
      query: (queryArg) => ({
        url: `/v0/join_codes/exchange`,
        method: "POST",
        body: queryArg.exchangeJoinCodeRequest,
      }),
    }),
    listStreamServers: build.query<ListStreamServersApiResponse, ListStreamServersApiArg>({
      query: (queryArg) => ({
        url: `/v0/stream_servers`,
        params: { page: queryArg.page, filter: queryArg.filter },
      }),
    }),
    upsertStreamServer: build.mutation<UpsertStreamServerApiResponse, UpsertStreamServerApiArg>({
      query: (queryArg) => ({
        url: `/v0/stream_servers`,
        method: "PUT",
        body: queryArg.upsertStreamServerRequest,
      }),
    }),
    getStreamServer: build.query<GetStreamServerApiResponse, GetStreamServerApiArg>({
      query: (queryArg) => ({ url: `/v0/stream_servers/${queryArg.streamServerId}` }),
    }),
    deleteStreamServer: build.mutation<DeleteStreamServerApiResponse, DeleteStreamServerApiArg>({
      query: (queryArg) => ({
        url: `/v0/stream_servers/${queryArg.streamServerId}`,
        method: "DELETE",
      }),
    }),
    listCxrServers: build.query<ListCxrServersApiResponse, ListCxrServersApiArg>({
      query: (queryArg) => ({
        url: `/v0/cxr_servers`,
        params: { page: queryArg.page, filter: queryArg.filter },
      }),
    }),
    createCxrServer: build.mutation<CreateCxrServerApiResponse, CreateCxrServerApiArg>({
      query: (queryArg) => ({
        url: `/v0/cxr_servers`,
        method: "POST",
        body: queryArg.createCxrServerRequest,
      }),
    }),
    refreshCxrServers: build.mutation<RefreshCxrServersApiResponse, RefreshCxrServersApiArg>({
      query: () => ({ url: `/v0/cxr_servers/refresh`, method: "POST" }),
    }),
    grabCxrServer: build.mutation<GrabCxrServerApiResponse, GrabCxrServerApiArg>({
      query: () => ({ url: `/v0/cxr_servers/grab`, method: "POST" }),
    }),
    getCxrServerInstanceTypes: build.query<GetCxrServerInstanceTypesApiResponse, GetCxrServerInstanceTypesApiArg>({
      query: () => ({ url: `/v0/cxr_servers/meta/instance_types` }),
    }),
    getCxrServerAmIs: build.query<GetCxrServerAmIsApiResponse, GetCxrServerAmIsApiArg>({
      query: () => ({ url: `/v0/cxr_servers/meta/amis` }),
    }),
    getCxrServer: build.query<GetCxrServerApiResponse, GetCxrServerApiArg>({
      query: (queryArg) => ({ url: `/v0/cxr_servers/${queryArg.cxrServerId}` }),
    }),
    updateCxrServer: build.mutation<UpdateCxrServerApiResponse, UpdateCxrServerApiArg>({
      query: (queryArg) => ({
        url: `/v0/cxr_servers/${queryArg.cxrServerId}`,
        method: "PUT",
        body: queryArg.updateCxrServerRequest,
      }),
    }),
    deleteCxrServer: build.mutation<DeleteCxrServerApiResponse, DeleteCxrServerApiArg>({
      query: (queryArg) => ({ url: `/v0/cxr_servers/${queryArg.cxrServerId}`, method: "DELETE" }),
    }),
    startCxrServer: build.mutation<StartCxrServerApiResponse, StartCxrServerApiArg>({
      query: (queryArg) => ({
        url: `/v0/cxr_servers/${queryArg.cxrServerId}/start`,
        method: "POST",
      }),
    }),
    stopCxrServer: build.mutation<StopCxrServerApiResponse, StopCxrServerApiArg>({
      query: (queryArg) => ({
        url: `/v0/cxr_servers/${queryArg.cxrServerId}/stop`,
        method: "POST",
      }),
    }),
    waitCxrServer: build.mutation<WaitCxrServerApiResponse, WaitCxrServerApiArg>({
      query: (queryArg) => ({
        url: `/v0/cxr_servers/${queryArg.cxrServerId}/wait`,
        method: "POST",
        body: queryArg.waitCxrServerRequest,
      }),
    }),
    updateCxrServerInstanceState: build.mutation<
      UpdateCxrServerInstanceStateApiResponse,
      UpdateCxrServerInstanceStateApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/cxr_servers/${queryArg.cxrServerId}/instance_state`,
        method: "POST",
        body: queryArg.updateCxrServerInstanceStateRequest,
      }),
    }),
    unlinkCxrServerUser: build.mutation<UnlinkCxrServerUserApiResponse, UnlinkCxrServerUserApiArg>({
      query: (queryArg) => ({
        url: `/v0/cxr_servers/${queryArg.cxrServerId}/unlink`,
        method: "DELETE",
      }),
    }),
    assignCxrServerIp: build.mutation<AssignCxrServerIpApiResponse, AssignCxrServerIpApiArg>({
      query: (queryArg) => ({
        url: `/v0/cxr_servers/${queryArg.cxrServerId}/assign_ip`,
        method: "POST",
      }),
    }),
    cxrListInstances: build.query<CxrListInstancesApiResponse, CxrListInstancesApiArg>({
      query: () => ({ url: `/exp-cxr/v-1/instances` }),
    }),
    cxrCreateInstance: build.mutation<CxrCreateInstanceApiResponse, CxrCreateInstanceApiArg>({
      query: (queryArg) => ({ url: `/exp-cxr/v-1/instances`, method: "POST", body: queryArg.body }),
    }),
    cxrListDevices: build.query<CxrListDevicesApiResponse, CxrListDevicesApiArg>({
      query: () => ({ url: `/exp-cxr/v-1/devices` }),
    }),
    cxrCreateDevice: build.mutation<CxrCreateDeviceApiResponse, CxrCreateDeviceApiArg>({
      query: (queryArg) => ({ url: `/exp-cxr/v-1/devices`, method: "POST", body: queryArg.body }),
    }),
    cxrGrabIp: build.mutation<CxrGrabIpApiResponse, CxrGrabIpApiArg>({
      query: (queryArg) => ({ url: `/exp-cxr/v-1/grab`, method: "POST", body: queryArg.body }),
    }),
    cxrReleaseIp: build.mutation<CxrReleaseIpApiResponse, CxrReleaseIpApiArg>({
      query: (queryArg) => ({ url: `/exp-cxr/v-1/release`, method: "POST", body: queryArg.body }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type WhoAmIApiResponse = /** status 200 Authenticated user data.
 */ GetUserResponse
export type WhoAmIApiArg = void
export type ListEventsApiResponse = /** status 200 List Events Response.
 */ ListEventsResponse
export type ListEventsApiArg = {
  /** List of included resources related to Events.
   */
  include?: ("organizations" | "specialties" | "instructors" | "locations" | "users")[]
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "start_time"
    | "-start_time"
    | "end_time"
    | "-end_time"
    | "title"
    | "-title"
    | "status"
    | "-status"
    | "privacy"
    | "-privacy"
  page?: {
    size?: number
    number?: number
  }
  filter?: {
    public_only?: boolean
    self?: boolean
    event_ids?: string[]
    user_ids?: string[]
    instructor_ids?: string[]
    organization_ids?: string[]
    end_before?: string
    end_after?: string
    start_before?: string
    start_after?: string
    specialty_ids?: string[]
  }
}
export type CreateEventApiResponse = /** status 200 Create Event Response.
 */ CreateEventResponse
export type CreateEventApiArg = {
  /** Create Event Request.
   */
  createEventRequest: CreateEventRequest
}
export type CountEventsApiResponse = /** status 200 Count Events Response.
 */ CountEventsResponse
export type CountEventsApiArg = void
export type GetEventApiResponse = /** status 200 Get Event Response.
 */ GetEventResponse
export type GetEventApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId3
  /** List of included resources related to Events.
   */
  include?: ("organizations" | "specialties" | "instructors" | "locations" | "users")[]
}
export type UpdateEventApiResponse = /** status 200 OK
 */ Success
export type UpdateEventApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId3
  /** Update Event Request.
   */
  updateEventRequest: UpdateEventRequest
}
export type DeleteEventApiResponse = /** status 200 OK
 */ Success
export type DeleteEventApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId3
}
export type UpdateEventRegistrationStateApiResponse = /** status 200 OK
 */ Success
export type UpdateEventRegistrationStateApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId3
  /** Update Event Registration State Request.
   */
  updateEventRegistrationStateRequest: UpdateEventRegistrationStateRequest
}
export type UpdateEventStatusApiResponse = /** status 200 OK
 */ Success
export type UpdateEventStatusApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId3
  /** Update Event Status Request.
   */
  updateEventStatusRequest: UpdateEventStatusRequest
}
export type UpdateEventSettingsApiResponse = /** status 200 OK
 */ Success
export type UpdateEventSettingsApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId3
  /** Update Event Satus Request.
   */
  updateEventSettingsRequest: UpdateEventSettingsRequest
}
export type StartEventStreamServerApiResponse = /** status 200 Media Server URL.
 */ StartStreamServerResponse
export type StartEventStreamServerApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId3
}
export type TerminateEventStreamServerApiResponse = /** status 200 OK
 */ Success
export type TerminateEventStreamServerApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId3
}
export type UpsertEventUserApiResponse = /** status 200 OK
 */ Success
export type UpsertEventUserApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId3
  /** Target User ID.
   */
  userId: FirebaseUserId
}
export type DeleteEventUserApiResponse = /** status 200 OK
 */ Success
export type DeleteEventUserApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId3
  /** Target User ID.
   */
  userId: FirebaseUserId
}
export type PostEventUserStatusActionsApiResponse = /** status 200 OK
 */ Success
export type PostEventUserStatusActionsApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId3
  /** Target User ID.
   */
  userId: FirebaseUserId
  action: EventUserStatusAction
}
export type PostEventUserRoleActionApiResponse = /** status 200 OK
 */ Success
export type PostEventUserRoleActionApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId3
  /** Target User ID.
   */
  userId: FirebaseUserId
  /** Event User Role Action Request.
   */
  body: {
    role: EventUserRole
  }
}
export type PostEventUserNpsActionApiResponse = /** status 200 OK
 */ Success
export type PostEventUserNpsActionApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId3
  /** Target User ID.
   */
  userId: FirebaseUserId
  /** Event User NPS Action Request.
   */
  body: {
    nps: {
      answer?: number
    }
  }
}
export type ListUsersApiResponse = /** status 200 List Users Response.
 */ ListUsersResponse
export type ListUsersApiArg = {
  /** List of included resources related to Users.
   */
  include?: ("organizations" | "specialties" | "industries" | "events" | "devices" | "streamers")[]
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "email"
    | "-email"
    | "name.first"
    | "-name.first"
    | "name.last"
    | "-name.last"
  page?: {
    size?: number
    number?: number
  }
  filter?: {
    email?: string
    self?: boolean
    user_ids?: string[]
    event_id?: string
    specialty_ids?: string[]
    organization_ids?: string[]
    user_role?: UserRole
  }
}
export type CreateUserApiResponse = /** status 200 Create User Response.
 */ CreateUserResponse
export type CreateUserApiArg = {
  /** Create User Request.
   */
  createUserRequest: CreateUserRequest
}
export type CountUsersApiResponse = /** status 200 Count Users Response.
 */ CountUsersResponse
export type CountUsersApiArg = void
export type InviteEmailApiResponse = /** status 200 OK
 */ Success
export type InviteEmailApiArg = {
  /** Invite User by Email Request.
   */
  emailInviteRequest: EmailInviteRequest
}
export type VerifyEmailApiResponse = /** status 200 OK
 */ Success
export type VerifyEmailApiArg = {
  /** Verify User by Email Request.
   */
  emailVerifyRequest: EmailVerifyRequest
}
export type GetUserApiResponse = /** status 200 Get User Response.
 */ GetUserResponse
export type GetUserApiArg = {
  /** Target User ID.
   */
  userId: FirebaseUserId
  /** List of included resources related to Users.
   */
  include?: ("organizations" | "specialties" | "industries" | "events" | "devices" | "streamers")[]
}
export type UpdateUserApiResponse = /** status 200 OK
 */ Success
export type UpdateUserApiArg = {
  /** Target User ID.
   */
  userId: FirebaseUserId
  /** Update User Request.

    NOTE: All users must be part of the root organization "Immertec" (organization_id "FC1CjZ1sauwAgXKrQfuv" in all envs).
     */
  updateUserRequest: UpdateUserRequest
}
export type DeleteUserApiResponse = /** status 200 OK
 */ Success
export type DeleteUserApiArg = {
  /** Target User ID.
   */
  userId: FirebaseUserId
}
export type UpsertUserOrganizationApiResponse = /** status 200 OK
 */ Success
export type UpsertUserOrganizationApiArg = {
  /** Target User ID.
   */
  userId: FirebaseUserId
  /** Target Organization ID.
   */
  organizationId: FirestoreDocumentId3
  /** Upsert User Organization Request.
   */
  upsertUserOrganizationRequest: UpsertUserOrganizationRequest
}
export type DeleteUserOrganizationApiResponse = /** status 200 OK
 */ Success
export type DeleteUserOrganizationApiArg = {
  /** Target User ID.
   */
  userId: FirebaseUserId
  /** Target Organization ID.
   */
  organizationId: FirestoreDocumentId3
}
export type SendEmailInviteGeneralApiResponse = /** status 200 OK
 */ Success
export type SendEmailInviteGeneralApiArg = {
  /** Target User ID.
   */
  userId: FirebaseUserId
}
export type SendEmailInviteEventApiResponse = /** status 200 OK
 */ Success
export type SendEmailInviteEventApiArg = {
  /** Target User ID.
   */
  userId: FirebaseUserId
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId3
  /** Send invitation Request.
   */
  sendInvitationRequest: SendInvitationRequest
}
export type UserRegistrationStatusApiResponse = /** status 200 User Registration Status By Email.
 */ GetUserRegistrationStatusResponse
export type UserRegistrationStatusApiArg = {
  /** User Email.
   */
  email: Email
}
export type VerifyUserApiResponse = /** status 200 OK
 */ Success
export type VerifyUserApiArg = {
  /** Target User ID.
   */
  userId: FirebaseUserId
}
export type SendEmailVerificationApiResponse = /** status 200 OK
 */ Success | /** status 204 No Content */ undefined
export type SendEmailVerificationApiArg = void
export type SendAccountRemovalRequestNotificationsApiResponse = /** status 200 OK
 */ Success
export type SendAccountRemovalRequestNotificationsApiArg = void
export type ListOrganizationsApiResponse = /** status 200 List Organizations Response.
 */ ListOrganizationsResponse
export type ListOrganizationsApiArg = {
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?: "created_at" | "-created_at" | "updated_at" | "-updated_at" | "name" | "-name" | "status" | "-status"
  page?: {
    size?: number
    number?: number
  }
  filter?: {
    self?: boolean
    organization_ids?: string[]
    name?: string
    slug?: string
  }
}
export type CreateOrganizationApiResponse = /** status 200 Create Organization Response.
 */ CreateOrganizationResponse
export type CreateOrganizationApiArg = {
  /** Create Organization Request.
   */
  createOrganizationRequest: CreateOrganizationRequest
}
export type CountOrganizationsApiResponse = /** status 200 Count Organizations Response.
 */ CountOrganizationsResponse
export type CountOrganizationsApiArg = void
export type GetOrganizationApiResponse = /** status 200 Get Organization Response.
 */ GetOrganizationResponse
export type GetOrganizationApiArg = {
  orgId: OrganizationId
}
export type UpdateOrganizationApiResponse = /** status 200 OK
 */ Success
export type UpdateOrganizationApiArg = {
  orgId: OrganizationId
  /** Update Organization Request.
   */
  updateOrganizationRequest: UpdateOrganizationRequest
}
export type DeleteOrganizationApiResponse = /** status 200 OK
 */ Success
export type DeleteOrganizationApiArg = {
  orgId: OrganizationId
}
export type ListInstructorsApiResponse = /** status 200 List Instructors Response.
 */ ListInstructorsResponse
export type ListInstructorsApiArg = {
  /** List of included resources related to Instructors.
   */
  include?: ("organizations" | "specialties" | "instructors" | "locations")[]
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "name.first"
    | "-name.first"
    | "name.last"
    | "-name.last"
    | "status"
    | "-status"
  page?: {
    size?: number
    number?: number
  }
  filter?: {
    public_only?: boolean
    organization_ids?: string[]
    location_ids?: string[]
    instructor_ids?: string[]
    specialty_ids?: string[]
    slug?: string
  }
}
export type CreateInstructorApiResponse = /** status 200 Create Instructor Response.
 */ CreateInstructorResponse
export type CreateInstructorApiArg = {
  /** Create Instructor Request.
   */
  createInstructorRequest: CreateInstructorRequest
}
export type CountInstructorsApiResponse = /** status 200 Count Instructors Response.
 */ CountInstructorsResponse
export type CountInstructorsApiArg = void
export type GetInstructorApiResponse = /** status 200 Get Instructor Response.
 */ GetInstructorResponse
export type GetInstructorApiArg = {
  /** Target Instructor ID.
   */
  instructorId: FirestoreDocumentId
  /** List of included resources related to Instructors.
   */
  include?: ("organizations" | "specialties" | "instructors" | "locations")[]
}
export type UpdateInstructorApiResponse = /** status 200 OK
 */ Success
export type UpdateInstructorApiArg = {
  /** Target Instructor ID.
   */
  instructorId: FirestoreDocumentId
  /** Update Instructor Request.

    NOTE: All instructors must be part of the root organization "Immertec" (organization_id "FC1CjZ1sauwAgXKrQfuv" in all envs).
     */
  updateInstructorRequest: UpdateInstructorRequest
}
export type DeleteInstructorApiResponse = /** status 200 OK
 */ Success
export type DeleteInstructorApiArg = {
  /** Target Instructor ID.
   */
  instructorId: FirestoreDocumentId
}
export type ListLocationsApiResponse = /** status 200 List Locations Response.
 */ ListLocationsResponse
export type ListLocationsApiArg = {
  /** List of included resources related to Locations.
   */
  include?: "instructors"[]
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?: "created_at" | "-created_at" | "updated_at" | "-updated_at" | "name" | "-name" | "status" | "-status"
  page?: {
    size?: number
    number?: number
  }
  filter?: {
    public_only?: boolean
    name?: string
    location_ids?: string[]
    instructor_ids?: string[]
    slug?: string
  }
}
export type CreateLocationApiResponse = /** status 200 Create Location Response.
 */ CreateLocationResponse
export type CreateLocationApiArg = {
  /** Create Location Request.
   */
  createLocationRequest: CreateLocationRequest
}
export type CountLocationsApiResponse = /** status 200 Count Locations Response.
 */ CountLocationsResponse
export type CountLocationsApiArg = void
export type GetLocationApiResponse = /** status 200 Get Location Response.
 */ GetLocationResponse
export type GetLocationApiArg = {
  /** Target Location ID.
   */
  locationId: FirestoreDocumentId2
  /** List of included resources related to Locations.
   */
  include?: "instructors"[]
}
export type UpdateLocationApiResponse = /** status 200 OK
 */ Success
export type UpdateLocationApiArg = {
  /** Target Location ID.
   */
  locationId: FirestoreDocumentId2
  /** Update Location Request.
   */
  updateLocationRequest: UpdateLocationRequest
}
export type DeleteLocationApiResponse = /** status 200 OK
 */ Success
export type DeleteLocationApiArg = {
  /** Target Location ID.
   */
  locationId: FirestoreDocumentId2
}
export type ListSpecialtiesApiResponse = /** status 200 List Specialties Response.
 */ ListSpecialtiesResponse
export type ListSpecialtiesApiArg = {
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?: "created_at" | "-created_at" | "updated_at" | "-updated_at" | "name" | "-name"
  page?: {
    size?: number
    number?: number
  }
  filter?: {
    specialty_ids?: string[]
    name?: string
    subspec_name?: string
  }
}
export type CreateSpecialtyApiResponse = /** status 200 Create Specialty Response.
 */ CreateSpecialtyResponse
export type CreateSpecialtyApiArg = {
  /** Create Specialty Request.
   */
  createSpecialtyRequest: CreateSpecialtyRequest
}
export type GetSpecialtyApiResponse = /** status 200 Get Specialty Response.
 */ GetSpecialtyResponse
export type GetSpecialtyApiArg = {
  /** Target Specialty ID.
   */
  specialtyId: Id
}
export type UpdateSpecialtyApiResponse = /** status 200 OK
 */ Success
export type UpdateSpecialtyApiArg = {
  /** Target Specialty ID.
   */
  specialtyId: Id
  /** Update Specialty Request.
   */
  updateSpecialtyRequest: UpdateSpecialtyRequest
}
export type DeleteSpecialtyApiResponse = /** status 200 OK
 */ Success
export type DeleteSpecialtyApiArg = {
  /** Target Specialty ID.
   */
  specialtyId: Id
}
export type GetSubSpecialtyApiResponse = /** status 200 Get Specialty Response.
 */ GetSpecialtyResponse
export type GetSubSpecialtyApiArg = {
  /** Target Specialty ID.
   */
  specialtyId: Id
  /** Target Sub Specialty ID.
   */
  subSpecialtyId: Id
}
export type UpdateSubSpecialtyApiResponse = /** status 200 OK
 */ Success
export type UpdateSubSpecialtyApiArg = {
  /** Target Specialty ID.
   */
  specialtyId: Id
  /** Target Sub Specialty ID.
   */
  subSpecialtyId: Id
  /** Update Sub Specialty Request.
   */
  updateSpecialtyRequest: UpdateSpecialtyRequest
}
export type DeleteSubSpecialtyApiResponse = /** status 200 OK
 */ Success
export type DeleteSubSpecialtyApiArg = {
  /** Target Specialty ID.
   */
  specialtyId: Id
  /** Target Sub Specialty ID.
   */
  subSpecialtyId: Id
}
export type ListIndustriesApiResponse = /** status 200 List Industries Response.
 */ ListIndustriesResponse
export type ListIndustriesApiArg = {
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?: "created_at" | "-created_at" | "updated_at" | "-updated_at" | "name" | "-name"
  page?: {
    size?: number
    number?: number
  }
  filter?: {
    industry_ids?: string[]
    name?: string
    job_type_name?: string
  }
}
export type CreateIndustryApiResponse = /** status 200 Create Industry Response.
 */ CreateIndustryResponse
export type CreateIndustryApiArg = {
  /** Create Industry Request.
   */
  createIndustryRequest: CreateIndustryRequest
}
export type GetIndustryApiResponse = /** status 200 Get Industry Response.
 */ GetIndustryResponse
export type GetIndustryApiArg = {
  /** Target Industry ID.
   */
  industryId: Id
}
export type UpdateIndustryApiResponse = /** status 200 OK
 */ Success
export type UpdateIndustryApiArg = {
  /** Target Industry ID.
   */
  industryId: Id
  /** Update Industry Request.
   */
  updateIndustryRequest: UpdateIndustryRequest
}
export type DeleteIndustryApiResponse = /** status 200 OK
 */ Success
export type DeleteIndustryApiArg = {
  /** Target Industry ID.
   */
  industryId: Id
}
export type GetIndustryJobTypeApiResponse = /** status 200 Get Industry Response.
 */ GetIndustryResponse
export type GetIndustryJobTypeApiArg = {
  /** Target Industry ID.
   */
  industryId: Id
  /** Target Job Type ID.
   */
  jobTypeId: Id
}
export type UpdateIndustryJobTypeApiResponse = /** status 200 OK
 */ Success
export type UpdateIndustryJobTypeApiArg = {
  /** Target Industry ID.
   */
  industryId: Id
  /** Target Job Type ID.
   */
  jobTypeId: Id
  /** Update Industry Job Type Request.
   */
  updateIndustryRequest: UpdateIndustryRequest
}
export type DeleteIndustryJobTypeApiResponse = /** status 200 OK
 */ Success
export type DeleteIndustryJobTypeApiArg = {
  /** Target Industry ID.
   */
  industryId: Id
  /** Target Job Type ID.
   */
  jobTypeId: Id
}
export type ListDevicesApiResponse = /** status 200 List Devices Response.
 */ ListDevicesResponse
export type ListDevicesApiArg = {
  /** List of included resources related to Devices.
   */
  include?: "users"[]
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "paired_at"
    | "-paired_at"
    | "serial_number"
    | "-serial_number"
  page?: {
    size?: number
    number?: number
  }
  filter?: {
    user_ids?: string[]
    device_ids?: string[]
    serial_number?: string
  }
}
export type CreateDeviceApiResponse = /** status 200 Create Device Response.
 */ CreateDeviceResponse
export type CreateDeviceApiArg = {
  /** Create Device Request.
   */
  createDeviceRequest: CreateDeviceRequest
}
export type PairDeviceApiResponse = /** status 200 Pair Device Success.
 */ undefined
export type PairDeviceApiArg = {
  /** Pair Device Request.
   */
  pairDeviceRequest: PairDeviceRequest
}
export type GetDeviceApiResponse = /** status 200 Get Device Response.
 */ GetDeviceResponse
export type GetDeviceApiArg = {
  /** Target Device ID.
   */
  deviceId: DeviceId
  /** List of included resources related to Devices.
   */
  include?: "users"[]
}
export type UpdateDeviceApiResponse = /** status 200 OK
 */ Success
export type UpdateDeviceApiArg = {
  /** Target Device ID.
   */
  deviceId: DeviceId
  /** Update Device Request.
   */
  updateDeviceRequest: UpdateDeviceRequest
}
export type DeleteDeviceApiResponse = /** status 200 OK
 */ Success
export type DeleteDeviceApiArg = {
  /** Target Device ID.
   */
  deviceId: DeviceId
}
export type ListFcmTokensApiResponse = /** status 200 List FCM Tokens Response.
 */ ListFcmTokensResponse
export type ListFcmTokensApiArg = {
  /** List of included resources related to FCM Tokens.
   */
  include?: ("users" | "devices")[]
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?: "created_at" | "-created_at" | "updated_at" | "-updated_at" | "paired_at" | "-paired_at"
  page?: {
    size?: number
    number?: number
  }
  filter?: {
    user_ids?: string[]
    device_ids?: string[]
    fcm_token_ids?: string[]
  }
}
export type CreateFcmTokenApiResponse = /** status 200 Create FCM Token Response.
 */ CreateFcmTokenResponse
export type CreateFcmTokenApiArg = {
  /** Create FCM Token Request.
   */
  createFcmTokenRequest: CreateFcmTokenRequest
}
export type GetFcmTokenApiResponse = /** status 200 Get FCM Token Response.
 */ GetFcmTokenResponse
export type GetFcmTokenApiArg = {
  /** Target FCM Token ID.
   */
  fcmTokenId: FcmTokenId
  /** List of included resources related to FCM Tokens.
   */
  include?: ("users" | "devices")[]
}
export type UpdateFcmTokenApiResponse = /** status 200 OK
 */ Success
export type UpdateFcmTokenApiArg = {
  /** Target FCM Token ID.
   */
  fcmTokenId: FcmTokenId
  /** Update FCM Token Request.
   */
  updateFcmTokenRequest: UpdateFcmTokenRequest
}
export type DeleteFcmTokenApiResponse = /** status 200 OK
 */ Success
export type DeleteFcmTokenApiArg = {
  /** Target FCM Token ID.
   */
  fcmTokenId: FcmTokenId
}
export type GetHeadsetRequestsReportsApiResponse = /** status 200 Report Response.
 */ string
export type GetHeadsetRequestsReportsApiArg = {
  filter?: {
    event_ids?: string[]
  }
}
export type GetConnectionsReportApiResponse = /** status 200 Get Event Connections Report Response.
 */ GetEventConnectionsReportResponse
export type GetConnectionsReportApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId3
}
export type CreateConnectionReportApiResponse = /** status 200 Create Report Response.
 */ CreateReportResponse
export type CreateConnectionReportApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId3
  /** Create Event Connection Report Request.
   */
  createEventConnectionsReportRequest: CreateEventConnectionsReportRequest
}
export type GetCo2EmissionsReportApiResponse = /** status 200 Get Event CO2 Emissions Report Response.
 */ GetEventCo2EmissionsReportResponse
export type GetCo2EmissionsReportApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId3
}
export type CreateCo2EmissionReportApiResponse = /** status 200 OK
 */ Success
export type CreateCo2EmissionReportApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId3
}
export type ListImagesApiResponse = /** status 200 List Images Response.
 */ ListImagesResponse
export type ListImagesApiArg = {
  /** List of included resources related to Images.
   */
  include?: string[]
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?: "created_at" | "-created_at" | "updated_at" | "-updated_at" | "name" | "-name"
  page?: {
    size?: number
    number?: number
  }
  filter?: {
    image_ids?: string[]
    name?: string
    tags?: string[]
  }
}
export type CreateImageApiResponse = /** status 200 Create Image Response.
 */ CreateImageResponse
export type CreateImageApiArg = {
  /** Create Image Request.
   */
  createImageRequest: CreateImageRequest
}
export type GetImageApiResponse = /** status 200 Get Image Response.
 */ GetImageResponse
export type GetImageApiArg = {
  /** Target Image ID.
   */
  imageId: Uuid
  /** List of included resources related to Images.
   */
  include?: string[]
}
export type UpdateImageApiResponse = /** status 200 OK
 */ Success
export type UpdateImageApiArg = {
  /** Target Image ID.
   */
  imageId: Uuid
  /** Update Image Request.
   */
  updateImageRequest: UpdateImageRequest
}
export type DeleteImageApiResponse = /** status 200 OK
 */ Success
export type DeleteImageApiArg = {
  /** Target Image ID.
   */
  imageId: Uuid
}
export type ListDemosApiResponse = /** status 200 List Demos Response.
 */ ListDemosResponse
export type ListDemosApiArg = {
  /** List of included resources related to Demos.
   */
  include?: ("organizations" | "instructors")[]
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?: "created_at" | "-created_at" | "updated_at" | "-updated_at" | "title" | "-title"
  page?: {
    size?: number
    number?: number
  }
  filter?: {
    organization_ids?: string[]
    instructor_ids?: string[]
    demo_ids?: string[]
  }
}
export type CreateDemoApiResponse = /** status 200 Create Demo Response.
 */ CreateDemoResponse
export type CreateDemoApiArg = {
  /** Create Demo Request.
   */
  createDemoRequest: CreateDemoRequest
}
export type GetDemoApiResponse = /** status 200 Get Demo Response.
 */ GetDemoResponse
export type GetDemoApiArg = {
  /** Target Demo ID.
   */
  demoId: Uuid
  /** List of included resources related to Demos.
   */
  include?: ("organizations" | "instructors")[]
}
export type UpdateDemoApiResponse = /** status 200 OK
 */ Success
export type UpdateDemoApiArg = {
  /** Target Demo ID.
   */
  demoId: Uuid
  /** Update Demo Request.
   */
  updateDemoRequest: UpdateDemoRequest
}
export type DeleteDemoApiResponse = /** status 200 OK
 */ Success
export type DeleteDemoApiArg = {
  /** Target Demo ID.
   */
  demoId: Uuid
}
export type SpeedTestApiResponse = /** status 200 Success.
 */ string
export type SpeedTestApiArg = {
  /** Size of the payload to return.
   */
  size?: number
  /** Status Code to return.
   */
  code?: number
}
export type ListHeadsetRequestsApiResponse = /** status 200 List Headset Requests Response.
 */ ListHeadsetRequestsResponse
export type ListHeadsetRequestsApiArg = {
  page?: {
    size?: number
    number?: number
  }
  filter?: {
    headset_request_ids?: string[]
    event_ids?: string[]
    user_ids?: string[]
    before?: string
    after?: string
  }
}
export type CreateHeadsetRequestApiResponse = /** status 200 Create Headset Request Response.
 */ CreateHeadsetRequestResponse
export type CreateHeadsetRequestApiArg = {
  /** Create Headset Request Request.
   */
  createHeadsetRequestRequest: CreateHeadsetRequestRequest
}
export type GetHeadsetRequestApiResponse = /** status 200 Get Headset Request Response.
 */ GetHeadsetRequestResponse
export type GetHeadsetRequestApiArg = {
  /** Target Headset Request ID.
   */
  headsetRequestId: FirestoreDocumentId5
}
export type UpdateHeadsetRequestApiResponse = /** status 200 OK
 */ Success
export type UpdateHeadsetRequestApiArg = {
  /** Target Headset Request ID.
   */
  headsetRequestId: FirestoreDocumentId5
  /** Update Headset Request Request.
   */
  updateHeadsetRequestRequest: UpdateHeadsetRequestRequest
}
export type DeleteHeadsetRequestApiResponse = /** status 200 OK
 */ Success
export type DeleteHeadsetRequestApiArg = {
  /** Target Headset Request ID.
   */
  headsetRequestId: FirestoreDocumentId5
}
export type LinkHeadsetRequestEventsApiResponse = /** status 200 OK
 */ Success
export type LinkHeadsetRequestEventsApiArg = {
  /** Target Headset Request ID.
   */
  headsetRequestId: FirestoreDocumentId5
  /** Update Image Request.
   */
  linkHeadsetRequestEventsRequest: LinkHeadsetRequestEventsRequest
}
export type ListHeadsetRequestHistoryApiResponse = /** status 200 List Headset Request History Response.
 */ ListHeadsetRequestHistoryResponse
export type ListHeadsetRequestHistoryApiArg = {
  /** Target Headset Request ID.
   */
  headsetRequestId: FirestoreDocumentId5
}
export type CreateJoinCodeApiResponse = /** status 200 Create Join Code Response.
 */ CreateJoinCodeResponse
export type CreateJoinCodeApiArg = {
  /** Create Join Code Request.
   */
  createJoinCodeRequest: CreateJoinCodeRequest
}
export type ExchangeJoinCodeApiResponse = /** status 200 Create Join Code Response.
 */ ExchangeJoinCodeResponse
export type ExchangeJoinCodeApiArg = {
  /** Exchange Join Code Request.
   */
  exchangeJoinCodeRequest: ExchangeJoinCodeRequest
}
export type ListStreamServersApiResponse = /** status 200 List StreamServers Response.
 */ ListStreamServersResponse
export type ListStreamServersApiArg = {
  page?: {
    size?: number
    number?: number
  }
  filter?: {
    stream_server_ids?: string[]
    event_ids?: string[]
  }
}
export type UpsertStreamServerApiResponse = /** status 200 Upsert StreamServer Response.
 */ UpsertStreamServerResponse
export type UpsertStreamServerApiArg = {
  /** Upsert StreamServer Request.
   */
  upsertStreamServerRequest: UpsertStreamServerRequest
}
export type GetStreamServerApiResponse = /** status 200 Get StreamServer Response.
 */ GetStreamServerResponse
export type GetStreamServerApiArg = {
  /** Target StreamServer ID.
   */
  streamServerId: FirestoreDocumentId5
}
export type DeleteStreamServerApiResponse = /** status 200 OK
 */ Success
export type DeleteStreamServerApiArg = {
  /** Target StreamServer ID.
   */
  streamServerId: FirestoreDocumentId5
}
export type ListCxrServersApiResponse = /** status 200 List CXRServers Response.
 */ ListCxrServersResponse
export type ListCxrServersApiArg = {
  page?: {
    size?: number
    number?: number
  }
  filter?: {
    cxr_server_ids?: string[]
    event_ids?: string[]
  }
}
export type CreateCxrServerApiResponse = /** status 200 Create CXRServer Response.
 */ CreateCxrServerResponse
export type CreateCxrServerApiArg = {
  /** Create CXRServer Request.
   */
  createCxrServerRequest: CreateCxrServerRequest
}
export type RefreshCxrServersApiResponse = /** status 200 OK
 */ Success
export type RefreshCxrServersApiArg = void
export type GrabCxrServerApiResponse = /** status 200 Get CXRServer Instance Types Response.
 */ GrabCxrServerResponse
export type GrabCxrServerApiArg = void
export type GetCxrServerInstanceTypesApiResponse = /** status 200 Get CXRServer Instance Types Response.
 */ ListCxrInstanceTypesResponse
export type GetCxrServerInstanceTypesApiArg = void
export type GetCxrServerAmIsApiResponse = /** status 200 Get CXRServer AMIs Response.
 */ ListCxramIsResponse
export type GetCxrServerAmIsApiArg = void
export type GetCxrServerApiResponse = /** status 200 Get CXRServer Response.
 */ GetCxrServerResponse
export type GetCxrServerApiArg = {
  /** Target CXRServer ID.
   */
  cxrServerId: Uuid
}
export type UpdateCxrServerApiResponse = /** status 200 OK
 */ Success
export type UpdateCxrServerApiArg = {
  /** Target CXRServer ID.
   */
  cxrServerId: Uuid
  /** Update CXRServer Request.
   */
  updateCxrServerRequest: UpdateCxrServerRequest
}
export type DeleteCxrServerApiResponse = /** status 200 OK
 */ Success
export type DeleteCxrServerApiArg = {
  /** Target CXRServer ID.
   */
  cxrServerId: Uuid
}
export type StartCxrServerApiResponse = /** status 200 OK
 */ Success
export type StartCxrServerApiArg = {
  /** Target CXRServer ID.
   */
  cxrServerId: Uuid
}
export type StopCxrServerApiResponse = /** status 200 OK
 */ Success
export type StopCxrServerApiArg = {
  /** Target CXRServer ID.
   */
  cxrServerId: Uuid
}
export type WaitCxrServerApiResponse = /** status 200 OK
 */ Success
export type WaitCxrServerApiArg = {
  /** Target CXRServer ID.
   */
  cxrServerId: Uuid
  /** Wait CXRServer Request.
   */
  waitCxrServerRequest: WaitCxrServerRequest
}
export type UpdateCxrServerInstanceStateApiResponse = /** status 200 OK
 */ Success
export type UpdateCxrServerInstanceStateApiArg = {
  /** Target CXRServer ID.
   */
  cxrServerId: Uuid
  /** Update CXRServer Instance State Request.
   */
  updateCxrServerInstanceStateRequest: UpdateCxrServerInstanceStateRequest
}
export type UnlinkCxrServerUserApiResponse = /** status 200 OK
 */ Success
export type UnlinkCxrServerUserApiArg = {
  /** Target CXRServer ID.
   */
  cxrServerId: Uuid
}
export type AssignCxrServerIpApiResponse = /** status 200 Assign CXR Server IP Response.
 */ AssignCxrServerIpResponse
export type AssignCxrServerIpApiArg = {
  /** Target CXRServer ID.
   */
  cxrServerId: Uuid
}
export type CxrListInstancesApiResponse = /** status 200 Success.
 */ {
  success: boolean
  data: {
    id: string
    ipv4: string
    device_id?: string
    created_at: DateTime
    updated_at: DateTime
    deleted_at?: DateTime
  }
}
export type CxrListInstancesApiArg = void
export type CxrCreateInstanceApiResponse = /** status 200 OK
 */ Success
export type CxrCreateInstanceApiArg = {
  body: {
    ipv4: string
  }
}
export type CxrListDevicesApiResponse = /** status 200 Success.
 */ {
  success: boolean
  data: {
    id: string
    instance_id?: string
    created_at: DateTime
    updated_at: DateTime
    deleted_at?: DateTime
  }
}
export type CxrListDevicesApiArg = void
export type CxrCreateDeviceApiResponse = /** status 200 OK
 */ Success
export type CxrCreateDeviceApiArg = {
  body: {
    device_id: string
  }
}
export type CxrGrabIpApiResponse = /** status 200 Success.
 */ {
  success: boolean
  data: string
}
export type CxrGrabIpApiArg = {
  body: {
    device_id: string
  }
}
export type CxrReleaseIpApiResponse = /** status 200 OK
 */ Success
export type CxrReleaseIpApiArg = {
  body: {
    device_id: string
    ipv4: string
  }
}
export type Email = string
export type UserName = {
  prefix?: string
  first: string
  middle?: string
  last: string
  suffix?: string
  alias?: string
}
export type Phone = {
  mobile?: string
}
export type UserInfo = {
  email: Email
  name: UserName
  phone?: Phone
}
export type FirebaseUserId = string
export type DateTime = string
export type Metadata = {
  created_at: DateTime
  updated_at: DateTime
  deleted_at?: DateTime
  created_by: FirebaseUserId
  updated_by: FirebaseUserId
  deleted_by?: FirebaseUserId
}
export type OrganizationRole = "UNKNOWN" | "ADMIN" | "MANAGER" | "MEMBER" | "TRAINEE" | "JOIN_CODE" | "VR_JOIN_CODE"
export type UserOrganization = {
  org_role?: OrganizationRole
}
export type UserOrganizations = {
  [key: string]: UserOrganization
}
export type SpecialtyCertificationStatus = "UNKNOWN" | "PENDING" | "DECLARED" | "VERIFIED" | "REJECTED"
export type Id = string
export type UserSpecialty = {
  certification?: {
    status?: SpecialtyCertificationStatus
  }
  child_ids?: Id[]
}
export type UserSpecialties = {
  [key: string]: UserSpecialty
}
export type EventUserRole = "UNKNOWN" | "TRAINEE" | "HOST" | "CUSTOMER_SUPPORT" | "IMMERTEC_EMPLOYEE"
export type EventUserRegistrationStatus =
  | "UNKNOWN"
  | "PENDING_USER"
  | "PENDING_CLIENT"
  | "CANCELLED_USER"
  | "CANCELLED_CLIENT"
  | "REJECTED_USER"
  | "REJECTED_CLIENT"
  | "ACCEPTED"
  | "CANCELLED_ACCEPTED_USER"
  | "CANCELLED_ACCEPTED_CLIENT"
  | "CANCELLED_EVENT"
export type HeadsetRequestStatus =
  | "UNKNOWN"
  | "REQUEST_INITIATED"
  | "REQUEST_PROCESSED"
  | "REQUEST_ALLOCATED"
  | "REQUEST_REJECTED"
  | "REQUEST_WAIVED"
  | "SHIPPED"
  | "DELIVERY_CONFIRMED"
  | "RETURN_PENDING"
  | "RETURN_TRANSIT"
  | "RETURN_WAIVED"
  | "RETURN_CONFIRMED"
  | "RETURN_DELINQUENT"
  | "MISSING_INFORMATION"
  | "SHIPPING_ERROR"
export type HeadsetRequestDeviceType = "UNKNOWN" | "OCULUS_QUEST_1" | "OCULUS_QUEST_2" | "OCULUS_QUEST_PRO"
export type Address = {
  address_line_1: string
  address_line_2?: string
  city: string
  zip: string
  state: string
  country?: string
  company?: string
  extra?: string
}
export type TrackingInfo = {
  return_number?: string
  return_date?: DateTime
  shipping_number?: string
  shipping_date?: DateTime
}
export type HeadsetRequestLegacy = {
  status?: HeadsetRequestStatus
  type?: HeadsetRequestDeviceType
  date?: DateTime
  quantity?: number
  name?: UserName
  address?: Address
  tracking_info?: TrackingInfo
  late_fee_ack?: string
}
export type EventUserNps = {
  answer: number
  answered_at: DateTime
}
export type EventUser = {
  role: EventUserRole
  registration_status: EventUserRegistrationStatus
  headset_request?: HeadsetRequestLegacy
  nps?: EventUserNps
}
export type UserEvents = {
  [key: string]: EventUser
}
export type Url = string
export type UserRole =
  | "UNKNOWN"
  | "USER"
  | "FIELD_SUPPORT_ENGINEER"
  | "MARKETING"
  | "CUSTOMER_SUCCESS"
  | "EVENT_SUCCESS"
  | "STREAMER"
  | "GLOBAL_ADMIN"
  | "JOIN_CODE"
  | "VR_JOIN_CODE"
  | "IMMERTEC_EMPLOYEE"
  | "TECHDOGS_EMPLOYEE"
  | "CLOUDXR_ADMIN"
export type UserClassification =
  | "UNKNOWN"
  | "MDC_PARTICIPANT"
  | "PARTICIPANT"
  | "INSTRUCTOR_KOL"
  | "IMMERTEC_EMPLOYEE"
  | "BUSINESS_DEVELOPMENT"
export type UserPreferences = {
  notifications?: {
    email?: boolean
    sms?: boolean
    push?: boolean
  }
}
export type UserData = {
  user_id: FirebaseUserId
  metadata?: Metadata
  user_organizations: UserOrganizations
  user_specialties?: UserSpecialties
  user_events?: UserEvents
  address?: Address
  photo?: Url
  user_role: UserRole
  classification?: UserClassification
  industry_id?: Id
  job_type_id?: Id
  preferences?: UserPreferences
  assistant?: UserInfo
  can_use_streamer?: boolean
  accepted_terms?: boolean
  email_verified?: boolean
  last_login?: DateTime
  last_auth_refresh?: DateTime
  synced?: boolean
}
export type User = UserInfo & UserData
export type OrganizationId = string
export type InternalId = string
export type DigitalFootprint = {
  facebook?: Url
  instagram?: Url
  linkedin?: Url
  twitter?: Url
  website?: Url
}
export type OrganizationStatus = "ACTIVE" | "INACTIVE"
export type Coordinates = {
  latitude?: number
  longitude?: number
}
export type Organization = {
  organization_id: OrganizationId
  internal_id?: InternalId
  slug?: string
  metadata?: Metadata
  name: string
  description: string
  digital_footprint?: DigitalFootprint
  status: OrganizationStatus
  photo?: Url
  owner: UserInfo
  address: Address
  coordinates?: Coordinates
  cs_rep_user_id?: FirebaseUserId
}
export type BaseTaxonomy = {
  id?: Id
  metadata?: Metadata
  parent_id?: Id
}
export type BaseSpecialty = BaseTaxonomy & {
  name: string
  photo?: Url
}
export type Specialty = BaseSpecialty & {
  children?: {
    [key: string]: BaseSpecialty
  }
}
export type BaseIndustry = BaseTaxonomy & {
  name: string
  photo?: Url
}
export type Industry = BaseIndustry & {
  children?: {
    [key: string]: BaseIndustry
  }
}
export type FirestoreDocumentId = string
export type FirestoreDocumentId2 = string
export type InstructorStatus = "ACTIVE" | "COMING_SOON" | "INACTIVE"
export type Display = {
  marketing?: boolean
}
export type Instructor = {
  instructor_id: FirestoreDocumentId
  internal_id?: InternalId
  slug?: string
  metadata?: Metadata
  location_ids?: FirestoreDocumentId2[]
  organization_ids: FirestoreDocumentId2[]
  instructor_specialties: UserSpecialties
  email: Email
  name: UserName
  phone?: Phone
  status?: InstructorStatus
  photo?: Url
  quote?: string
  practice?: string
  description: string
  digital_footprint?: DigitalFootprint
  display?: Display
  hs_instructor_id?: string
}
export type DeviceId = string
export type PairingCode = string
export type Uuid = string
export type Device = {
  device_id: DeviceId
  metadata?: Metadata
  name?: string
  user_id: FirebaseUserId
  pairing_code: PairingCode
  serial_number: Uuid
  paired_at: DateTime
}
export type FirestoreDocumentId3 = string
export type EventUsers = {
  [key: string]: EventUser
}
export type GuestInstructor = {
  email?: Email
  name: UserName
}
export type StreamSettings = {
  media_server_url?: Url & any
  user_list_disabled?: boolean
}
export type EventPrivacy = "PUBLIC" | "PRIVATE"
export type EventStatus =
  | "UNKNOWN"
  | "REGISTRATION_OPEN"
  | "REGISTRATION_CLOSED"
  | "PATIENT_ARRIVED"
  | "PATIENT_PREPARED"
  | "TIMED_OUT"
  | "LIVE"
  | "STOPPED"
  | "CANCELLED"
export type ServerStatus = "NOT_RUNNING" | "RUNNING"
export type EventFunding = "UNKNOWN" | "PAID" | "UNPAID"
export type EventRegistrationState = "OPEN" | "CLOSED"
export type EventType =
  | "UNKNOWN"
  | "DEVELOPMENT"
  | "OPS_TESTING"
  | "LIVE_PATIENT_P2P"
  | "LIVE_PATIENT_COMPANY_TRAINING"
  | "LIVE_PATIENT_DEMO"
  | "NON_LIVE_PATIENT_OBSERVATION_TRAINING"
  | "NON_LIVE_PATIENT_DEMO"
  | "PROMOTIONAL"
export type Event = {
  event_id: FirestoreDocumentId3
  internal_id?: InternalId
  slug?: string
  metadata?: Metadata
  organization_id: OrganizationId
  location_id: FirestoreDocumentId2
  instructor_ids?: FirestoreDocumentId[]
  event_users?: EventUsers
  event_users_count?: number
  guest_instructors?: GuestInstructor[]
  specialty_id: Id
  sub_specialty_id?: Id
  cs_rep_user_id?: FirebaseUserId
  stream_settings?: StreamSettings
  title: string
  description: string
  start_time: DateTime & any
  end_time: DateTime & any
  duration?: number
  privacy: EventPrivacy
  status: EventStatus
  server_status?: ServerStatus
  funding?: EventFunding
  registration_state?: EventRegistrationState
  type?: EventType
  flexible_time: boolean
  seat_count?: number
  photo?: Url
  is_test?: boolean
}
export type LocationCategory =
  | "UNKNOWN"
  | "HOSPITAL"
  | "ASC"
  | "BIO_SKILLS_LAB"
  | "OFFICE_LAB"
  | "COMPANY_HQ_LAB"
  | "PRACTICE"
export type LocationStatus = "ACTIVE" | "COMING_SOON" | "UNAVAILABLE"
export type Location = {
  location_id: FirestoreDocumentId2
  internal_id?: InternalId
  slug?: string
  metadata?: Metadata
  instructor_ids?: FirestoreDocumentId[]
  address: Address
  category: LocationCategory
  coordinates?: Coordinates
  digital_footprint?: DigitalFootprint
  name: string
  description: string
  photo?: Url
  status: LocationStatus
  display?: Display
}
export type IncludedUser = {
  organizations?: {
    [key: string]: Organization
  }
  specialties?: {
    [key: string]: Specialty
  }
  industries?: {
    [key: string]: Industry
  }
  instructors?: {
    [key: string]: Instructor
  }
  devices?: {
    [key: string]: Device
  }
  events?: {
    [key: string]: Event
  }
  locations?: {
    [key: string]: Location
  }
}
export type GetUserResponse = {
  data?: User
  included?: IncludedUser
}
export type Error = {
  success: false
  message?: string
}
export type MetaResponse = {
  total_count?: number
  total_pages?: number
}
export type IncludedEvent = {
  organizations?: {
    [key: string]: Organization
  }
  specialties?: {
    [key: string]: Specialty
  }
  instructors?: {
    [key: string]: Instructor
  }
  locations?: {
    [key: string]: Location
  }
  users?: {
    [key: string]: User
  }
  industries?: {
    [key: string]: Industry
  }
}
export type ListEventsResponse = {
  meta?: MetaResponse
  data?: Event[]
  included?: IncludedEvent
}
export type Success = {
  success: boolean
  message?: string
}
export type CreateEventResponse = Success & {
  data: {
    event_id: FirestoreDocumentId3
  }
}
export type UpdateEventSettingsRequest = {
  stream_settings?: StreamSettings
}
export type BaseEventRequest = UpdateEventSettingsRequest & {
  location_id: FirestoreDocumentId2
  instructor_ids?: FirestoreDocumentId[]
  specialty_id: Id
  sub_specialty_id?: Id
  cs_rep_user_id?: FirebaseUserId
  start_time: DateTime
  end_time: DateTime
  title: string
  description?: string
  seat_count?: number
  privacy: EventPrivacy
  funding?: EventFunding
  type?: EventType
  registration_state?: EventRegistrationState
  photo?: Url
  guest_instructors?: GuestInstructor[]
  flexible_time?: boolean
  skip_notifications?: boolean
}
export type CreateEventRequest = BaseEventRequest & {
  organization_id: OrganizationId
  status?: "REGISTRATION_OPEN" | "REGISTRATION_CLOSED"
}
export type CountEventsResponse = {
  data?: {
    count: number
  }
}
export type GetEventResponse = {
  data?: Event
  included?: IncludedEvent
}
export type UpdateEventStatusRequest = {
  status: EventStatus
  skip_notifications?: boolean
}
export type UpdateEventRequest = BaseEventRequest & UpdateEventStatusRequest
export type UpdateEventRegistrationStateRequest = {
  registration_state: EventRegistrationState
}
export type StartStreamServerResponse = Success & {
  data: {
    url: string
  }
}
export type EventUserStatusAction = "INITIATE" | "CANCEL" | "ACCEPT"
export type ListUsersResponse = {
  meta?: MetaResponse
  data?: User[]
  included?: IncludedUser
}
export type CreateUserResponse = Success & {
  data: {
    user_id: FirebaseUserId
  }
}
export type BaseUserRequest = {
  user_specialties?: UserSpecialties
  user_role?: UserRole
  classification?: UserClassification
  name: UserName
  address?: Address
  photo?: Url
  industry_id?: Id
  job_type_id?: Id
  phone?: Phone
  preferences?: UserPreferences
  assistant?: UserInfo
  accepted_terms?: boolean
}
export type CreateUserRequest = BaseUserRequest & {
  email: Email
  user_organizations?: UserOrganizations
}
export type CountUsersResponse = {
  data?: {
    count: number
  }
}
export type EmailInviteRequest = {
  email: Email
}
export type EmailVerifyRequest = {
  email: Email
}
export type UpdateUserRequest = BaseUserRequest
export type UpsertUserOrganizationRequest = {
  org_role?: OrganizationRole
}
export type SendInvitationRequest = {
  with_vr?: any
}
export type RegistrationStatus = "UNKNOWN" | "UNVERIFIED" | "INVITED" | "INCOMPLETE" | "COMPLETE"
export type UserRegistrationStatus = {
  registration_status: RegistrationStatus
}
export type GetUserRegistrationStatusResponse = {
  data?: UserRegistrationStatus
}
export type ListOrganizationsResponse = {
  meta?: MetaResponse
  data?: Organization[]
}
export type CreateOrganizationResponse = Success & {
  data: {
    organization_id: OrganizationId
  }
}
export type CreateOrganizationRequest = {
  name: string
  description: string
  digital_footprint?: DigitalFootprint
  status: OrganizationStatus
  owner: UserInfo
  address: Address
  photo?: Url
  coordinates?: Coordinates
  cs_rep_user_id?: FirebaseUserId
}
export type CountOrganizationsResponse = {
  data?: {
    count: number
  }
}
export type GetOrganizationResponse = {
  data?: Organization
}
export type UpdateOrganizationRequest = CreateOrganizationRequest
export type IncludedInstructor = {
  organizations?: {
    [key: string]: Organization
  }
  specialties?: {
    [key: string]: Specialty
  }
  locations?: {
    [key: string]: Location
  }
}
export type ListInstructorsResponse = {
  meta?: MetaResponse
  data?: Instructor[]
  included?: IncludedInstructor
}
export type CreateInstructorResponse = Success & {
  data: {
    instructor_id: FirestoreDocumentId
  }
}
export type CreateInstructorRequest = {
  organization_ids: OrganizationId[]
  location_ids?: FirestoreDocumentId2[]
  instructor_specialties: UserSpecialties & any
  email: Email & any
  name: UserName
  photo?: Url & any
  phone?: Phone
  status?: InstructorStatus
  slug?: string
  quote?: string
  practice?: string
  description: string
  digital_footprint?: DigitalFootprint
  display?: Display
  hs_instructor_id?: string
}
export type CountInstructorsResponse = {
  data?: {
    count: number
  }
}
export type GetInstructorResponse = {
  data?: Instructor
  included?: IncludedInstructor
}
export type UpdateInstructorRequest = CreateInstructorRequest
export type IncludedLocation = {
  instructors?: {
    [key: string]: Instructor
  }
}
export type ListLocationsResponse = {
  meta?: MetaResponse
  data?: Location[]
  included?: IncludedLocation
}
export type CreateLocationResponse = Success & {
  data: {
    location_id: FirestoreDocumentId2
  }
}
export type CreateLocationRequest = {
  instructor_ids?: FirestoreDocumentId[]
  address: Address
  category: LocationCategory
  name: string
  description: string
  status: LocationStatus
  coordinates?: Coordinates
  photo?: Url
  digital_footprint?: DigitalFootprint
  display?: Display
  slug?: string
}
export type CountLocationsResponse = {
  data?: {
    count: number
  }
}
export type GetLocationResponse = {
  data?: Location
  included?: IncludedLocation
}
export type UpdateLocationRequest = CreateLocationRequest
export type ListSpecialtiesResponse = {
  meta?: MetaResponse
  data?: Specialty[]
}
export type CreateSpecialtyResponse = Success & {
  data: {
    id: Id
  }
}
export type BaseTaxonomyRequest = {
  parent_id?: Id
}
export type CreateSpecialtyRequest = BaseTaxonomyRequest & BaseSpecialty
export type GetSpecialtyResponse = {
  data?: Specialty
}
export type UpdateSpecialtyRequest = CreateSpecialtyRequest
export type ListIndustriesResponse = {
  meta?: MetaResponse
  data?: Industry[]
}
export type CreateIndustryResponse = Success & {
  data: {
    id: Id
  }
}
export type CreateIndustryRequest = BaseTaxonomyRequest & BaseIndustry
export type GetIndustryResponse = {
  data?: Industry
}
export type UpdateIndustryRequest = CreateIndustryRequest
export type IncludedDevice = {
  users?: {
    [key: string]: User
  }
}
export type ListDevicesResponse = {
  meta?: MetaResponse
  data?: Device[]
  included?: IncludedDevice
}
export type CreateDeviceResponse = Success & {
  data: {
    device_id: DeviceId
  }
}
export type CreateDeviceRequest = {
  name?: string
  pairing_code: PairingCode
  serial_number: Uuid
}
export type PairDeviceAction = "PAIR_DEVICE" | "UNPAIR_DEVICE"
export type PairDeviceRequest = {
  pairing_code: PairingCode
  event_id?: FirestoreDocumentId3
}
export type GetDeviceResponse = {
  data?: Device
  included?: IncludedDevice
}
export type UpdateDeviceRequest = CreateDeviceRequest
export type FcmTokenId = string
export type FcmToken = {
  fcm_token_id: FcmTokenId
  metadata?: Metadata
  user_id: FirebaseUserId
  device_id?: DeviceId
  token: string
}
export type IncludedFcmToken = {
  users?: {
    [key: string]: User
  }
  devices?: {
    [key: string]: Device
  }
}
export type ListFcmTokensResponse = {
  meta?: MetaResponse
  data?: FcmToken[]
  included?: IncludedFcmToken
}
export type CreateFcmTokenResponse = Success & {
  data: {
    fcm_token_id: FcmTokenId
  }
}
export type CreateFcmTokenRequest = {
  token: string
  device_id?: Id
}
export type GetFcmTokenResponse = {
  data?: FcmToken
  included?: IncludedFcmToken
}
export type UpdateFcmTokenRequest = CreateFcmTokenRequest
export type ClientType = "EVENTS" | "MAD" | "MWV" | "MMA" | "MS" | "IUC"
export type EventConnectionUser = {
  user_id: FirebaseUserId
  name: UserName
  photo?: Url
  event_user_role?: EventUserRole
  client_type?: ClientType
  start_time: DateTime & any
  end_time: DateTime & any
  attendance_percentage?: number
  total_connection_time: number
  total_disconnection_time: number
  total_disconnections: number
}
export type EventConnectionsReport = {
  event_id: FirestoreDocumentId3
  start_time: DateTime & any
  end_time: DateTime & any
  total_connection_time: number
  total_disconnection_time: number
  total_disconnections: number
  event_users?: EventConnectionUser[]
}
export type GetEventConnectionsReportResponse = {
  data?: EventConnectionsReport
}
export type FirestoreDocumentId4 = string
export type CreateReportResponse = Success & {
  data: {
    report_id: FirestoreDocumentId4
  }
}
export type EventConnectionType = "EVENT_USER_CONNECT" | "EVENT_USER_DISCONNECT"
export type CreateEventConnectionsReportRequest = {
  type: EventConnectionType
  user_id: FirebaseUserId
  client_type?: ClientType
  is_streamer?: boolean
}
export type EventCo2EmissionUser = {
  user_id: FirebaseUserId
  co2_emission: number
}
export type EventCo2EmissionsReport = {
  event_id: FirestoreDocumentId3
  co2_emission_users: EventCo2EmissionUser[]
}
export type GetEventCo2EmissionsReportResponse = {
  data?: EventCo2EmissionsReport
}
export type Image = {
  image_id: Uuid
  metadata?: Metadata
  name: string
  url: Url
  tags?: string[]
  public?: boolean
  organization_id?: OrganizationId
}
export type IncludedImage = Record<string, unknown>
export type ListImagesResponse = {
  meta?: MetaResponse
  data?: Image[]
  included?: IncludedImage
}
export type CreateImageResponse = Success & {
  data: {
    image_id: Uuid
  }
}
export type CreateImageRequest = {
  name: string
  url: Url
  tags?: string[]
  public?: boolean
  organization_id?: OrganizationId
}
export type GetImageResponse = {
  data?: Image
  included?: IncludedImage
}
export type UpdateImageRequest = CreateImageRequest
export type DemoMainVideo = {
  url: Url
  settings?: {
    fov?: number
    horizontal_shift?: number
    vertical_shift?: number
  }
}
export type DemoOverlay = {
  url: Url
  label?: string
}
export type DemoAttendee = {
  name: string
  photo?: Url
}
export type Demo = {
  demo_id: Uuid
  metadata?: Metadata
  organization_ids: OrganizationId[]
  instructor_ids?: FirestoreDocumentId[]
  title: string
  description?: string
  photo?: Url
  main_video: DemoMainVideo
  overlays?: DemoOverlay[]
  attendees?: DemoAttendee[]
  guest_instructors?: GuestInstructor[]
  duration?: number
}
export type IncludedDemo = {
  organizations?: {
    [key: string]: Organization
  }
  instructors?: {
    [key: string]: Instructor
  }
}
export type ListDemosResponse = {
  meta?: MetaResponse
  data?: Demo[]
  included?: IncludedDemo
}
export type CreateDemoResponse = Success & {
  data: {
    demo_id: Uuid
  }
}
export type CreateDemoRequest = {
  organization_ids: OrganizationId[]
  instructor_ids?: FirestoreDocumentId[]
  guest_instructors?: GuestInstructor[]
  title: string
  description?: string
  photo?: Url
  duration?: number
  main_video: DemoMainVideo
  overlays?: DemoOverlay[]
  attendees?: DemoAttendee[]
}
export type GetDemoResponse = {
  data?: Demo
  included?: IncludedDemo
}
export type UpdateDemoRequest = CreateDemoRequest
export type HeadsetRequestLoanType = "UNKNOWN" | "NORMAL" | "PERMANENT"
export type HeadsetRequest = {
  headset_request_id: Uuid
  metadata?: Metadata
  user_id: FirebaseUserId
  event_ids?: FirestoreDocumentId3[]
  status?: HeadsetRequestStatus
  device_type?: HeadsetRequestDeviceType
  loan_type?: HeadsetRequestLoanType
  expected_delivery_date?: DateTime
  order_date?: DateTime
  quantity?: number
  name?: UserName
  address?: Address
  tracking_info?: TrackingInfo
  late_fee_ack?: string
  user_classification?: UserClassification
  headsets_serial_numbers?: string[]
}
export type ListHeadsetRequestsResponse = {
  meta?: MetaResponse
  data?: HeadsetRequest[]
}
export type FirestoreDocumentId5 = string
export type CreateHeadsetRequestResponse = Success & {
  data: {
    headset_request_id: FirestoreDocumentId5
  }
}
export type CreateHeadsetRequestRequest = {
  user_id?: string
  event_ids?: FirestoreDocumentId3[]
  status?: HeadsetRequestStatus
  device_type?: HeadsetRequestDeviceType
  loan_type?: HeadsetRequestLoanType
  expected_delivery_date?: DateTime
  order_date?: DateTime
  quantity?: number
  name?: UserName
  address?: Address
  tracking_info?: TrackingInfo
  late_fee_ack?: string
  user_classification?: UserClassification
  headsets_serial_numbers?: string[]
}
export type GetHeadsetRequestResponse = {
  data?: HeadsetRequest
}
export type UpdateHeadsetRequestRequest = CreateHeadsetRequestRequest
export type LinkHeadsetRequestEventsRequest = {
  event_ids: FirestoreDocumentId3[]
}
export type ListHeadsetRequestHistoryResponse = {
  meta?: MetaResponse
  data?: HeadsetRequest[]
}
export type MeetingId = number
export type Passcode = number
export type VrPasscode = number
export type CreateJoinCodeResponse = Success &
  (
    | {
        data: {
          join_code_id: FirestoreDocumentId5
          meeting_id: MeetingId
          passcode: Passcode
          expiration: DateTime
        }
      }
    | {
        data: {
          join_code_id: FirestoreDocumentId5
          passcode: VrPasscode
          expiration: DateTime
        }
      }
  )
export type CreateJoinCodeRequest = {
  user_id: FirebaseUserId
  event_id?: FirestoreDocumentId3
  serial_number?: Uuid
}
export type ExchangeJoinCodeResponse = Success & {
  data: {
    token: string
    event_id?: FirestoreDocumentId3
    user_id: FirebaseUserId
  }
}
export type ExchangeJoinCodeRequest =
  | {
      meeting_id: MeetingId
      passcode: Passcode
    }
  | {
      vr_passcode: VrPasscode
      serial_number?: Uuid
    }
export type FirestoreDocumentId6 = string
export type StreamServer = {
  stream_server_id: FirestoreDocumentId6
  event_id: FirestoreDocumentId3
  user_id: FirebaseUserId
  eip_id: string
  public_ip: string
  domain: string
  instance_id: string
  ami_image_id: string
  instance_type: string
  launch_time: DateTime
  metadata: Metadata
}
export type ListStreamServersResponse = {
  meta?: MetaResponse
  data?: StreamServer[]
}
export type UpsertStreamServerResponse = Success & {
  data: {
    headset_request_id?: FirestoreDocumentId5
  }
}
export type UpsertStreamServerRequest = {
  event_id: FirestoreDocumentId3
  user_id: FirebaseUserId
  eip_id: string
  public_ip: string
  domain: string
  instance_id: string
  ami_image_id: string
  instance_type: string
  launch_time: DateTime
}
export type GetStreamServerResponse = {
  data?: StreamServer
}
export type CxrServer = {
  cxr_server_id: Uuid
  eip_id?: string
  public_ip?: string
  domain?: string
  instance_id: string
  ami_image_id: string
  instance_type: string
  instance_state: string
  user_id?: FirebaseUserId
  device_id?: DeviceId
  launch_time: DateTime
  metadata: Metadata
}
export type ListCxrServersResponse = {
  meta?: MetaResponse
  data?: CxrServer[]
}
export type CreateCxrServerResponse = Success & {
  data: {
    cxr_server_id: string
  }
}
export type CreateCxrServerRequest = {
  ami_image_id?: string
  instance_type?: string
}
export type GrabCxrServerResponse = {
  data?: {
    cxr_server_id: Uuid
    public_ip: string
    domain: string
  }
}
export type ListCxrInstanceTypesResponse = {
  meta?: MetaResponse
  data?: string[]
}
export type ListCxramIsResponse = {
  meta?: MetaResponse
  data?: {
    ami_image_id: string
  }[]
}
export type GetCxrServerResponse = {
  data?: CxrServer
}
export type UpdateCxrServerRequest = {
  eip_id?: string
  public_ip?: string
  domain?: string
  instance_id?: string
  ami_image_id?: string
  instance_type?: string
  launch_time?: DateTime
  instance_state?: string
}
export type WaitCxrServerRequest = {
  instance_state: string
}
export type UpdateCxrServerInstanceStateRequest = {
  instance_state: string
}
export type AssignCxrServerIpResponse = {
  data?: {
    cxr_server_id: Uuid
    eip_id: string
    public_ip: string
    domain: string
  }
}
export const {
  useWhoAmIQuery,
  useListEventsQuery,
  useCreateEventMutation,
  useCountEventsQuery,
  useGetEventQuery,
  useUpdateEventMutation,
  useDeleteEventMutation,
  useUpdateEventRegistrationStateMutation,
  useUpdateEventStatusMutation,
  useUpdateEventSettingsMutation,
  useStartEventStreamServerMutation,
  useTerminateEventStreamServerMutation,
  useUpsertEventUserMutation,
  useDeleteEventUserMutation,
  usePostEventUserStatusActionsMutation,
  usePostEventUserRoleActionMutation,
  usePostEventUserNpsActionMutation,
  useListUsersQuery,
  useCreateUserMutation,
  useCountUsersQuery,
  useInviteEmailMutation,
  useVerifyEmailMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useUpsertUserOrganizationMutation,
  useDeleteUserOrganizationMutation,
  useSendEmailInviteGeneralMutation,
  useSendEmailInviteEventMutation,
  useUserRegistrationStatusQuery,
  useVerifyUserMutation,
  useSendEmailVerificationMutation,
  useSendAccountRemovalRequestNotificationsMutation,
  useListOrganizationsQuery,
  useCreateOrganizationMutation,
  useCountOrganizationsQuery,
  useGetOrganizationQuery,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
  useListInstructorsQuery,
  useCreateInstructorMutation,
  useCountInstructorsQuery,
  useGetInstructorQuery,
  useUpdateInstructorMutation,
  useDeleteInstructorMutation,
  useListLocationsQuery,
  useCreateLocationMutation,
  useCountLocationsQuery,
  useGetLocationQuery,
  useUpdateLocationMutation,
  useDeleteLocationMutation,
  useListSpecialtiesQuery,
  useCreateSpecialtyMutation,
  useGetSpecialtyQuery,
  useUpdateSpecialtyMutation,
  useDeleteSpecialtyMutation,
  useGetSubSpecialtyQuery,
  useUpdateSubSpecialtyMutation,
  useDeleteSubSpecialtyMutation,
  useListIndustriesQuery,
  useCreateIndustryMutation,
  useGetIndustryQuery,
  useUpdateIndustryMutation,
  useDeleteIndustryMutation,
  useGetIndustryJobTypeQuery,
  useUpdateIndustryJobTypeMutation,
  useDeleteIndustryJobTypeMutation,
  useListDevicesQuery,
  useCreateDeviceMutation,
  usePairDeviceMutation,
  useGetDeviceQuery,
  useUpdateDeviceMutation,
  useDeleteDeviceMutation,
  useListFcmTokensQuery,
  useCreateFcmTokenMutation,
  useGetFcmTokenQuery,
  useUpdateFcmTokenMutation,
  useDeleteFcmTokenMutation,
  useGetHeadsetRequestsReportsQuery,
  useGetConnectionsReportQuery,
  useCreateConnectionReportMutation,
  useGetCo2EmissionsReportQuery,
  useCreateCo2EmissionReportMutation,
  useListImagesQuery,
  useCreateImageMutation,
  useGetImageQuery,
  useUpdateImageMutation,
  useDeleteImageMutation,
  useListDemosQuery,
  useCreateDemoMutation,
  useGetDemoQuery,
  useUpdateDemoMutation,
  useDeleteDemoMutation,
  useSpeedTestQuery,
  useListHeadsetRequestsQuery,
  useCreateHeadsetRequestMutation,
  useGetHeadsetRequestQuery,
  useUpdateHeadsetRequestMutation,
  useDeleteHeadsetRequestMutation,
  useLinkHeadsetRequestEventsMutation,
  useListHeadsetRequestHistoryQuery,
  useCreateJoinCodeMutation,
  useExchangeJoinCodeMutation,
  useListStreamServersQuery,
  useUpsertStreamServerMutation,
  useGetStreamServerQuery,
  useDeleteStreamServerMutation,
  useListCxrServersQuery,
  useCreateCxrServerMutation,
  useRefreshCxrServersMutation,
  useGrabCxrServerMutation,
  useGetCxrServerInstanceTypesQuery,
  useGetCxrServerAmIsQuery,
  useGetCxrServerQuery,
  useUpdateCxrServerMutation,
  useDeleteCxrServerMutation,
  useStartCxrServerMutation,
  useStopCxrServerMutation,
  useWaitCxrServerMutation,
  useUpdateCxrServerInstanceStateMutation,
  useUnlinkCxrServerUserMutation,
  useAssignCxrServerIpMutation,
  useCxrListInstancesQuery,
  useCxrCreateInstanceMutation,
  useCxrListDevicesQuery,
  useCxrCreateDeviceMutation,
  useCxrGrabIpMutation,
  useCxrReleaseIpMutation,
} = injectedRtkApi
