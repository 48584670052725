import React, { useRef, useEffect, useContext, useMemo, useState } from "react"
import { Stage, Layer, Text, Line, Group, Rect } from "react-konva"
import { useCanvasUI } from "../containers/CanvasUI"
import CanvasImageRounded from "./CanvasImageRounded"
import { PeerContext } from "../containers/Peer"
import UserListHeader from "./UserListHeader"
import theme from "../../data/canvasTheme"
import UserListMenu from "./UserListMenu"
import person from "../../images/icons/svg/account-circle-fill.svg"
import immertecLogo from "../../images/logo-small.png"
import CanvasImage from "./CanvasImage"
import volumeHigh from "../../images/icons/svg/volume-up-fill.svg"
import volumeOff from "../../images/icons/svg/volume-mute-fill.svg"
import { UIContext } from "../containers/UI"
import { MicContext } from "../containers/Mic"
import { MediaContext } from "../containers/Media"
import { useScene } from "../containers/Scene"
import { enums } from "../libs/api"

const settings = {
  width: 440,
  height: 60,
  heightMax: 60 * 12,
  fontSize: 18,
  iconSize: 30,
  headerHeight: 45,
  menuHeight: 115,
}

const UserListCanvas = () => {
  const stage = useRef(null)
  const layer = useRef(null)
  const timer = useRef(0)
  const [canvases, { dispatch, updateTexture }] = useCanvasUI()
  const { peers } = useContext(PeerContext)
  const { muted, toggleMute } = useContext(MicContext)
  const { resetOverlay, setPopup, toggleSettings, showImmertecEmployees } = useContext(UIContext)
  const { exitStream } = useContext(MediaContext)
  const [, sceneDispatch] = useScene()
  const [filteredPeers, setFilteredPeers] = useState(peers)

  const onMount = () => {
    dispatch({ type: "addStage", payload: { stage: stage.current } })
  }

  const onDismount = () => {
    dispatch({ type: "removeStage", payload: { id: stage.current.attrs.id } })
  }

  const updateTextures = () => {
    if (stage.current) {
      layer.current.draw()
      updateTexture(stage.current.attrs.id)
    }
  }

  useEffect(() => {
    if (!peers) return

    const filtered = peers.filter((peer) => {
      return !(
        !showImmertecEmployees &&
        !peer.ui?.parens.endsWith("(You)") &&
        peer.eventRole === enums.EventUserRole.IMMERTEC_EMPLOYEE
      )
    })
    setFilteredPeers(filtered)
  }, [peers, showImmertecEmployees, setFilteredPeers])

  useEffect(() => {
    onMount()
    return onDismount
  }, [])

  const [width, height] = useMemo(() => {
    const width = settings.width
    const height =
      filteredPeers.length === 0
        ? // avoid 0 height canvas
          settings.height
        : filteredPeers.length * settings.height

    const shouldUpdateSize =
      (stage.current && canvases[stage.current.attrs.id].width !== width) ||
      (stage.current && canvases[stage.current.attrs.id].height !== height)

    if (shouldUpdateSize) {
      dispatch({ type: "setSize", payload: { id: stage.current.attrs.id, width, height } })
    }

    return [width, height]
  }, [filteredPeers.length, stage])

  useEffect(() => {
    clearTimeout(timer.current)
    // NOTE: We need to update textures with timeout, so it has enough time to render the updates on canvas.
    timer.current = setTimeout(() => {
      updateTextures()
    }, 50)

    return () => clearTimeout(timer.current)
  }, [JSON.stringify(filteredPeers)])

  return (
    <Stage
      ref={stage}
      id="user-list"
      width={width}
      height={height + settings.headerHeight + settings.menuHeight}
      style={{ position: "absolute", top: 0, left: 0, visibility: "hidden" }}
    >
      <Layer ref={layer} id="user-list">
        <UserListHeader
          width={settings.width}
          height={settings.headerHeight}
          fill={theme.color.darkgray.hex}
          cornerRadius={[10, 10, 0, 0]}
          align="center"
          verticalAlign="middle"
          fontFamily="Gilroy"
          fontSize={settings.fontSize}
          fontColor={theme.color.white.hex}
          updateTextures={updateTextures}
          toggleSettings={toggleSettings}
          listening={false}
        />

        <UserListMenu
          width={settings.width}
          height={settings.menuHeight}
          y={settings.headerHeight}
          paddingTop={settings.headerHeight}
          settings={settings}
          updateTextures={updateTextures}
          resetOverlay={resetOverlay}
          muted={muted}
          toggleMute={toggleMute}
          setPopup={setPopup}
          exitStream={exitStream}
          sceneDispatch={sceneDispatch}
        />

        <Rect
          width={width}
          height={height}
          y={settings.headerHeight + settings.menuHeight}
          fill={theme.color.grey.hex}
          cornerRadius={[0, 0, 10, 10]}
          listening={false}
        />

        {filteredPeers.map((peer, index) => {
          const imageX = 10
          const imageY =
            index * settings.height +
            settings.height / 2 -
            settings.iconSize / 2 +
            settings.headerHeight +
            settings.menuHeight

          return (
            <Group key={`peer-${peer.connectionId}`}>
              <CanvasImageRounded
                x={imageX}
                y={imageY}
                width={settings.iconSize}
                height={settings.iconSize}
                listening={false}
                src={peer.ui?.nameIsHidden ? immertecLogo : peer.photo ? peer.photo : person}
                onLoaded={updateTextures}
              />
              <Text
                x={50}
                y={index * settings.height + settings.headerHeight + settings.menuHeight}
                align="left"
                verticalAlign="middle"
                width={settings.width}
                height={settings.height}
                fill="white"
                fontFamily="Gilroy"
                fontSize={settings.fontSize}
                listening={false}
                text={`${peer.ui?.displayName ?? peer.name} ${peer.ui?.parens ?? ""}`}
              />
              <CanvasImage
                x={settings.width - settings.iconSize - 10}
                y={
                  index * settings.height +
                  settings.height / 2 -
                  settings.iconSize / 2 +
                  settings.headerHeight +
                  settings.menuHeight
                }
                width={settings.iconSize}
                height={settings.iconSize}
                listening={false}
                src={peer.speaking ? volumeHigh : peer.muted ? volumeOff : null}
                onLoaded={updateTextures}
              />
              <Line
                x={0}
                y={index * settings.height + settings.headerHeight + settings.menuHeight}
                points={[0, 0, settings.width, 0]}
                stroke={theme.color.lightgrey.hex}
                strokeWidth={1}
                listening={false}
                visible={index > 0}
              />
            </Group>
          )
        })}
      </Layer>
    </Stage>
  )
}

export default UserListCanvas
