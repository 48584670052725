import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export type AuthState = {
  token?: string | (() => string) | PromiseLike<string> | (() => PromiseLike<string>)
  user?: { id: string; email: string }
}

const slice = createSlice({
  name: "auth",
  initialState: {} as AuthState,
  reducers: {
    resetCredentials: (state) => {
      state.token = undefined
      state.user = undefined
    },
    setCredentials: (state, { payload: { user, token } }: PayloadAction<Required<AuthState>>) => {
      state.token = token
      state.user = user
    },
  },
})

export const { resetCredentials, setCredentials } = slice.actions
export const authReducer = slice.reducer

export const selectCurrentUser = (state: { auth: AuthState }): AuthState => state.auth
