import React, { useContext, useState, useEffect } from "react"
import { Flex, Box, Button, Text, Heading } from "rebass"
import { CloseOutlined, SettingFilled } from "@ant-design/icons"
import { MediaContext } from "../containers/Media"
import { MicContext } from "../containers/Mic"
import { useScene } from "../containers/Scene"
import AudioAnalyser from "./AudioAnalyser"
import SoundCheck from "./SoundCheck"
import { UserContext } from "../containers/User"

const CloseButton = (props) => (
  <Box
    color="#696969"
    fontSize="30px"
    sx={{
      position: "absolute",
      top: "5px",
      right: "5px",
      cursor: "pointer",
      transition: "0.2s all",
      "&:hover": { color: "white" },
    }}
    {...props}
  >
    <CloseOutlined />
  </Box>
)

const SettingsButton = (props) => (
  <Box
    ml="1rem"
    color="#696969"
    fontSize="30px"
    sx={{
      cursor: "pointer",
      transition: "0.2s all",
      "&:hover": { color: "white" },
    }}
    {...props}
  >
    <SettingFilled />
  </Box>
)

const Container = ({ children }) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    sx={{
      position: "absolute",
      top: 0,
      pointerEvents: "none",
      "& > *": { pointerEvents: "all" },
    }}
    width="100%"
    height="100%"
    bg="rgba(0, 0, 0, 0.65)"
  >
    <Box
      p="4"
      bg="black"
      alignSelf="center"
      textAlign="center"
      sx={{ position: "relative", boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)" }}
    >
      {children}
    </Box>
  </Flex>
)

const MediaPrompts = () => {
  const { granted, request, error, goWithout } = useContext(MicContext)
  const { setEvent } = useContext(UserContext)
  const {
    activateAudio,
    gestured,
    connected,
    isStreamEnded,
    error: mediaError,
    exitStream,
    isStreamerIssues,
  } = useContext(MediaContext)
  const [scene, dispatch] = useScene()
  const [dismiss, setDismiss] = useState(false)
  const [isRequesting, setRequesting] = useState(true)

  const onNext = () => {
    if (!granted && !goWithout) {
      request()
    } else {
      setRequesting(false)
    }
  }

  const onCancel = () => {
    exitStream()
    setEvent(null)
  }

  useEffect(() => {
    if (granted || goWithout) {
      if (!gestured) {
        activateAudio()
      }
      if (!error) {
        setRequesting(false)
      }
    }
  }, [granted, goWithout, error])

  if (!connected && !mediaError && !isStreamEnded) {
    const message = isStreamerIssues ? "Technical difficulties" : "Connecting"

    return (
      <Container>
        <Flex flexDirection="column" width="100%">
          <Text mb="3">{message}, please wait...</Text>
          <Button minWidth="10rem" width="100%" onClick={onCancel}>
            Cancel
          </Button>
        </Flex>
      </Container>
    )
  }

  if (!connected) {
    return null
  }

  return (
    <Container>
      <Box sx={{ display: isRequesting ? "block" : "none" }}>
        <Heading fontSize="3">Grant mic permissions</Heading>
        <Text my="3">Mic access needed to be heard by others</Text>
        <Box my="4">
          <AudioAnalyser variant={isRequesting && "permission"} processAudio={false} />
        </Box>
        {error && (
          <Text my="3" color="red">
            {error}
          </Text>
        )}
        <Button fontSize="2" width="100%" onClick={onNext}>
          Next
        </Button>
      </Box>

      <Box sx={{ display: !isRequesting ? "block" : "none" }}>
        <Box sx={{ display: dismiss ? "none" : "inherit" }}>
          <CloseButton onClick={() => setDismiss(true)} />
          <Flex mb="4">
            <Box mr="3">
              <AudioAnalyser variant={goWithout ? "permission" : "levels"} />
            </Box>
            <Box>
              <SoundCheck />
            </Box>
          </Flex>
        </Box>
        <Flex alignItems="center">
          {scene.xr ? (
            !scene.session ? (
              <Button
                width="100%"
                onClick={() => {
                  navigator.xr
                    .requestSession("immersive-vr", {
                      optionalFeatures: ["local-floor", "bounded-floor"],
                    })
                    .then((session) => dispatch({ type: "setSession", payload: session }))
                }}
              >
                Start
              </Button>
            ) : (
              <Button
                width="100%"
                onClick={() => {
                  dispatch({ type: "exitXR" })
                }}
              >
                Exit VR
              </Button>
            )
          ) : (
            <Flex flexDirection="column" width="100%">
              {!dismiss && <Text mb="4">VR Not Supported</Text>}
              <Button minWidth="10rem" width="100%" onClick={exitStream}>
                Exit
              </Button>
            </Flex>
          )}

          {dismiss && <SettingsButton onClick={() => setDismiss(false)} />}
        </Flex>
      </Box>
    </Container>
  )
}

export default MediaPrompts
