import React, { useRef, useEffect, useContext, useState, useMemo } from "react"
import styled from "styled-components"
import { MediaContext } from "../containers/Media"
import logger from "../etc/logger"

const Video = styled.video`
  ${(props) => !props.requiresLayout && "display: none;"}
`

const VideoElement = ({ id, onLayout, track, info }) => {
  const [requiresLayout, setRequiresLayout] = useState(false)
  const videoEl = useRef(null)
  const mediaStreamId = info.appData.streamId

  const onReady = () => {
    videoEl.current.removeEventListener("canplay", onReady)

    // Play video track right after it's ready.
    videoEl.current.play().catch((e) => logger.warn(`Cannot play ${id} video track.`, e))
    // Pass the DOM element to the videos state.
    onLayout(videoEl, id)
  }

  useEffect(() => {
    const el = videoEl.current
    const isMediaStream = track && !track.src
    if (isMediaStream) {
      el.srcObject = new MediaStream([track])
    } else {
      el.src = track.src
      setRequiresLayout(true)
    }

    if (el.readyState >= 3) {
      onLayout(videoEl, id)
    } else {
      el.addEventListener("canplay", onReady)
    }

    return () => {
      el.removeEventListener("canplay", onReady)
    }
  }, [])

  return (
    <Video
      requiresLayout={requiresLayout}
      ref={videoEl}
      data-streamid={mediaStreamId}
      preload="auto"
      autoPlay
      playsInline
    />
  )
}

const VideoElements = () => {
  const { videos, addElement } = useContext(MediaContext)

  return useMemo(() => {
    return Object.keys(videos).map((id) => (
      <VideoElement id={id} key={`MediaElement-${id}`} onLayout={(el) => addElement({ el, id })} {...videos[id]} />
    ))
  }, [videos])
}

export default VideoElements
