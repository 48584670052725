import React, { useContext } from "react"
import styled from "styled-components"
import { Flex, Box } from "rebass"
import { Button } from "../libs/ui"
import { AuthContext } from "../containers/Auth"
import { UserContext } from "../containers/User"
import { MediaContext } from "../containers/Media"
import { AuthOptions } from "./AuthOptions"
import Lobby from "./lobby"
import { ThankYouFeedback } from "./ThankYouFeedback"

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Centered = ({ children }) => (
  <Flex alignItems="center" justifyContent="center" height="100%">
    <Box textAlign="center">{children}</Box>
  </Flex>
)

const Onboard = ({ children }) => {
  const { loggedIn, isGuestMode } = useContext(AuthContext)
  const { event, setEvent } = useContext(UserContext)
  const { loading, connected, error, setError, isStreamEnded, joinStream } = useContext(MediaContext)

  const onExit = () => {
    setError(null)
    setEvent(null)
  }

  return (
    <Container>
      {!loggedIn && !event && <AuthOptions />}
      {loggedIn && !event && <Lobby />}
      {(loggedIn || isGuestMode) && event && (
        <>
          {loading && !error && (
            <Centered>
              <h1>Loading ...</h1>
            </Centered>
          )}

          {error && (
            <Centered>
              <h1>
                The event hasn&apos;t started yet, <br />
                or there has been an error!
              </h1>
              <p>{error}</p>
              <Box mt="4">
                <Button mr="2" onClick={joinStream}>
                  Try again
                </Button>
                <Button onClick={onExit}>Exit</Button>
              </Box>
            </Centered>
          )}

          {!connected && !loading && !error && isStreamEnded && (
            <ThankYouFeedback onClose={onExit} eventId={event.id} />
          )}

          {children}
        </>
      )}
    </Container>
  )
}

export default Onboard
