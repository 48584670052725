import React, { createContext, useEffect, useState, useContext, useRef, useMemo } from "react"
import { io } from "socket.io-client"
import MediaClient from "../components/MediaClient"
import { AuthContext } from "./Auth"
import { UserContext } from "./User"
import logger from "../etc/logger"
import { auth } from "../etc/firebase"
import * as Sentry from "@sentry/browser"
import { connectionIssuesReasons, socketDisconnectionReasons } from "../constants"

export const MediaContext = createContext()

const MediaContainer = ({ children }) => {
  const socket = useRef(null)
  const mediaClient = useRef(null)
  const reconnectTimeout = useRef(null)
  const isStreamerIssues = useRef(false)
  const audioCtx = useRef(null)
  const audioDest = useRef(null)
  const stats = useRef(null)
  const audioEl = useRef(null)
  const audioConnected = useRef(false)
  const readyToPlay = useRef(false)
  const audioTracks = useRef({}) // tracks storage that will be added to Audio Context
  const { user, loggedIn, isGuestMode } = useContext(AuthContext)
  const { event } = useContext(UserContext)
  const [gestured, setGestured] = useState(false)
  const [videos, setVideos] = useState({})
  const [connected, setConnected] = useState(false)
  const [isStreamEnded, setIsStreamEnded] = useState(false)
  const [isClientDisconnection, setIsClientDisconnection] = useState(false)
  const [roomMessages, setRoomMessages] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  // Set timeouts after which the client will try to reconnect to the media server.
  // NOTE: 5 seconds for the initial reconnection try seems optimal, but can be reconsidered.
  const initialReconnectionPeriod = 5000 // 5 seconds.
  // NOTE: 3 seconds for the repeated reconnection try seems optimal, but can be reconsidered.
  const recurrentReconnectionPeriod = 3000 // 3 seconds.

  useEffect(() => {
    if (event) {
      joinStream()
    } else {
      setIsStreamEnded(false)
      setIsClientDisconnection(false)
    }
  }, [event])

  // Exit stream on unmount.
  useEffect(() => exitStream, [])

  const subscribeTracks = async () => {
    const consumerStats = { video: true, audio: false }
    await mediaClient.current.subscribeToTracks(consumerStats)
    mediaClient.current.on("newTrack", (track, info) => addTrack({ track, info }))
    mediaClient.current.on("removeTrack", (info) => removeTrack(info.id))
    mediaClient.current.on("stats", (clientStats) => {
      // we emit stats back to socket to track them server side for admin interface.
      // if (socket.current) socket.current.emit("peer-stats", clientStats)

      stats.current = {
        ...stats.current,
        [clientStats.trackId]: {
          width: clientStats.video.frameWidth,
          height: clientStats.video.frameHeight,
          bitrate: clientStats.bitrate,
        },
      }
    })
  }

  useEffect(() => {
    if (connected) {
      setRoomMessages([])
      subscribeTracks()
    } else {
      if (mediaClient.current) {
        mediaClient.current.closeTransport("send") // close all producers
        mediaClient.current.closeTransport("receive") // close all consumers
        mediaClient.current = null
      }
    }
  }, [connected])

  const onSocketDisconnect = (reason) => {
    logger.debug("Socket disconnected:", reason)

    // Stop all media tracks by closing a media transport if it was created.
    // Closing producers, in turn, will end all related tracks.
    if (mediaClient.current) {
      mediaClient.current.closeTransport("send") // close all producers
      mediaClient.current.closeTransport("receive") // close all consumers
      mediaClient.current = null
    }

    // Clear socket reconnect timeout.
    window.clearTimeout(reconnectTimeout.current)
    audioConnected.current = false

    if (connectionIssuesReasons.includes(reason)) {
      // Try to reconnect.
      reconnectTimeout.current = setTimeout(() => joinStream(), recurrentReconnectionPeriod)
    } else {
      if (reason === socketDisconnectionReasons.CLIENT_DISCONNECT) {
        setIsClientDisconnection(true)
      }
      setIsStreamEnded(true)
    }

    // Needed to make setLoading(false) on connection work, so we get 'room-joined' working in Peer container.
    setLoading(null)
    setConnected(false)
  }

  // Intentional exit from the stream.
  const exitStream = () => {
    window.clearTimeout(reconnectTimeout.current)
    if (socket.current) {
      socket.current.disconnect()
      socket.current = null
    } else {
      setIsStreamEnded(true)
    }
  }

  const joinStream = async () => {
    if ((!loggedIn && !isGuestMode) || !event) {
      return
    }
    if (!event.streamSettings?.mediaServerUrl) {
      setError("Media server url is not provided")
      return
    }

    setError(null)
    setIsStreamEnded(false)

    let userToken = null
    if (user) {
      try {
        userToken = await auth.currentUser.getIdToken(true)
      } catch (e) {
        setError(e.message)
      }
    }

    socket.current = io(event.streamSettings.mediaServerUrl, {
      auth: { token: userToken },
      reconnection: false,
    })
    socket.current.on("connect", () => setLoading(false))

    // Try to reconnect if the initial connection fails
    socket.current.on("connect_error", (err) => {
      logger.warn("Socket connection error.", err)
      Sentry.captureMessage(`Unable to connect to the media server: ${event.mediaServerUrl}`, Sentry.Severity.Warning)
      logger.debug(`Reconnecting in ${initialReconnectionPeriod} seconds...`)
      reconnectTimeout.current = setTimeout(() => joinStream(), initialReconnectionPeriod)
    })

    socket.current.on("room-joined", ({ rtpCapabilities }) => {
      logger.debug("Joined the room successfully! Room Router RtpCapabilities:", rtpCapabilities)
      mediaClient.current = new MediaClient(socket.current, rtpCapabilities)
      setError(null)
      setConnected(true)
    })

    socket.current.on("message", (message) => addRoomMessage(message, 5 * 1000))

    socket.current.on("disconnect", onSocketDisconnect)
  }

  const addRoomMessage = (message, timer = 0) => {
    setRoomMessages((messages) => messages.concat(message))
    if (timer) {
      setTimeout(() => setRoomMessages((messages) => messages.filter(({ id }) => message.id !== id)), timer)
    }
  }

  const addTrack = ({ track, info }) => {
    if (track.kind === "audio") {
      audioTracks.current[info.id] = { track, info, consumed: false }
      updateAudioContext()
      return
    }

    const newVideo = { [info.id]: { track, info } }
    setVideos((prevVideos) => ({ ...prevVideos, ...newVideo }))
  }

  const removeTrack = (id) => {
    if (audioTracks.current[id]) {
      // An audio track (consumer) was removed.
      cleanupAudio(id)
    } else {
      // If video, remove the corresponding video DOM element.
      setVideos((prevVideos) => {
        if (!prevVideos[id]) return prevVideos
        delete prevVideos[id]
        return { ...prevVideos }
      })
    }
  }

  /**
   * Cleans up specified audio track gracefully, so it doesn't stuck in memory.
   *
   * @param {string} id - Unique ID of the audio track.
   */
  const cleanupAudio = (id) => {
    if (!audioTracks.current[id]) {
      return
    }
    audioTracks.current[id].track.stop()
    if (audioTracks.current[id].source) {
      audioTracks.current[id].source.disconnect()
    }
    if (audioTracks.current[id].gain) {
      audioTracks.current[id].gain.disconnect()
    }
    if (audioTracks.current[id].element) {
      audioTracks.current[id].element.remove()
    }
    delete audioTracks.current[id]
  }

  /**
   * Combines all audio track into a single audio, adds each track as a new element otherwise
   */
  const updateAudioContext = () => {
    if (!readyToPlay.current) {
      logger.info("The track is not ready to play. User gesture is required.")
      return
    }

    const AudioContext = window.AudioContext

    if (!audioCtx.current && AudioContext) {
      audioCtx.current = new AudioContext()
      audioDest.current = audioCtx.current.createMediaStreamDestination()
    }

    Object.keys(audioTracks.current).forEach((trackId) => {
      if (audioTracks.current[trackId].consumed) return
      audioTracks.current[trackId].consumed = true
      // Combine all the audio tracks into one.
      const audioStream = new MediaStream([audioTracks.current[trackId].track])

      // NOTE: Chrome has a known bug where it can't hook up / play remote tracks in Web Audio API.
      // https://bugs.chromium.org/p/chromium/issues/detail?id=933677.
      // https://bugs.chromium.org/p/chromium/issues/detail?id=121673.
      // The workaround is to create a temporary audio element and assign the stream to it.
      let audio = new Audio()
      audio.srcObject = audioStream
      audio.addEventListener("canplaythrough", () => {
        // Clear the Audio, since Chrome has a limit of 10 Audio elements which causes the silence issue after several reconnections.
        audio = null
      })

      // TODO: Consider replacing below with just MediaStream and then change the volume on the audio element.
      const source = audioCtx.current.createMediaStreamSource(audioStream)
      const gainNode = audioCtx.current.createGain()
      source.connect(gainNode)
      gainNode.connect(audioDest.current)
      gainNode.gain.value = 0.6
      audioTracks.current[trackId].source = source
      audioTracks.current[trackId].gain = gainNode
    })

    if (audioDest.current && !audioConnected.current) {
      // Bind the resulted track into the audio DOM element.
      audioConnected.current = true
      const track = audioDest.current.stream.getAudioTracks()[0]
      audioEl.current.srcObject = new MediaStream([track])
      audioEl.current.play()
    }
  }

  useEffect(() => {
    if (gestured) updateAudioContext()
  }, [gestured])

  /**
   * Adds a video DOM element to the existing video instance in state.
   * Gets called when the video element is rendered on DOM and ready to play.
   *
   * @param el
   * @param id
   */
  const addElement = ({ el, id }) => {
    setVideos((prevVideos) => {
      if (prevVideos[id]) {
        prevVideos[id].el = el
        prevVideos[id].el.current.play()
      } else {
        logger.warn(`Cannot set element: ${id}`)
        Sentry.captureMessage(`Cannot set element: ${id}`)
      }

      return { ...prevVideos }
    })
  }

  // Used after user gesture to unmute audio on initial interaction.
  const activateAudio = () => {
    readyToPlay.current = true
    setGestured(true)
  }

  const [vrElement, vrSettings] = useMemo(() => {
    if (!videos) {
      return null
    }

    const keys = Object.keys(videos).filter((key) => videos[key].info.appData.streamId === "vr" && videos[key].el)
    return keys.length !== 0 ? [videos[keys[0]].el, videos[keys[0]].info.appData.settings] : [null, null]
  }, [videos])

  const overlayElements = useMemo(() => {
    return Object.keys(videos)
      .filter((key) => videos[key].info.appData.streamId !== "vr" && videos[key].el)
      .reduce((obj, key, index) => {
        obj[index] = videos[key].el
        return obj
      }, [])
  }, [videos])

  return (
    <MediaContext.Provider
      value={{
        socket,
        mediaClient,
        vrElement,
        vrSettings,
        overlayElements,
        videos,
        stats,
        addElement,
        connected,
        joinStream,
        exitStream,
        isStreamEnded,
        setIsClientDisconnection,
        isClientDisconnection,
        error,
        setError,
        loading,
        activateAudio,
        gestured,
        messages: roomMessages,
        addRoomMessage,
        isStreamerIssues: isStreamerIssues.current,
      }}
    >
      {children}
      <audio ref={audioEl} id="audio" />
    </MediaContext.Provider>
  )
}

export default MediaContainer
