import React, { useContext } from "react"
import { Text, Flex, Box, Image } from "rebass"
import styled from "styled-components"
import { Button } from "../../libs/ui"
import { enums } from "../../libs/api"
import { formatAMPM, getTimeInEST, months } from "../../helpers/TimeHelper"
import { UserContext } from "../../containers/User"
import { colors } from "../../etc/theme"
import { InstructorById } from "../getById/InstructorById"
import { LocationById } from "../getById/LocationById"
import { BankOutlined, ClockCircleOutlined } from "@ant-design/icons"

import gradientLeft from "../../../images/slider-gradient-left.png"
import gradientRight from "../../../images/slider-gradient-right.png"
import eventBgGradient from "../../../images/event-bg-gradient.png"
import eventSrcFallback from "../../../images/event-bg.jpg"

const eventStatusTitles = {
  [enums.EventStatus.LIVE]: "Ready To Join",
  [enums.EventStatus.PATIENT_ARRIVED]: "Patient Arrived",
  [enums.EventStatus.PATIENT_PREPARED]: "Patient Prepped",
  [enums.EventStatus.TIMED_OUT]: "Going Live Soon",
  [enums.EventStatus.STOPPED]: "Event Ended",
}

const Events = ({ events }) => {
  const { setEvent } = useContext(UserContext)

  return (
    <SliderContainer overflow="hidden" height={480} width="90%" mx="auto" mt={25} px={20}>
      <Flex overflow="scroll" height={500} width="100%">
        <Flex minWidth="fit-content">
          {events.map((event) => {
            return (
              <Flex key={event.id} flexDirection="column" mx={20}>
                <Flex
                  flexDirection="column"
                  width="412px"
                  overflow="hidden"
                  sx={{
                    position: "relative",
                    borderRadius: 16,
                  }}
                >
                  <EventImageWrapper bg={colors.gray13}>
                    <Image
                      src={event.photo || eventSrcFallback}
                      alt={event.title}
                      width="100%"
                      height="100%"
                      opacity={0.15}
                    />
                  </EventImageWrapper>
                  <Flex
                    alignItems="center"
                    flexBasis="50%"
                    p={16}
                    css={{ gap: 20, position: "absolute", top: 20, left: 0 }}
                  >
                    <InstructorById
                      instructors={event.instructors}
                      guestInstructors={event.guestInstructors}
                      photoOnly
                      size={115}
                      style={{ width: 200 }}
                    />
                    <Text fontSize={24} fontWeight={600}>
                      <InstructorById instructors={event.instructors} guestInstructors={event.guestInstructors} />
                    </Text>
                  </Flex>

                  <Box p={32} backgroundColor={colors.gray9}>
                    <EllipsisTitle fontSize={20} fontWeight={600}>
                      <div className="ellipsis">{event.title}</div>
                    </EllipsisTitle>

                    <Box
                      mt={16}
                      color={colors.gray4}
                      style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
                    >
                      <Text display="inline" mr={2} color={colors.primary}>
                        <BankOutlined />
                      </Text>
                      <LocationById id={event.location} />
                    </Box>

                    <Text mt={20} color={colors.gray4}>
                      <Text display="inline" mr={2} color={colors.primary}>
                        <ClockCircleOutlined />
                      </Text>
                      {months[new Date(event.startTime).getMonth()].substring(0, 3)}{" "}
                      {getTimeInEST(new Date(event.startTime)).getDate()}
                      {!event.flexibleTime && (
                        <>
                          {", "}
                          {formatAMPM(getTimeInEST(new Date(event.startTime)))} -{" "}
                          {formatAMPM(getTimeInEST(new Date(event.endTime)))} EST
                        </>
                      )}
                    </Text>
                  </Box>
                </Flex>
                <Box mt={30} width="100%" textAlign="center">
                  {event.status === enums.EventStatus.LIVE ? (
                    <Button onClick={() => setEvent(event)}>Join Event</Button>
                  ) : (
                    <Text fontSize={20} textAlign="center" color={colors.gray6} p={16}>
                      {eventStatusTitles[event.status] || "Event Not Ready"}
                    </Text>
                  )}
                </Box>
              </Flex>
            )
          })}
        </Flex>
      </Flex>
    </SliderContainer>
  )
}

export default Events

const SliderContainer = styled(Box)`
  position: relative;

  &:before,
  &:after {
    position: absolute;
    content: "";
    top: 0;
    height: 100%;
    width: 50px !important;
    min-width: 50px !important;
    max-width: 50px !important;
    background-image: url(${gradientLeft});
    background-repeat: repeat-y;
    background-size: 50px;
    z-index: 1;
    pointer-events: none;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 36px;
    background-image: url(${gradientRight});
    background-color: transparent;
  }
`

const EventImageWrapper = styled(Box)`
  position: relative;
  height: 180px;

  &:after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url(${eventBgGradient});
    background-repeat: repeat-y;
  }
`

const EllipsisTitle = styled(Text)`
  overflow: hidden;
  width: fit-content;
  height: fit-content;

  .ellipsis {
    height: 48px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: pre-wrap;
  }
`
