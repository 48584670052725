export const socketDisconnectionReasons = {
  // The socket was forcefully disconnected with socket.disconnect().
  SERVER_DISCONNECT: "io server disconnect",
  // The client has manually disconnected the socket using socket.disconnect().
  CLIENT_DISCONNECT: "io client disconnect",
  // The client did not send a PONG packet in the pingTimeout delay.
  CLIENT_TIMEOUT: "ping timeout",
  // The connection was closed (eg. the user has lost connection, or the network was changed from WiFi to 4G).
  CLIENT_TRANSPORT_CLOSE: "transport close",
  // The connection has encountered an error.
  CLIENT_TRANSPORT_ERROR: "transport error",
} as const

// Reasons after which the client should try to reconnect to the media server.
export const connectionIssuesReasons = [
  socketDisconnectionReasons.CLIENT_TIMEOUT,
  socketDisconnectionReasons.CLIENT_TRANSPORT_CLOSE,
  socketDisconnectionReasons.CLIENT_TRANSPORT_ERROR,
]

export type SocketDisconnectionReason = typeof socketDisconnectionReasons[keyof typeof socketDisconnectionReasons]
