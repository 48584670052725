import React from "react"
import * as Sentry from "@sentry/browser"
import { Box } from "rebass"
import { isObjectString } from "../helpers/CommonHelper"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      eventId: null,
    }
  }

  componentDidCatch(error, errorInfo) {
    let errorMessage = error.message
    let isUnexpected = true

    // If error message is an object it means we threw this error ourselves
    // (eg. in case of browser incompatibility)
    if (isObjectString(errorMessage)) {
      const errorObj = JSON.parse(errorMessage)
      if (errorObj.message) {
        errorMessage = errorObj.message
      }
      isUnexpected = false
    }

    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId, error: { unexpected: isUnexpected, message: errorMessage } })
    })
  }

  render() {
    if (this.state.error) {
      return (
        <Box>
          <h1>Ooops!</h1>

          {this.state.error.unexpected ? (
            <>
              <p>That wasn&apos;t supposed to happen.</p>
              <button onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</button>
            </>
          ) : (
            <p>{this.state.error.message}</p>
          )}
        </Box>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
