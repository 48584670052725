import type { model } from "../libs/api"

export type DisplayFullNameType = {
  name?: model.generated.UserName
  showSuffix?: boolean
}

export function displayFullName(name: model.generated.UserName, showSuffix = true): string {
  if (!name) return ""

  const suffix = showSuffix && name?.suffix ? `, ${name.suffix}` : ""
  return `${name?.alias ?? name.first} ${name.last}${suffix}`
}

export default displayFullName
