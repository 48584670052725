import styled, { keyframes } from "styled-components"
import { position, space } from "styled-system"

const spinAnimation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`
const defaultSize = "35px"

const Loader = styled.div`
  ${position}
  ${space}
  display: inline-block;
  width: ${({ size = defaultSize }) => size};
  height: ${({ size = defaultSize }) => size};
  margin: 0 auto;
  position: ${({ centered }) => (centered ? "absolute" : "relative")};
  left: ${({ centered, size = defaultSize }) => (centered ? `calc(50% - ${size} / 2)` : "auto")};
  top: ${({ centered, size = defaultSize }) => (centered ? `calc(50% - ${size} / 2)` : "auto")};
  border-top: 5px solid rgba(255, 255, 255, 0.2);
  border-right: 5px solid rgba(255, 255, 255, 0.2);
  border-bottom: 5px solid rgba(255, 255, 255, 0.2);
  border-left: 5px solid #ffffff;
  border-radius: 50%;
  font-size: 10px;
  text-indent: -9999px;
  transform: translateZ(0);
  animation: ${spinAnimation} 1.1s infinite linear;
  &::after {
    border-radius: 50%;
    width: ${({ size = "35px" }) => size};
    height: ${({ size = "35px" }) => size};
  }
`

export default Loader
