import React, { useRef, useCallback, useEffect } from "react"
import useCanvasUIasInteractable from "../../hooks/useCanvasUIasInteractable"

const CanvasMesh = ({ stage, onTexture, ...props }) => {
  const mesh = useRef(null)
  const { width, height, texture, makeInteractable } = useCanvasUIasInteractable({
    stage,
  })

  useEffect(() => {
    makeInteractable(mesh)
    onTexture(texture)
  }, [])

  return (
    <mesh ref={mesh} name="canvas-ui" {...props}>
      <planeBufferGeometry attach="geometry" args={[width, height]} />
      <meshBasicMaterial attach="material" map={texture} transparent={true} />
    </mesh>
  )
}

const CanvasUI = ({ id, fromCanvas, ...props }) => {
  const [canvases, { dispatch }] = fromCanvas

  const setTexture = useCallback((id, texture) => dispatch({ type: "setTexture", payload: { id, texture } }))

  return (
    Object.keys(canvases).includes(id) && (
      <CanvasMesh stage={canvases[id].stage} onTexture={(texture) => setTexture(id, texture)} {...props} />
    )
  )
}

export default CanvasUI
