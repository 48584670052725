import React, { useRef, useEffect, useContext } from "react"
import { Stage, Layer, Rect, Text, Group } from "react-konva"
import { useCanvasUI } from "../containers/CanvasUI"
import { UIContext } from "../containers/UI"
import theme from "../../data/canvasTheme"
import CanvasImage from "./CanvasImage"
import { MediaContext } from "../containers/Media"
import subtractIcon from "../../images/icons/svg/subtract-fill.svg"
import addIcon from "../../images/icons/svg/add-fill.svg"
import eyeShowIcon from "../../images/icons/svg/eye.svg"
import eyeHideIcon from "../../images/icons/svg/eye-off.svg"

const SettingsCanvas = () => {
  const stage = useRef(null)
  const layer = useRef(null)
  const { settings, setSettings, showImmertecEmployees, toggleImmertecEmployees } = useContext(UIContext)
  const { camera } = useContext(MediaContext)
  const [, { dispatch, updateTexture }] = useCanvasUI()
  const width = 295
  const height = 220
  const headerHeight = 45

  const onMount = () => {
    dispatch({ type: "addStage", payload: { stage: stage.current } })
  }

  const onDismount = () => {
    dispatch({ type: "removeStage", payload: { id: stage.current.attrs.id } })
  }

  const updateTextures = () => {
    if (stage.current) {
      layer.current.draw()
      updateTexture(stage.current.attrs.id)
    }
  }

  const changeBgColor = (e, color) => {
    e.currentTarget.fill(color)
    updateTextures()
  }

  const changeShift = (value, kind = "horizontal") => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      vr: {
        ...prevSettings.vr,
        hShift: kind === "horizontal" ? value : prevSettings.vr.hShift,
        vShift: kind === "vertical" ? value : prevSettings.vr.vShift,
      },
    }))
  }

  useEffect(() => {
    onMount()
    return onDismount
  }, [])

  useEffect(() => {
    updateTextures()
  }, [settings.vr])

  useEffect(() => {
    if (camera) {
      changeShift(camera.hShift, "horizontal")
      changeShift(camera.vShift, "vertical")
    }
  }, [camera])

  return (
    <Stage
      ref={stage}
      id="settings"
      width={width}
      height={height + headerHeight}
      style={{ position: "absolute", top: 0, left: 0, visibility: "hidden" }}
    >
      <Layer ref={layer} id="settings">
        <Rect width={width} height={headerHeight} fill={theme.color.darkgray.hex} cornerRadius={[10, 10, 0, 0]} />
        <Text
          width={width}
          height={headerHeight}
          align="center"
          verticalAlign="middle"
          fontFamily="Gilroy"
          fontWeight={600}
          fontSize={18}
          fill={theme.color.white.hex}
          listening={false}
          text="Settings"
        />
        <Rect
          width={width}
          height={height}
          y={headerHeight}
          fill={theme.color.grey.hex}
          cornerRadius={[0, 0, 10, 10]}
          listening={false}
        />

        {/* VR Horizontal shift */}
        <Group width={width} height={60} y={headerHeight + 10}>
          <Text
            width={130}
            height={50}
            x={12}
            y={5}
            verticalAlign="middle"
            fontFamily="Gilroy"
            fontSize={16}
            lineHeight={1.2}
            fill={theme.color.white.hex}
            listening={false}
            text="Horizontal shift"
          />
          <Rect
            width={50}
            height={50}
            x={135}
            y={5}
            fill={theme.color.darkgray.hex}
            cornerRadius={5}
            onMouseOver={(e) => changeBgColor(e, theme.color.semigrey.hex)}
            onMouseOut={(e) => changeBgColor(e, theme.color.darkgray.hex)}
            onMouseUp={() => changeShift(settings.vr.hShift - 5, "horizontal")}
          />
          <CanvasImage
            x={148}
            y={18}
            width={25}
            height={25}
            src={subtractIcon}
            onLoaded={updateTextures}
            listening={false}
          />
          <Text
            width={40}
            x={188}
            y={23}
            align="center"
            fontFamily="Gilroy"
            fontSize={18}
            fontWeight={600}
            fill={theme.color.white.hex}
            text={settings.vr.hShift}
            listening={false}
          />
          <Rect
            width={50}
            height={50}
            x={233}
            y={5}
            fill={theme.color.darkgray.hex}
            cornerRadius={5}
            onMouseOver={(e) => changeBgColor(e, theme.color.semigrey.hex)}
            onMouseOut={(e) => changeBgColor(e, theme.color.darkgray.hex)}
            onMouseUp={() => changeShift(settings.vr.hShift + 5, "horizontal")}
          />
          <CanvasImage
            x={246}
            y={18}
            width={25}
            height={25}
            src={addIcon}
            onLoaded={updateTextures}
            listening={false}
          />
        </Group>

        {/* VR Vertical shift */}
        <Group width={width} height={60} y={headerHeight + 80}>
          <Text
            width={130}
            height={50}
            x={12}
            y={5}
            verticalAlign="middle"
            fontFamily="Gilroy"
            fontSize={16}
            lineHeight={1.2}
            fill={theme.color.white.hex}
            listening={false}
            text="Vertical shift"
          />
          <Rect
            width={50}
            height={50}
            x={135}
            y={5}
            fill={theme.color.darkgray.hex}
            cornerRadius={5}
            onMouseOver={(e) => changeBgColor(e, theme.color.semigrey.hex)}
            onMouseOut={(e) => changeBgColor(e, theme.color.darkgray.hex)}
            onMouseUp={() => changeShift(settings.vr.vShift - 5, "vertical")}
          />
          <CanvasImage
            x={148}
            y={18}
            width={25}
            height={25}
            src={subtractIcon}
            onLoaded={updateTextures}
            listening={false}
          />
          <Text
            width={40}
            x={188}
            y={23}
            align="center"
            fontFamily="Gilroy"
            fontSize={18}
            fontWeight={600}
            fill={theme.color.white.hex}
            text={settings.vr.vShift}
            listening={false}
          />
          <Rect
            width={50}
            height={50}
            x={233}
            y={5}
            fill={theme.color.darkgray.hex}
            cornerRadius={5}
            onMouseOver={(e) => changeBgColor(e, theme.color.semigrey.hex)}
            onMouseOut={(e) => changeBgColor(e, theme.color.darkgray.hex)}
            onMouseUp={() => changeShift(settings.vr.vShift + 5, "vertical")}
          />
          <CanvasImage
            x={246}
            y={18}
            width={25}
            height={25}
            src={addIcon}
            onLoaded={updateTextures}
            listening={false}
          />
        </Group>

        {/* Show/hide Immertec Employees */}
        <Group width={width} height={60} y={headerHeight + 150}>
          <Text
            width={200}
            height={50}
            x={12}
            y={5}
            verticalAlign="middle"
            fontFamily="Gilroy"
            fontSize={16}
            lineHeight={1.2}
            fill={theme.color.white.hex}
            listening={false}
            text={`${showImmertecEmployees ? "Hide" : "Show"} Immertec Employees`}
          />
          <Rect
            width={50}
            height={50}
            x={233}
            y={5}
            fill={theme.color.darkgray.hex}
            cornerRadius={5}
            // onMouseOver={(e) => changeBgColor(e, theme.color.semigrey.hex)}
            // onMouseOut={(e) => changeBgColor(e, theme.color.darkgray.hex)}
            onMouseUp={toggleImmertecEmployees}
          />
          <CanvasImage
            x={245}
            y={18}
            width={25}
            height={25}
            src={showImmertecEmployees ? eyeHideIcon : eyeShowIcon}
            onLoaded={updateTextures}
            listening={false}
          />
        </Group>
      </Layer>
    </Stage>
  )
}

export default SettingsCanvas
