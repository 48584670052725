import React, { useMemo } from "react"
import * as THREE from "three"

const PopupBg = ({ radius = 5, opacity = 0.5, renderOrder = 1, ...props }) => {
  const createGeometry = ({ height, thetaLength }) => {
    return new THREE.CylinderGeometry(radius, radius, height, 10, 1, false, -Math.PI + thetaLength / 2, -thetaLength)
  }

  const material = useMemo(() => {
    const mat = new THREE.MeshBasicMaterial({ color: 0x000000, transparent: true, opacity })
    mat.side = THREE.FrontSide
    return mat
  })

  const mesh = useMemo(() => {
    const mesh = new THREE.Mesh(createGeometry({ height: 100, thetaLength: 7 }), material)
    mesh.renderOrder = renderOrder
    mesh.position.z = 0.06
    return mesh
  })

  return <primitive object={mesh} material={material} name="popup-bg" {...props} />
}

export default PopupBg
