import { levels } from "loglevel"

export default {
  app: {
    environment: process.env.NODE_ENV,
  },
  api: {
    url: process.env.REACT_APP_API_SERVER_URL,
  },
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  },
  logger: {
    level: process.env.REACT_APP_LOGGER_LEVEL || levels.DEBUG,
  },
  media: {
    iceServers: JSON.parse(process.env.REACT_APP_MEDIA_SERVER_ICE_SERVERS || "[]"),
    codec: process.env.REACT_APP_MEDIA_VIDEO_CODEC,
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
  },
}
