import React, { createContext, useState, useCallback, useContext, useRef, useEffect } from "react"
import { get } from "lodash"
import { MicContext } from "./Mic"
import { MediaContext } from "./Media"
import logger from "../etc/logger"

export const UIContext = createContext()

const defaultPopup = {
  show: false,
  width: 0,
  height: 0,
  padding: 20,
  closeTitle: "Close",
  content: null,
}

const defaultSettings = {
  isAnnotationsAllowed: false,
  overlay: { x: -0.8, y: 1 },
  vr: { hShift: 0, vShift: 0, fov: 180, swap: false },
}

const UIContainer = ({ children }) => {
  const [overlayVisible, setOverlayVisible] = useState(true)
  const [shouldResetOverlay, setShouldResetOverlay] = useState(false)
  const [attendeesVisible, setAttendeesVisible] = useState(true)
  const [controllersIdle, setControllersIdle] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const [popup, setPopup] = useState(defaultPopup)
  const [shouldPopupTexturesUpdate, setShouldPopupTexturesUpdate] = useState(false)
  const [settings, setSettings] = useState(defaultSettings)
  const [vrPasscode, setVrPasscode] = useState("")
  const { muted, muteLockedByModerator, toggleMute } = useContext(MicContext)
  const { socket, vrSettings, addRoomMessage } = useContext(MediaContext)
  const pressedButtons = useRef([])
  const [showImmertecEmployees, setShowImmertecEmployees] = useState(false)

  useEffect(() => {
    if (!socket.current) {
      return
    }

    const updateSettings = (settings) => {
      setSettings((prevSettings) => ({ ...prevSettings, ...settings }))
    }

    socket.current.on("room-joined", ({ settings }) => {
      logger.debug("Settings:", settings)
      updateSettings(settings)
    })

    socket.current.on("settings", updateSettings)
  }, [socket.current])

  useEffect(() => {
    if (vrSettings) {
      setSettings((prev) => ({
        ...prev,
        vr: {
          ...prev.vr,
          ...vrSettings,
        },
      }))
    }
  }, [vrSettings])

  const toggleImmertecEmployees = () => {
    setShowImmertecEmployees((prevState) => !prevState)
  }

  const toggleOverlay = () => {
    setOverlayVisible((prevState) => !prevState)
  }

  const resetOverlay = () => {
    setShouldResetOverlay(true)
  }

  const toggleAttendees = () => {
    setAttendeesVisible((prevState) => !prevState)
  }

  const setIdle = useCallback((value) => {
    setControllersIdle(value)
  })

  const updatePopupTextures = () => {
    setShouldPopupTexturesUpdate(true)
  }

  const onButtonsPressedUpdate = (buttons) => {
    const gripButton = -1 // index of the Grip button in most gamepads
    if (buttons.includes(gripButton) && muted) {
      if (muteLockedByModerator) {
        addRoomMessage(
          {
            id: new Date().getTime(),
            message: "You were muted by moderator. You cannot unmute yourself.",
            kind: "warning",
          },
          5 * 1000,
        )
      } else {
        toggleMute()
      }
    }
    if (!buttons.includes(gripButton) && pressedButtons.current.includes(gripButton) && !muted) {
      toggleMute()
    }

    pressedButtons.current = buttons
  }

  return (
    <UIContext.Provider
      value={{
        overlayVisible,
        resetOverlay,
        toggleOverlay,
        shouldResetOverlay,
        setShouldResetOverlay,
        attendeesVisible,
        toggleAttendees,
        controllersIdle,
        setControllersIdle: setIdle,
        onButtonsPressedUpdate,
        popup,
        setPopup,
        updatePopupTextures,
        shouldPopupTexturesUpdate,
        setShouldPopupTexturesUpdate,
        settings,
        setSettings,
        showSettings,
        toggleSettings: () => setShowSettings(!showSettings),
        vrPasscode,
        setVrPasscode,
        showImmertecEmployees,
        toggleImmertecEmployees,
      }}
    >
      {children}
    </UIContext.Provider>
  )
}

export default UIContainer
