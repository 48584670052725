import React, { useState } from "react"
import { Flex, Box, Image, Text, Button } from "rebass"
import styled from "styled-components"
import { colors } from "../etc/theme"
import headset from "../../images/headset.png"
import { usePostEventUserNpsActionMutation } from "../libs/api/api.generated"
import { auth } from "../etc/firebase"
import Loader from "./Loader"

type WrapperProps = {
  children: React.ReactNode
  width?: string
}

type Props = {
  eventId: string
  onClose: () => void
}

const ScoreBox = styled(Box)`
  width: 50px;
  padding: 14px 0;
  border-radius: 10px;
  border: 1px solid ${colors.gray5};
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  :hover {
    background: ${colors.blue};
    border-color: ${colors.blue};
  }
`

const Wrapper: React.FC<WrapperProps> = ({ children, width }) => {
  return (
    <Flex
      width={width || "480px"}
      height="100vh"
      margin="0 auto"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      css={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0 }}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        minHeight="200px"
        p="20px 0"
        textAlign="center"
        css={{ background: "#243746", borderRadius: "16px" }}
      >
        {children}
      </Flex>
    </Flex>
  )
}

export const ThankYouFeedback: React.FC<Props> = ({ onClose, eventId }) => {
  const [showPoll, setShowPoll] = useState(true)
  const [sendScore, { isLoading }] = usePostEventUserNpsActionMutation()

  const onScore = async (score: number) => {
    const userId = auth.currentUser?.uid ?? ""
    await sendScore({ eventId, userId, body: { nps: { answer: score } } })
    setShowPoll(false)
  }

  return (
    <>
      {isLoading && (
        <Wrapper>
          <Loader />
        </Wrapper>
      )}

      {/* Feedback pool. */}
      {showPoll && !isLoading && (
        <Wrapper width="700px">
          <Text p="30px 80px" fontSize="22px" fontWeight={600}>
            How likely are you to recommend Immertec to a friend or colleague?
          </Text>
          <Flex width="100%" p="0 40px" justifyContent="space-between">
            <Text fontWeight={600}>Not at all likely</Text>
            <Text fontWeight={600}>Extremely likely</Text>
          </Flex>
          <Flex width="100%" p="5px 40px" justifyContent="space-between">
            {[...Array(11)].map((_, score) => (
              <ScoreBox key={`score-${score}`} onClick={() => onScore(score)}>
                {score}
              </ScoreBox>
            ))}
          </Flex>
        </Wrapper>
      )}

      {/* Headset return reminder. */}
      {!showPoll && !isLoading && (
        <Wrapper>
          <Image src={headset} />
          <Box p="20px 40px" css={{ lineHeight: "30px" }}>
            <Text fontSize="24px" fontWeight={600}>
              Thanks for participating
            </Text>
            <Text fontSize="18px">
              Please, follow instructions in the <b>For Headset Return</b> envelope to{" "}
              <Text as="span" color={colors.primary}>
                return your headset
              </Text>
              .
            </Text>
          </Box>
          <Button fontSize="20px" p="14px 30px" onClick={onClose}>
            Continue
          </Button>
        </Wrapper>
      )}
    </>
  )
}
