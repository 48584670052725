import { enums } from "../libs/api"
/**
 * Retrieves device's unique id from local storage generated earlier
 *
 * @param {Boolean} force - Whether to force uuid generation.
 * @returns {string}
 */
export const getDeviceUUID = (force = false) => {
  let uuid = localStorage.getItem("deviceId")
  if (!uuid || force) {
    uuid = uuidv4()
    localStorage.setItem("deviceId", uuid)
  }

  return uuid
}

/**
 * Checks if the given string is an Object (can be parsed as it)
 *
 * @param string
 * @returns {boolean}
 */
export const isObjectString = (string) => {
  try {
    JSON.parse(string)
    return true
  } catch (e) {
    return false
  }
}

/**
 * RFC4122 version 4 compliant UUID
 */
export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  )
}

/**
 * Determines whether the event time is overlapped with current time or the event time is today.
 *
 * @param {object} event
 * @returns {boolean}
 */
export const isActiveEvent = (event) => {
  const now = Date.now()
  const today = new Date().toDateString()
  return (
    (event.startTime < now && event.endTime > now) ||
    new Date(event.startTime).toDateString() === today ||
    new Date(event.endTime).toDateString() === today ||
    event.status === enums.EventStatus.LIVE
  )
}
