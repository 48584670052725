import React from "react"
import theme from "../../data/canvasTheme"
import { Group, Rect, Text } from "react-konva"

const settings = {
  width: 100,
  height: 45,
  padding: 10,
  margin: 20,
  radius: 5,
}

const PopupButtons = ({ popup, setPopup, updateTextures }) => {
  const popupButtons = popup.buttons || []
  const buttons = popupButtons.concat({
    title: popup.closeTitle,
    onClick: () => setPopup((prevPopup) => ({ ...prevPopup, show: false })),
  })
  const btnStartX = popup.width / 2 - (buttons.length * (settings.width + settings.margin) - settings.margin) / 2

  const changeBgColor = (e, color) => {
    e.currentTarget.fill(color)
    updateTextures()
  }

  const getButtonElement = ({ button, i }) => {
    const btnX = btnStartX + settings.width * i + settings.margin * i
    const btnY = popup.height - popup.padding - settings.height
    button.bg = button.bg || theme.color.blue.hex
    button.bgHover = button.bgHover || theme.color.lightBlue.hex

    return (
      <Group key={i}>
        <Rect
          width={settings.width}
          height={settings.height}
          x={btnX}
          y={btnY}
          cornerRadius={settings.radius}
          fill={button.bg}
          onMouseOver={(e) => changeBgColor(e, button.bgHover)}
          onMouseOut={(e) => changeBgColor(e, button.bg)}
          onMouseUp={button.onClick}
        />
        <Text
          width={settings.width - settings.padding * 2}
          height={settings.height - settings.padding * 2}
          x={btnX + settings.padding}
          y={btnY + settings.padding}
          align="center"
          verticalAlign="middle"
          fontFamily="Gilroy"
          fontSize={16}
          fill="white"
          text={button.title}
          listening={false}
        />
      </Group>
    )
  }

  return (
    <Group>
      {buttons.map((button, i) => {
        return getButtonElement({ button, i })
      })}
    </Group>
  )
}

export default PopupButtons
