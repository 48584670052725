import React, { useContext, useEffect, useState, useCallback } from "react"
import { Flex, Text, Image, Box } from "rebass"
import { AuthContext } from "../../containers/Auth"
import { UserContext } from "../../containers/User"
import Loader from "../Loader"
import Events from "./Events"
import logo from "../../../images/logo-small.png"
import noEvents from "../../../images/no-events.png"
import refreshSrc from "../../../images/icons/svg/refresh.svg"
import { enums, useListEventsQuery } from "../../libs/api"
import { Button, Modal } from "../../libs/ui"
import { colors } from "../../etc/theme"
import { auth } from "../../etc/firebase"

const eventActiveStatuses = [
  enums.EventStatus.PATIENT_ARRIVED,
  enums.EventStatus.PATIENT_PREPARED,
  enums.EventStatus.TIMED_OUT,
  enums.EventStatus.LIVE,
]

const getSortedEvents = (events) =>
  events
    .sort((a, b) => a.startTime - b.startTime)
    .sort((a, b) => {
      return eventActiveStatuses.includes(a.status) && !eventActiveStatuses.includes(b.status)
        ? -1
        : !eventActiveStatuses.includes(a.status) && eventActiveStatuses.includes(b.status)
        ? 1
        : 0
    })

const Lobby = () => {
  const { user, error, setError, logout } = useContext(AuthContext)
  const { passcodeEventId } = useContext(UserContext)
  const [closestEvents, setClosestEvents] = useState([])
  const [toggleModal, setToggleModal] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)

  const {
    data: events,
    isLoading,
    refetch,
    isFetching,
  } = useListEventsQuery({ filter: { self: true } }, { pollingInterval: 7000 })

  const handleToggleModal = useCallback(() => {
    setToggleModal(!toggleModal)
  }, [toggleModal])

  const onRefreshEvents = async () => {
    await auth.currentUser.getIdToken(true)
    setIsRefreshing(true)
    refetch()
  }

  useEffect(() => {
    if (!isFetching) {
      setIsRefreshing(false)
    }
  }, [isFetching])

  useEffect(() => {
    if (!events?.length) return
    if (isLoading || isFetching) return

    let sortedEvents = []

    if (passcodeEventId) {
      const filteredEvent = events.filter((event) => event.id === passcodeEventId)

      if (!filteredEvent.length) {
        return setError("Cannot find event.")
      }

      sortedEvents = getSortedEvents(filteredEvent)
    } else {
      const d = new Date()
      const now = d.getTime()

      // We do not consider cancelled and past events (if they are not live).
      const filteredEvents = events.filter((event) => {
        const isLiveStatus = eventActiveStatuses.includes(event.status)

        const eventUser = event.users.find((u) => u.user === user.id)
        if (!eventUser || eventUser.registrationStatus !== enums.EventUserRegistrationStatus.ACCEPTED) {
          return false
        }
        return event.status !== enums.EventStatus.CANCELLED && (event.endTime > now || isLiveStatus)
      })

      sortedEvents = getSortedEvents(filteredEvents)
    }

    setClosestEvents(sortedEvents)
  }, [events, isLoading, isFetching, passcodeEventId])

  if (error) {
    return (
      <Flex height="100vh" flexDirection="column" alignItems="center" justifyContent="center">
        <Text fontSize={24}>{error}</Text>
        <Flex justifyItems="center" mt={50} css={{ gap: 20 }}>
          <Button onClick={onRefreshEvents}>Try again</Button>
          <Button variant="outline" onClick={logout}>
            Exit
          </Button>
        </Flex>
      </Flex>
    )
  }

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" width="100%" py={16} px={32}>
        <Image src={logo} alt="Immertec logo" width="50px" />

        <Text fontSize={24}>My Events</Text>

        <Flex justifyContent="end">
          <Text>
            {user.name.first} {user.name.last}
          </Text>
          &nbsp;|&nbsp;
          <Text onClick={handleToggleModal} css={{ textDecoration: "underline", cursor: "pointer" }}>
            Sign Out
          </Text>
        </Flex>
      </Flex>

      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="80vh"
        backgroundColor={colors.gray11}
      >
        {isLoading ? (
          <Text fontSize={24}>Loading event{passcodeEventId ? "" : "s"}...</Text>
        ) : (
          <>
            {closestEvents.length ? (
              <Events events={closestEvents} />
            ) : (
              <>
                <Box
                  width="100%"
                  flex={1}
                  css={{
                    background: `url(${noEvents}) no-repeat center`,
                    backgroundSize: "contain",
                  }}
                />
                <Text width={550} m="5px 0" textAlign="center" fontSize="18px" mt={50}>
                  No registered events found. Use the Immertec mobile app to register for new events.
                </Text>
              </>
            )}
            <Flex
              alignItems="center"
              color={colors.gray5}
              css={{ textDecoration: "underline", cursor: "pointer", gap: 10, ":hover": { color: colors.primary } }}
              onClick={onRefreshEvents}
            >
              <Image src={refreshSrc} /> Refresh
            </Flex>
          </>
        )}
      </Flex>

      {toggleModal && (
        <Modal
          title={
            passcodeEventId ? (
              <>Are you sure you want to exit the&nbsp;session?</>
            ) : (
              <>
                <Box mb={16} fontWeight="bold">
                  Are you sure?
                </Box>
                <Text fontSize="1.2rem">You will have to pair the headset again.</Text>
              </>
            )
          }
          buttons={
            <>
              <Button onClick={logout}>Yes</Button>
              <Button variant="secondary" onClick={handleToggleModal}>
                No
              </Button>
            </>
          }
        />
      )}

      {isRefreshing && !isLoading && (
        <>
          <Box
            bg={colors.gray13}
            css={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0, opacity: 0.95, zIndex: 9 }}
          />
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            css={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0, zIndex: 10 }}
          >
            <Image src={logo} mb={50} />
            <Loader />
            <Text fontSize={24} mt={50}>
              Refreshing events...
            </Text>
          </Flex>
        </>
      )}
    </>
  )
}

export default Lobby
