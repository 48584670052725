import React, { useContext, useEffect, useCallback } from "react"
import { Canvas } from "react-three-fiber"
import * as THREE from "three"
import { MediaContext } from "../../containers/Media"
import { useScene } from "../../containers/Scene"
import { useCanvasUI } from "../../containers/CanvasUI"
import { UIContext } from "../../containers/UI"
import { Controllable } from "../../containers/Controllable"
import Draggable from "../../containers/Draggable"
import SceneBridgeContainer from "../../containers/SceneBridge"
import Overlays from "./Overlays"
import VRVideo from "./VRVideo"
import CanvasUI from "./CanvasUI"
import UserList from "./UserList"
import Controllers from "./Controllers"
import Controls from "../OrbitControls"
import Popup from "./Popup"
import Settings from "./Settings"
import Performance from "./Performance"

const VRScene = () => {
  const { vrElement, overlayElements, connected, isStreamEnded, addRoomMessage, socket } = useContext(MediaContext)
  const [scene, dispatch] = useScene()

  const canvasUI = useCanvasUI()
  const {
    overlayVisible,
    controllersIdle,
    setControllersIdle,
    onButtonsPressedUpdate,
    popup,
    settings,
    showSettings,
    ...uiContext
  } = useContext(UIContext)

  const onCreated = (renderer) => {
    dispatch({ type: "setRenderer", payload: renderer })
    const { gl } = renderer
    gl.setClearColor(new THREE.Color("#232b3c"))
  }

  const onPerformanceMetrics = useCallback(
    (metrics) => {
      if (socket.current) {
        socket.current.emit("peer-stats", { ...metrics, type: "CLIENT_PERFORMANCE" })
      }
    },
    [socket.current],
  )

  useEffect(() => {
    if (scene.session && !connected) {
      if (isStreamEnded) {
        dispatch({ type: "exitXR" })
        return
      }
      addRoomMessage({ id: new Date().getTime(), message: "Connection issues, please wait...", kind: "info" })
    }
  }, [connected, isStreamEnded])

  return (
    <Canvas
      vr
      gl2
      colorManagement
      concurrent
      gl={{ xrCompatible: true }}
      onCreated={onCreated}
      camera={{ position: [0, 0, 3] }}
      style={{ display: connected ? "block" : "none" }}
    >
      <Controllable>
        <Draggable>
          <SceneBridgeContainer value={{ ui: uiContext }}>
            <Overlays
              elements={overlayElements}
              visible={overlayVisible}
              verticalPosition={settings.overlay.y}
              angle={settings.overlay.x}
              radius={7}
            />
          </SceneBridgeContainer>
          <UserList
            fromCanvas={canvasUI}
            visible={!controllersIdle}
            verticalPosition={1}
            angle={Math.PI / 3.5}
            radius={6}
            // TODO: Add not draggable considering object height.
            // notDraggableOnCoords={[0, 1, 0.46, 0.77]}
          />
          <Settings
            fromCanvas={canvasUI}
            visible={showSettings && !controllersIdle}
            verticalPosition={1}
            angle={showSettings ? Math.PI / 5.5 : -5}
            radius={6}
            notDraggableOnCoords={[0.47, 1, 0.1, 0.7]}
          />
          <CanvasUI id="messages" fromCanvas={canvasUI} position={[0, 2, -6]} />
          <Popup fromCanvas={canvasUI} visible={popup.show} position={popup.show ? [0, 1, -5] : [-10, 1, -5]} />
          <Controllers
            session={scene.session}
            onControllersIdle={setControllersIdle}
            onButtonsPressedUpdate={onButtonsPressedUpdate}
            visible={!controllersIdle}
          />
        </Draggable>
      </Controllable>
      <VRVideo
        element={vrElement}
        hShift={settings.vr.hShift}
        vShift={settings.vr.vShift}
        fov={settings.vr.fov}
        swap={settings.vr.swap}
      />
      <Performance onMetrics={onPerformanceMetrics} />
      <Controls enabled={!scene.xr} enableDamping rotateSpeed={0.3} dampingFactor={0.1} />
      <ambientLight intensity={0.1} />
      <spotLight intensity={0.3} position={[30, 30, 50]} angle={0.2} penumbra={1} castShadow />
    </Canvas>
  )
}

VRScene.whyDidYouRender = false

export default React.memo(VRScene)
