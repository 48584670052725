import React from "react"
import { useFrame } from "react-three-fiber"

/**
 * Gathers some 3D scene performance metrics. NOTE: Can be used inside three.js Canvas context only.
 *
 * @param {function} onMetrics - A callback function.
 * @returns {null} - Empty React component for now.
 */
const Performance = ({ onMetrics }) => {
  let lastFPSMeasureTime = performance.now()
  let lastFPSUpdateTime = Date.now()

  const updateMetrics = ({ fps, memory }) => {
    const now = Date.now()
    if (now - lastFPSUpdateTime >= 1000) {
      onMetrics({
        timestamp: now,
        fps,
        memory,
        // TODO: Implement CPU and GPU metrics (some ideas: https://github.com/munrocket/gl-bench/blob/master/src/index.js).
        cpu: null,
        gpu: null,
      })
      lastFPSUpdateTime = now
    }
  }

  useFrame(() => {
    const now = performance.now()
    // NOTE: Memory metrics is not supported by Firefox.
    const memory = performance.memory
    const fps = Math.round(1 / ((now - lastFPSMeasureTime) / 1000))
    updateMetrics({
      fps,
      ...(memory && { memory: { used: memory.usedJSHeapSize, available: memory.jsHeapSizeLimit } }),
    })
    lastFPSMeasureTime = now
  })

  return null
}

export default React.memo(Performance)
