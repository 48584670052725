import React, { useContext, useCallback } from "react"
import { UIContext } from "../../containers/UI"
import { AuthContext } from "../../containers/Auth"
import { Box, Text, Flex, Image } from "rebass"
import { colors } from "../../etc/theme"
import logo from "../../../images/logo-small.png"
import Loader from "../Loader"
import { Input } from "./Input"
import { KeypadButton } from "./KeypadButton"
import { VR_PASSCODE_LENGTH } from "../../constants"

const EventCodeForm = ({ isLoading }) => {
  const { setAuthType } = useContext(AuthContext)
  const { vrPasscode, setVrPasscode } = useContext(UIContext)
  // Generate empty numbers array based on the number of input fields required.
  const inputArray = Array.apply(null, { length: VR_PASSCODE_LENGTH }).map(Number.call, Number)

  const handleKeypadEntry = useCallback(
    (key) => () => {
      if (vrPasscode.length <= inputArray.length) {
        setVrPasscode(`${vrPasscode}${key}`)
      }
    },
    [vrPasscode],
  )

  const handleBackspace = useCallback(() => {
    if (vrPasscode.length) {
      setVrPasscode(vrPasscode.substring(0, vrPasscode.length - 1))
    }
  }, [vrPasscode])

  const handleCancel = () => {
    setAuthType(null)
    setVrPasscode("")
  }

  return (
    <Flex width="80%" minWidth={600} height="calc(100vh - 100px)" flexDirection="column" alignItems="center" mx="auto">
      <Box
        width="100%"
        minWidth={700}
        maxWidth={1024}
        bg={colors.gray12}
        p={50}
        mt={50}
        mx="auto"
        textAlign="center"
        css={{ borderRadius: 48 }}
      >
        <Image src={logo} width={82} backgroundSize="contain" />
        <Text fontSize={20} color={colors.white} mt={25}>
          Enter your Event Passcode here.
        </Text>
        <Text fontSize={20} color={colors.gray5} mt={15}>
          Look for an email in your inbox from Immertec.
        </Text>

        <Flex width="fit-content" mt={40} mx="auto">
          {inputArray.map((i) => {
            const id = `input${i.toString()}`
            return <Input key={id} id={id} name={id} value={vrPasscode.charAt(i)} highlight={i === vrPasscode.length} />
          })}
        </Flex>

        <Box width={300} mt={50} mx="auto">
          <Flex justifyContent="center">
            <KeypadButton onClick={handleKeypadEntry(1)}>1</KeypadButton>
            <KeypadButton onClick={handleKeypadEntry(2)}>2</KeypadButton>
            <KeypadButton onClick={handleKeypadEntry(3)}>3</KeypadButton>
          </Flex>
          <Flex justifyContent="center">
            <KeypadButton onClick={handleKeypadEntry(4)}>4</KeypadButton>
            <KeypadButton onClick={handleKeypadEntry(5)}>5</KeypadButton>
            <KeypadButton onClick={handleKeypadEntry(6)}>6</KeypadButton>
          </Flex>
          <Flex justifyContent="center">
            <KeypadButton onClick={handleKeypadEntry(7)}>7</KeypadButton>
            <KeypadButton onClick={handleKeypadEntry(8)}>8</KeypadButton>
            <KeypadButton onClick={handleKeypadEntry(9)}>9</KeypadButton>
          </Flex>
          <Flex justifyContent="end">
            <KeypadButton onClick={handleKeypadEntry(0)}>0</KeypadButton>
            <KeypadButton dark onClick={handleBackspace}>
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.04843 5.85422C8.72356 5.17909 9.63924 4.7998 10.594 4.7998H17.9999C19.9881 4.7998 21.5999 6.41158 21.5999 8.3998V15.5998C21.5999 17.588 19.9881 19.1998 17.9999 19.1998H10.594C9.63924 19.1998 8.72356 18.8205 8.04843 18.1454L2.75137 12.8483C2.52633 12.6233 2.3999 12.3181 2.3999 11.9998C2.3999 11.6815 2.52633 11.3763 2.75137 11.1513L8.04843 5.85422ZM12.8484 8.75128C12.3798 8.28265 11.62 8.28265 11.1514 8.75128C10.6827 9.21991 10.6827 9.9797 11.1514 10.4483L12.7028 11.9998L11.1514 13.5513C10.6827 14.0199 10.6827 14.7797 11.1514 15.2483C11.62 15.717 12.3798 15.717 12.8484 15.2483L14.3999 13.6969L15.9514 15.2483C16.42 15.717 17.1798 15.717 17.6484 15.2483C18.1171 14.7797 18.1171 14.0199 17.6484 13.5513L16.097 11.9998L17.6484 10.4483C18.1171 9.9797 18.1171 9.21991 17.6484 8.75128C17.1798 8.28265 16.42 8.28265 15.9514 8.75128L14.3999 10.3027L12.8484 8.75128Z"
                  fill="white"
                />
              </svg>
            </KeypadButton>
          </Flex>
        </Box>
      </Box>

      <Flex alignItems="center" color={colors.gray5} py={50} onClick={handleCancel} css={{ cursor: "pointer" }}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.70711 14.7071C9.31658 15.0976 8.68342 15.0976 8.2929 14.7071L4.29289 10.7071C3.90237 10.3166 3.90237 9.68342 4.29289 9.29289L8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289C10.0976 5.68342 10.0976 6.31658 9.70711 6.70711L7.41421 9L15 9C15.5523 9 16 9.44772 16 10C16 10.5523 15.5523 11 15 11H7.41421L9.70711 13.2929C10.0976 13.6834 10.0976 14.3166 9.70711 14.7071Z"
            fill={colors.gray5}
          />
        </svg>
        &nbsp;Cancel
      </Flex>

      {isLoading ? (
        <Flex alignItems="center" height="100vh" css={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0 }}>
          <Box bg={colors.gray13} css={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0, opacity: 0.9 }} />
          <Loader />
        </Flex>
      ) : null}
    </Flex>
  )
}

export default EventCodeForm
