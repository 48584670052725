import React from "react"
import { Flex, Box, Text } from "rebass"
import { colors } from "../../etc/theme"

export const Modal = ({ buttons, title, ...rest }) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100vh"
      css={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0, zIndex: 999999 }}
      {...rest}
    >
      <Box
        bg={colors.gray13}
        css={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0, opacity: 0.95, zIndex: -1 }}
      />
      <Box width="50%" p={50} bg={colors.gray12} color={colors.white} css={{ borderRadius: 48 }} textAlign="center">
        {title ? <Text fontSize={24}>{title}</Text> : null}
        {buttons ? (
          <Flex justifyContent="center" alignItems="center" mt={50} css={{ gap: 20 }}>
            {buttons}
          </Flex>
        ) : null}
      </Box>
    </Flex>
  )
}
