import React, { useRef, useState, useEffect } from "react"
import { Flex, Text } from "rebass"
import { SoundFilled } from "@ant-design/icons"
import chime from "../../sounds/chime.ogg"

const SoundCheck = () => {
  const element = useRef(null)
  const [playing, setPlaying] = useState(false)

  const playSound = async () => {
    try {
      await element.current.play()
      setPlaying(true)
    } catch (e) {
      console.error(e)
    }
  }

  const onEnded = () => {
    setPlaying(false)
  }

  useEffect(() => {
    element.current.addEventListener("ended", onEnded)
    return () => {
      element.current.removeEventListener("ended", onEnded)
    }
  }, [])

  return (
    <>
      <Flex
        width="100px"
        height="100px"
        mx="auto"
        alignItems="center"
        justifyContent="center"
        fontSize="30px"
        bg={playing ? "blue" : "#333333"}
        sx={{
          borderRadius: "50%",
          cursor: "pointer",
        }}
        onClick={playSound}
      >
        <Flex flexDirection="column">
          <SoundFilled />
          <Text mt="6px" fontSize="13px">
            Test Audio
          </Text>
        </Flex>
      </Flex>
      <audio ref={element} preload="auto">
        <source src={chime} type="audio/ogg" />
      </audio>
    </>
  )
}

export default SoundCheck
