import React, { useMemo } from "react"
import Overlay from "./Overlay"

const settings = {
  spacing: 0.25,
}

const Overlays = ({ elements, radius = 6, height = 2, verticalPosition, angle = 0, thetaLength = 0.5, ...props }) => {
  const overlays = useMemo(() => {
    return elements.map((element, index) => {
      const y = (index + verticalPosition) * (height + settings.spacing) - height + settings.spacing
      const defaultTransform = {
        verticalPosition: y,
        thetaLength: thetaLength,
        angle,
      }

      return {
        ...defaultTransform,
        name: `overlay-${index}`,
        element,
        height,
        radius,
      }
    })
  }, [elements, verticalPosition, angle])

  return (
    <group name="overlays" {...props}>
      {overlays.map((mio, index) => {
        return <Overlay key={`mio-${index}`} {...mio} />
      })}
    </group>
  )
}

export default Overlays
