import { lighten } from "polished"

export const colors = {
  white: "#fff",
  dark: "#1e282f",
  darkblue: "#283745",
  lightblue: "#7198D0",
  blue: "#3b6cb3",
  green: "#15b937",
  lightgreen2: "#c4f2e4",
  red: "#e11932",
  yellow: "#ff9502",
  lightyellow: "#ffe1b8",
  gray1: "#FBFCFD",
  gray2: "#F6F7FB",
  gray3: "#E4E6ED",
  gray4: "#BDC2C7",
  gray5: "#929BA3",
  gray6: "#6F7E8B",
  gray7: "#5A6874",
  gray8: "#2D3F4E",
  gray9: "#243746",
  gray10: "#21303D",
  gray11: "#1B2830",
  gray12: "#141D24",
  gray13: "#0b0e12",
  primary: "#47d7ac",
  primaryHover: "#27B48A",
}

export default {
  colors,
  fonts: {
    body: "Gilroy, sans-serif",
    heading: "Gilroy, sans-serif",
  },
  fontSizes: [14, 16, 18, 22, 26, 32],
  fontWeights: {
    body: 400,
    bold: 700,
  },
  sizes: {
    avatar: 35,
  },
  radii: {
    small: 4,
    medium: 8,
    big: 12,
    max: "100%",
  },
  shadows: {
    buttonOutside: "0 2px 8px rgba(0,0,0, .35)",
    buttonInside: "inset 1px 2px 2px rgba(0,0,0, .35) !important",
    none: "none !important",
  },
  variants: {
    avatar: {
      width: "avatar",
      height: "avatar",
      borderRadius: "max",
    },
    link: {
      color: "blue", // colors.blue
      textDecoration: "underline",
      ":hover": {
        textDecoration: "none",
      },
    },
  },
  buttons: {
    success: {
      borderRadius: "2px",
      bg: "green", // colors.green
      fontFamily: "Gilroy",
      color: "white",
      ":hover": { bg: lighten(0.1, colors.green) },
      ":disabled": { bg: lighten(0.2, colors.green) },
    },
    primary: {
      borderRadius: "2px",
      bg: "blue",
      fontFamily: "Gilroy",
      color: "white",
      ":hover": { bg: lighten(0.1, colors.blue) },
      ":disabled": { bg: lighten(0.2, colors.darkblue) },
    },
    secondary: {
      borderRadius: "2px",
      bg: colors.gray6,
      fontFamily: "Gilroy",
      ":hover": { bg: lighten(0.1, colors.gray5) },
      ":disabled": { bg: lighten(0.2, colors.gray7) },
    },
    dark: {
      borderRadius: "2px",
      bg: "gray12",
      fontFamily: "Gilroy",
      ":hover": { bg: lighten(0.1, colors.gray12) },
      ":disabled": { bg: lighten(0.2, colors.gray12) },
    },
    danger: {
      borderRadius: "2px",
      bg: "red",
      fontFamily: "Gilroy",
      color: "white",
      ":hover": { bg: lighten(0.1, colors.red) },
      ":disabled": { bg: lighten(0.2, colors.red) },
    },
    warning: {
      borderRadius: "2px",
      bg: "yellow",
      fontFamily: "Gilroy",
      color: "white",
      ":hover": { bg: lighten(0.1, colors.yellow) },
      ":disabled": { bg: lighten(0.2, colors.yellow) },
    },
  },
}
