import React, { useContext, useEffect, useRef, useState } from "react"
import { MicContext } from "../containers/Mic"
import { MediaContext } from "../containers/Media"
import { Box, Flex, Text } from "rebass"
import styled from "styled-components"
import { AudioFilled, AudioMutedOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons"

const AudioAnalyserConfig = {
  width: "100px",
  height: "100px",
  speakDetectThreshold: 4,
  speakDetectTime: 500,
  speakDetectInterval: 1000 * 30,
}

const AnalyserIconContainer = styled(Flex)`
  flex-direction: column;
  position: absolute;
  top: 0;
  width: ${AudioAnalyserConfig.width};
  height: ${AudioAnalyserConfig.height};
  align-items: center;
  justify-content: center;
`

const AudioAnalyser = ({ variant = "levels", processAudio = true }) => {
  const levelsElement = useRef(null)
  const isMuted = useRef(false)
  const detectSpeakingTimeout = useRef(null)
  const lastSpeakDetectTime = useRef(0)
  const { granted, micTrack, muted, toggleMute } = useContext(MicContext)
  const { gestured, addRoomMessage } = useContext(MediaContext)
  const [audioContext, setAudioContext] = useState(null)

  const onAudioProcess = (e) => {
    // much of this is from https://github.com/webrtc/samples/blob/gh-pages/src/content/getusermedia/volume/js/soundmeter.js
    const input = e.inputBuffer.getChannelData(0)
    let i
    let sum = 0.0
    for (i = 0; i < input.length; ++i) {
      sum += input[i] * input[i]
    }
    let micValue = Math.sqrt(sum / input.length) * 1000
    micValue = micValue > 100 ? 100 : micValue
    if (levelsElement.current) {
      levelsElement.current.style.height = isMuted.current ? 0 : micValue + "%"
    }

    const time = new Date().getTime()
    if (isMuted.current && time - lastSpeakDetectTime.current > AudioAnalyserConfig.speakDetectInterval) {
      if (micValue >= AudioAnalyserConfig.speakDetectThreshold && !detectSpeakingTimeout.current) {
        detectSpeakingTimeout.current = setTimeout(() => {
          lastSpeakDetectTime.current = time
          addRoomMessage(
            {
              id: new Date().getTime(),
              message: "Are you trying to speak? You are muted!",
              kind: "info",
            },
            3 * 1000,
          )
        }, AudioAnalyserConfig.speakDetectTime)
      }
      if (micValue < AudioAnalyserConfig.speakDetectThreshold && detectSpeakingTimeout.current) {
        clearTimeout(detectSpeakingTimeout.current)
        detectSpeakingTimeout.current = null
      }
    }
  }

  useEffect(() => {
    let script = null
    if (processAudio && micTrack?.readyState === "live" && granted && audioContext !== null) {
      script = audioContext.createScriptProcessor(2048, 1, 1)
      script.addEventListener("audioprocess", onAudioProcess)
      const mic = audioContext.createMediaStreamSource(new MediaStream([micTrack]))
      mic.connect(script)
      script.connect(audioContext.destination)
    }

    return () => {
      if (script) script.removeEventListener("audioprocess", onAudioProcess)
    }
  }, [micTrack, granted, audioContext])

  useEffect(() => {
    if (gestured) {
      const AudioContext = window.AudioContext || window.webkitAudioContext || null
      if (AudioContext) {
        setAudioContext(new AudioContext())
      }
    }
  }, [gestured])

  useEffect(() => {
    isMuted.current = muted
  }, [muted])

  return (
    <Box sx={{ position: "relative" }} width={AudioAnalyserConfig.width} height={AudioAnalyserConfig.height} mx="auto">
      <Flex
        bg="#333333"
        color="white"
        width={AudioAnalyserConfig.width}
        height={AudioAnalyserConfig.height}
        style={{
          position: "absolute",
          top: 0,
          overflow: "hidden",
          borderRadius: "50%",
        }}
      >
        <Box
          ref={levelsElement}
          bg="#adadad"
          style={{
            width: AudioAnalyserConfig.width,
            alignSelf: "flex-end",
          }}
        />

        {variant === "permission" && (
          <AnalyserIconContainer bg={granted && "green"} fontSize="32px">
            <AudioFilled />
            <Box mt="10px" fontSize="18px">
              {granted ? <CheckOutlined /> : <CloseOutlined />}
            </Box>
          </AnalyserIconContainer>
        )}

        {variant === "levels" && (
          <AnalyserIconContainer onClick={toggleMute} fontSize="32px" sx={{ cursor: "pointer" }}>
            {!muted ? <AudioFilled /> : <AudioMutedOutlined />}
            {muted && (
              <Text mt="6px" fontSize="13px">
                Muted
              </Text>
            )}
          </AnalyserIconContainer>
        )}
      </Flex>
    </Box>
  )
}

export default AudioAnalyser
