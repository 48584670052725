import { mappers } from "./mappings"

import { enums } from "./enums"
import type * as generated from "./api.generated"
import type * as model from "./modelCommon"
import { LocationCategory } from "./api.generated"
import { ReadonlyCamelCaseDeep } from "./utils_types"

type Modify<T, R> = Omit<T, keyof R> & R

export type NormalizedEventWUsers = Modify<
  NormalizedEvent,
  { instructors: readonly NormalizedInstructor[]; users: readonly NormalizedUser[] }
>

export type NormalizedHeadsetRequest = Readonly<{
  id: string

  metadata?: generated.Metadata

  user: string
  events?: readonly string[]

  status?: generated.HeadsetRequestStatus
  type?: generated.HeadsetRequestDeviceType
  loanType?: generated.HeadsetRequestLoanType
  orderDate?: number
  userClassification?: generated.UserClassification
  expectedDeliveryDate?: number
  quantity?: number

  name?: model.UserName
  address?: model.Address
  trackingInfo?: model.TrackingInfo
  lateFeeAck?: number
  headsetsSerialNumbers?: string[]
}>

export type UserEventEdge = Readonly<{
  role: generated.EventUserRole
  registrationStatus: generated.EventUserRegistrationStatus
  headsetRequest?: NormalizedHeadsetRequest
  nps?: nps
}>
type nps = {
  answer: number
  answeredAt: string
}

export type NormalizedUserEvent = UserEventEdge & {
  event: string
}

export type NormalizedEventUser = UserEventEdge &
  Readonly<{
    user: string
  }>

export type NormalizedUserSpecialty = Readonly<{
  specialty: string
  certification: { status: generated.SpecialtyCertificationStatus }
  subspecialtyIds: readonly string[]
}>

export type NormalizedUserIndustry = Readonly<{
  industry: string
  jobTypeId?: string
}>

export type NormalizedUserOrganization = Readonly<{
  organization: string
  role: generated.OrganizationRole
}>

export type NormalizedEventSpecialty = Readonly<{
  specialty: string
  subspecialtyId: string | undefined
}>

export type NormalizedIds = Readonly<{
  id: string
  internalId: string
  hubspotId: string
}>

export type NormalizedResource = NormalizedIds &
  Readonly<{
    slug: string
  }>

export type NormalizedOrganization = Omit<NormalizedResource, "hubspotId"> &
  Readonly<{
    metadata?: generated.Metadata

    customerSuccessRep?: string

    name: string
    description: string
    status: generated.OrganizationStatus
    owner: model.UserInfo
    address: model.Address

    photo?: string
    coordinates?: model.Coordinates
    digitalFootprint?: model.DigitalFootprint
  }>

// NormalizedPartialUser is a partial version of NormalizedUser.
// Used in the invite trainee screen as we need to load everything,
// it allows to reduce the size of the state and speed up quite a lot as in
// that scenario, we need pretty much all the DB worth of entries but with only a few fields.
export type NormalizedPartialUser = Pick<NormalizedUser, "id" | "email" | "name" | "photo">

export type NormalizedUser = UserClaims &
  Readonly<{
    id: string

    metadata?: generated.Metadata

    organizations: readonly NormalizedUserOrganization[]
    specialties: readonly NormalizedUserSpecialty[]
    industry?: NormalizedUserIndustry
    events: readonly NormalizedUserEvent[]

    email: string
    name: model.UserName
    roles: generated.UserRole[]
    address?: model.Address
    photo?: string
    phone?: model.Phone
    classification?: generated.UserClassification

    assistant?: model.UserInfo
    preferences?: model.UserPreferences

    synced?: boolean
    acceptedTerms?: boolean
  }>

export type UserClaims = Readonly<{
  synced?: boolean
  emailVerified?: boolean
  lastLogin: number
  lastAuthRefresh: number
}>

export type NormalizedEvent = Omit<NormalizedResource, "hubspotId"> &
  Readonly<{
    metadata?: generated.Metadata

    organization: string
    specialty?: NormalizedEventSpecialty
    location?: string
    instructors: readonly string[]
    users: readonly NormalizedEventUser[]
    customerSuccessRep?: string

    guestInstructors: readonly model.GuestInstructor[]
    title: string
    internalId?: string
    description: string
    startTime: number
    endTime: number
    flexibleTime?: boolean
    privacy: generated.EventPrivacy
    status: generated.EventStatus

    seatCount?: number
    usersCount?: number
    photo?: string
    streamSettings?: model.StreamSettings
    type?: generated.EventType
    funding?: generated.EventFunding

    // Convenience fields, not coming from the API directly, computed locally.
    host?: string // Reference to the user with event role 'HOST'. // TODO: Remove this. we can now have multiple hosts or none.
    cancelled: boolean // status === 'CANCELLED'.
  }>

export type NormalizedInstructorSpecialty = Readonly<{
  specialty: string
  subspecialtyIds: readonly string[]
}>

export type NormalizedDemoMainVideoSettings = Readonly<{
  fov?: number
  verticalShift?: number
  horizontalShift?: number
}>

export type NormalizedDemoMainVideo = Readonly<{
  url: string
  settings: NormalizedDemoMainVideoSettings
}>

export type NormalizedDemoOverlay = Readonly<{
  url: string
  label?: string
}>

export type NormalizedDemoAttendee = Readonly<{
  name: string
  photo?: string
}>

export type NormalizedInstructor = NormalizedResource &
  Readonly<{
    metadata?: generated.Metadata

    organizations: readonly string[]
    locations: readonly string[]
    specialties: readonly NormalizedInstructorSpecialty[]

    name: model.UserName
    email: string
    description: string
    status: generated.InstructorStatus

    photo?: string
    phone?: model.Phone
    quote?: string
    practice?: string
    digitalFootprint?: model.DigitalFootprint
    display?: model.Display
  }>

export type NormalizedDemo = Readonly<{
  id: string

  organizations: readonly string[]
  instructors: readonly string[]

  guestInstructors: readonly model.GuestInstructor[]
  title: string
  description?: string
  mainVideo: NormalizedDemoMainVideo
  overlays: readonly NormalizedDemoOverlay[]
  attendees: readonly NormalizedDemoAttendee[]
  duration: number
  photo?: string
}>

export type NormalizedDemoWIncludes = Modify<
  NormalizedDemo,
  {
    organizations: readonly NormalizedOrganization[]
    instructors: readonly NormalizedInstructor[]
  }
>

export type NormalizedLocation = Omit<NormalizedResource, "hubspotId"> &
  Readonly<{
    metadata?: generated.Metadata

    instructors: readonly string[]

    name: string
    description: string
    address: model.Address
    category: LocationCategory
    status: generated.LocationStatus

    photo?: string
    coordinates?: model.Coordinates
    digitalFootprint?: model.DigitalFootprint
    display?: model.Display
  }>

export type NormalizedSpecialty = Readonly<{
  id: string

  name: string
  photo?: string

  subspecialties: readonly NormalizedSubspecialty[]
}>

export type NormalizedSubspecialty = Omit<NormalizedSpecialty, "subspecialties">

export type NormalizedIndustry = Readonly<{
  id: string

  name: string
  photo?: string

  jobTypes: readonly NormalizedJobType[]
}>

export type NormalizedJobType = Omit<NormalizedIndustry, "jobTypes">

export type NormalizedDevice = Readonly<{
  id: string

  serialNumber: string
  name: string

  user?: string

  pairingCode: string
  pairedAt: number
}>

export type NormalizedFcmToken = Readonly<{
  id: string

  token: string

  user: string
  device?: string
}>

export type NormalizedJoinCode = Readonly<{
  id: string

  meetingId?: number
  passcode: number
  expiration: number
}>

export type NormalizedPairing = Readonly<{
  id: string // ID == Pairing code.

  user?: string
  event?: string
  device: string

  code: string
}>

export type NormalizedImage = Readonly<{
  id: string
  name: string
  url: string
  tags: readonly string[]
  organization?: string
  public: boolean
}>

export type NormalizedConnectionReportEventUser = Readonly<{
  user: string
  eventRole: generated.EventUserRole
  startTime: number
  endTime: number
  totalConnectionTime: number
  totalDisconnectionTime: number
  totalDisconnections: number
}>

export type NormalizedConnectionReport = Readonly<{
  event: string
  startTime: number
  endTime: number
  totalConnectionTime: number
  totalDisconnectionTime: number
  totalDisconnections: number
  eventUsers: readonly NormalizedConnectionReportEventUser[]
}>

export type NormalizedMetadata = ReadonlyCamelCaseDeep<generated.Metadata>

export type NormalizedStreamServer = ReadonlyCamelCaseDeep<generated.StreamServer>

export type NormalizedCloudXRServer = ReadonlyCamelCaseDeep<generated.CxrServer>

const normalizeJobType = (data: Partial<generated.Industry>): NormalizedJobType => {
  if (!data.id) {
    throw new Error("missing jobtype id")
  }

  return {
    id: data.id,
    name: data.name ?? "",
    photo: data.photo,
  }
}

const normalizeIndustry = (data: Partial<generated.Industry>): NormalizedIndustry => {
  if (!data.id) {
    throw new Error("missing industry id")
  }

  return {
    id: data.id,
    name: data.name ?? "",
    photo: data.photo,

    jobTypes: Object.values(data.children ?? {}).map(normalizeJobType),
  }
}

const normalizeSubspecialty = (data: Partial<generated.Specialty>): NormalizedSubspecialty => {
  if (!data.id) {
    throw new Error("missing subspecialty id")
  }

  return {
    id: data.id,
    name: data.name ?? "",
    photo: data.photo,
  }
}

const normalizeSpecialty = (data: Partial<generated.Specialty>): NormalizedSpecialty => {
  if (!data.id) {
    throw new Error("missing specialty id")
  }

  return {
    id: data.id,
    name: data.name ?? "",
    photo: data.photo,

    subspecialties: Object.values(data.children ?? {}).map(normalizeSubspecialty),
  }
}

const normalizeOrganization = (data: Partial<generated.Organization>): NormalizedOrganization => {
  if (!data.organization_id) {
    throw new Error("missing organization_id")
  }
  return {
    id: data.organization_id,
    internalId: data.internal_id ?? "",
    slug: data.slug ?? "",

    customerSuccessRep: data.cs_rep_user_id,

    name: data.name ?? "",
    description: data.description ?? "",
    status: data.status ?? enums.OrganizationStatus.INACTIVE,

    owner: mappers.normalizeUserInfo(data.owner),
    address: mappers.mustNormalizeAddress(data.address),
    coordinates: mappers.normalizeCoordinates(data.coordinates),
    digitalFootprint: mappers.normalizeDigitalFootprint(data.digital_footprint),
    photo: data.photo,
  }
}

const normalizeInstructor = (data: Partial<generated.Instructor>): NormalizedInstructor => {
  if (!data.instructor_id) {
    throw new Error("missing instructor_id")
  }

  return {
    id: data.instructor_id,
    internalId: data.internal_id ?? "",
    slug: data.slug ?? "",
    hubspotId: data.hs_instructor_id ?? "",

    organizations: data.organization_ids ?? [],

    locations: data.location_ids ?? [],

    specialties: Object.entries(data.instructor_specialties ?? {}).map(([id, elem]) => ({
      specialty: id,
      certification: {
        status: elem.certification?.status ?? enums.SpecialtyCertificationStatus.UNKNOWN,
      },
      subspecialtyIds: elem.child_ids ?? [],
    })),

    email: data.email ?? "",
    status: data.status ?? enums.InstructorStatus.INACTIVE,
    description: data.description ?? "",

    name: mappers.normalizeUserName(data.name),
    digitalFootprint: mappers.normalizeDigitalFootprint(data.digital_footprint),
    display: mappers.normalizeDisplay(data.display),

    quote: data.quote,
    practice: data.practice,
    photo: data.photo,
    phone: mappers.normalizePhone(data.phone),
  }
}

const normalizeDemo = (data: generated.Demo): NormalizedDemo => {
  if (!data.demo_id) {
    throw new Error("missing demo_id")
  }

  return {
    id: data.demo_id,
    organizations: data.organization_ids,
    title: data.title,
    description: data.description,
    instructors: data.instructor_ids ?? [],
    guestInstructors: (data.guest_instructors ?? []).map((elem) => ({
      email: elem.email,
      name: mappers.normalizeUserName(elem.name),
    })),
    mainVideo: mappers.normalizeDemoMainVideo(data.main_video),
    overlays: data.overlays ?? [],
    attendees: data.attendees ?? [],

    duration: data.duration ?? 0,
    photo: data.photo,
  }
}

const normalizeLocation = (data: Partial<generated.Location>): NormalizedLocation => {
  if (!data.location_id) {
    throw new Error("missing location_id")
  }

  return {
    id: data.location_id,
    internalId: data.internal_id ?? "",
    slug: data.slug ?? "",

    instructors: data.instructor_ids ?? [],

    name: data.name ?? "",
    description: data.description ?? "",
    category: data.category ?? enums.LocationCategory.UNKNOWN,
    status: data.status ?? enums.LocationStatus.UNAVAILABLE,

    address: mappers.mustNormalizeAddress(data.address),
    coordinates: mappers.normalizeCoordinates(data.coordinates),
    digitalFootprint: mappers.normalizeDigitalFootprint(data.digital_footprint),
    display: mappers.normalizeDisplay(data.display),
    photo: data.photo,
  }
}

const normalizeUser = (data: Partial<generated.User>): NormalizedUser => {
  if (!data.user_id) {
    throw new Error("missing user_id")
  }

  return {
    id: data.user_id,

    metadata: data.metadata,

    industry: !data.industry_id ? undefined : { industry: data.industry_id, jobTypeId: data.job_type_id },

    organizations: Object.entries(data.user_organizations ?? {}).map(([organizationId, userOrg]) => ({
      organization: organizationId,
      role: userOrg.org_role ?? enums.OrganizationRole.UNKNOWN,
    })),

    specialties: Object.entries(data.user_specialties ?? {}).map(([id, elem]) => ({
      specialty: id,
      certification: {
        status: elem.certification?.status ?? enums.SpecialtyCertificationStatus.UNKNOWN,
      },
      subspecialtyIds: elem.child_ids ?? [],
    })),

    events: Object.entries(data.user_events ?? {}).map(([id, elem]) => ({
      event: id,
      registrationStatus: elem.registration_status ?? enums.EventUserRegistrationStatus.UNKNOWN,
      role: elem.role ?? enums.EventUserRole.UNKNOWN,
      ...elem.headset_request,
    })),

    email: data.email ?? "",
    roles: !data.user_role ? [] : (data.user_role.split(",") as generated.UserRole[]),
    name: mappers.normalizeUserName(data.name),
    phone: mappers.normalizePhone(data.phone),
    address: mappers.normalizeAddress(data.address),
    preferences: mappers.normalizeUserPreferences(data.preferences),
    assistant: data.assistant && mappers.normalizeUserInfo(data.assistant),
    photo: data.photo,
    classification: data.classification,

    synced: data.synced,
    acceptedTerms: data.accepted_terms,
    emailVerified: data.email_verified,
    lastLogin: !data.last_login ? 0 : new Date(data.last_login).getTime(),
    lastAuthRefresh: !data.last_auth_refresh ? 0 : new Date(data.last_auth_refresh).getTime(),
  }
}

const normalizeEvent = (data: Partial<generated.Event>): NormalizedEvent => {
  if (!data.event_id) {
    throw new Error("missing event_id")
  }
  if (!data.organization_id) {
    throw new Error("missing organization_id")
  }

  const users = Object.entries(data.event_users ?? {}).map(([id, elem]) => normalizeEventUser(id, elem))
  const host = users.find((user) => user.role === enums.EventUserRole.HOST)?.user

  return {
    id: data.event_id,
    internalId: data.internal_id ?? "",
    slug: data.slug ?? "",

    organization: data.organization_id,

    location: data.location_id,

    instructors: data.instructor_ids ?? [],
    guestInstructors: (data.guest_instructors ?? []).map((elem) => ({
      email: elem.email,
      name: mappers.normalizeUserName(elem.name),
    })),

    customerSuccessRep: data.cs_rep_user_id,

    users,
    host,
    cancelled: data.status === enums.EventStatus.CANCELLED,

    specialty: !data.specialty_id
      ? undefined
      : {
          specialty: data.specialty_id,
          subspecialtyId: data.sub_specialty_id,
        },

    title: data.title ?? "",
    description: data.description ?? "",
    privacy: data.privacy ?? enums.EventPrivacy.PRIVATE,
    status: data.status ?? enums.EventStatus.UNKNOWN,
    startTime: !data.start_time ? 0 : new Date(data.start_time).getTime(),
    endTime: !data.end_time ? 0 : new Date(data.end_time).getTime(),
    flexibleTime: data.flexible_time,
    usersCount: data.event_users_count ?? 0,
    seatCount: data.seat_count ?? 0,
    streamSettings: mappers.normalizeStreamSettings(data.stream_settings),
    photo: data.photo,
    type: data.type,
    funding: data.funding,
  }
}

const normalizeDevice = (data: Partial<generated.Device>): NormalizedDevice => {
  if (!data.device_id) {
    throw new Error("missing device_id")
  }

  return {
    id: data.device_id,
    serialNumber: data.serial_number ?? data.device_id,
    name: data.name ?? "",

    user: data.user_id,

    pairingCode: data.pairing_code ?? "",
    pairedAt: !data.paired_at ? 0 : new Date(data.paired_at).getTime(),
  }
}

const normalizeFcmToken = (data: Partial<generated.FcmToken>): NormalizedFcmToken => {
  if (!data.fcm_token_id) {
    throw new Error("missing fcm_token_id")
  }
  if (!data.user_id) {
    throw new Error("missing user_id")
  }
  if (!data.token) {
    throw new Error("missing token")
  }

  return {
    id: data.fcm_token_id,
    token: data.token,

    user: data.user_id,
    device: data.device_id,
  }
}

const normalizePairing = (
  data: Partial<{ pairing_id: string; user_id: string; event_id: string; device_id: string }>,
): NormalizedPairing => {
  if (!data.pairing_id) {
    throw new Error("missing pairing_id")
  }
  if (!data.device_id) {
    throw new Error("missing device_id")
  }

  return {
    id: data.pairing_id,

    user: data.user_id,
    event: data.event_id,
    device: data.device_id,

    code: data.pairing_id ?? "",
  }
}

const normalizeConnectionReport = (data: Partial<generated.EventConnectionsReport>): NormalizedConnectionReport => {
  if (!data.event_id) {
    throw new Error("missing event_id")
  }

  return {
    event: data.event_id,

    startTime: new Date(data.start_time).getTime(),
    endTime: new Date(data.end_time).getTime(),

    totalConnectionTime: data.total_connection_time ?? 0,
    totalDisconnections: data.total_disconnections ?? 0,
    totalDisconnectionTime: data.total_disconnection_time ?? 0,

    eventUsers: (data.event_users ?? []).map((elem) => {
      if (!elem.user_id) {
        throw new Error("missing user_id")
      }
      return {
        user: elem.user_id,
        startTime: new Date(elem.start_time).getTime(),
        endTime: new Date(elem.end_time).getTime(),
        eventRole: elem.event_user_role || enums.EventUserRole.UNKNOWN,

        totalConnectionTime: elem.total_connection_time ?? 0,
        totalDisconnections: elem.total_disconnections ?? 0,
        totalDisconnectionTime: elem.total_disconnection_time ?? 0,
      }
    }),
  }
}

const normalizeImage = (data: Partial<generated.Image>): NormalizedImage => {
  if (!data.image_id) {
    throw new Error("missing image_id")
  }

  return {
    id: data.image_id,

    name: data.name ?? "",
    tags: data.tags ?? [],
    url: data.url ?? "",
    organization: data.organization_id,
    public: data.public === undefined ? !data.organization_id : data.public,
  }
}

const normalizeEventUser = (userId: string, data: Partial<generated.EventUser>): NormalizedEventUser => {
  return {
    user: userId,
    registrationStatus: data.registration_status ?? enums.EventUserRegistrationStatus.UNKNOWN,
    role: data.role ?? enums.EventUserRole.UNKNOWN,
    nps: data?.nps
      ? {
          answer: data.nps.answer,
          answeredAt: data.nps.answered_at,
        }
      : undefined,
  }
}

const normalizeStreamServer = (data: Partial<generated.StreamServer>): NormalizedStreamServer => {
  return {
    streamServerId: data.stream_server_id ?? "",
    eventId: data.event_id ?? "",
    userId: data.user_id ?? "",
    eipId: data.eip_id ?? "",
    publicIp: data.public_ip ?? "",
    domain: data.domain ?? "",
    instanceId: data.instance_id ?? "",
    amiImageId: data.ami_image_id ?? "",
    instanceType: data.instance_type ?? "",
    launchTime: data.launch_time ?? "",
    metadata: normalizeMetadata(data.metadata),
  }
}

const normalizeCloudXRServer = (data: Partial<generated.CxrServer>): NormalizedCloudXRServer => {
  return {
    cxrServerId: data.cxr_server_id ?? "",
    eipId: !data.eip_id ? undefined : data.eip_id,
    publicIp: !data.public_ip ? undefined : data.public_ip,
    domain: !data.domain ? undefined : data.domain,
    instanceId: data.instance_id ?? "",
    amiImageId: data.ami_image_id ?? "",
    instanceType: data.instance_type ?? "",
    instanceState: data.instance_state ?? "",
    userId: !data.user_id ? undefined : data.user_id,
    deviceId: !data.device_id ? undefined : data.device_id,
    launchTime: data.launch_time ?? "",
    metadata: normalizeMetadata(data.metadata),
  }
}

const normalizeMetadata = (data?: Partial<generated.Metadata>): NormalizedMetadata => {
  return {
    createdBy: data?.created_by ?? "",
    createdAt: data?.created_at ?? "",
    updatedBy: data?.updated_by ?? "",
    updatedAt: data?.updated_at ?? "",
    deletedBy: data?.deleted_by,
    deletedAt: data?.deleted_at,
  }
}

export const entityMappers = {
  normalizeJobType,
  normalizeIndustry,
  normalizeSubspecialty,
  normalizeSpecialty,
  normalizeOrganization,
  normalizeInstructor,
  normalizeDemo,
  normalizeLocation,
  normalizeUser,
  normalizeEventUser,
  normalizeEvent,
  normalizeDevice,
  normalizeFcmToken,
  normalizePairing,
  normalizeImage,
  normalizeConnectionReport,
  normalizeHeadsetRequest: mappers.normalizeHeadsetRequest,
  normalizeStreamServer,
  normalizeCloudXRServer,
  normalizeMetadata,
}
