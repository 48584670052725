import { createGlobalStyle } from "styled-components"
import fontGilroyRegularWoff from "../fonts/gilroy-regular.woff"
import fontGilroyRegularEot from "../fonts/gilroy-regular.eot"
import fontGilroyRegularSvg from "../fonts/gilroy-regular.svg"
import fontGilroyRegularTtf from "../fonts/gilroy-regular.ttf"
import fontGilroyMediumWoff from "../fonts/gilroy-medium.woff"
import fontGilroyMediumWoff2 from "../fonts/gilroy-medium.woff2"
import fontGilroyMediumEot from "../fonts/gilroy-medium.eot"
import fontGilroyMediumSvg from "../fonts/gilroy-medium.svg"
import fontGilroyMediumTtf from "../fonts/gilroy-medium.ttf"
import { colors } from "./etc/theme"

export const GlobalStyles = createGlobalStyle`
  /* Gilroy Regular */
  @font-face {
    font-family: "Gilroy";
    font-weight: 400;
    src: url('${fontGilroyRegularEot}');
    src: url('${fontGilroyRegularEot}?#iefix') format("embedded-opentype"),
    url('${fontGilroyRegularWoff}') format("woff"),
    url('${fontGilroyRegularTtf}') format("truetype"),
    url('${fontGilroyRegularSvg}#Gilroy-Regular') format("svg");
  }
  /* Gilroy Medium */
  @font-face {
    font-family: "Gilroy";
    font-weight: 600;
    src: url('${fontGilroyMediumEot}');
    src: url('${fontGilroyMediumEot}?#iefix') format("embedded-opentype"),
    url('${fontGilroyMediumWoff2}') format("woff2"),
    url('${fontGilroyMediumWoff}') format("woff"),
    url('${fontGilroyMediumTtf}') format("truetype"),
    url('${fontGilroyMediumSvg}#Gilroy-Medium') format("svg");
  }
  
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: ${colors.gray11};
    font-family: "Gilroy", serif;
    font-size: 16px;
    color: ${colors.white};
  }
  
  ::-webkit-scrollbar {
    width: 18px;
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(255,255,255, 0.5);
  }

  #app {
    width: 100%;
    height: 100%;
  }
`
