import React, { forwardRef, useMemo } from "react"
import * as THREE from "three"
import img from "../../../images/ui.png"

const ResizerMesh = (props, ref) => {
  const texture = useMemo(() => {
    const texture = new THREE.TextureLoader().load(img)
    texture.repeat.set(0.1, 0.5)
    return texture
  }, [img])

  return (
    <mesh ref={ref} {...props} name="resizer-control">
      <planeBufferGeometry attach="geometry" args={[0.3, 0.3]} />
      <meshBasicMaterial attach="material" side={THREE.FrontSide}>
        <primitive attach="map" object={texture} offset={[0.905, 0.5]} />
      </meshBasicMaterial>
    </mesh>
  )
}

ResizerMesh.displayName = "Resizer"

const Resizer = forwardRef(ResizerMesh)

export default Resizer
