import React, { createContext, useState, useEffect, useRef, useCallback } from "react"

export const ControllableContext = createContext()

const initialInputState = {
  selecting: false,
  controller: undefined,
  intersection: undefined,
}

export const Controllable = ({ children }) => {
  // Array of Object3D that we would like controller raycaster to intersect.
  const interactable = useRef([])

  // Selected Object3D
  const [selectDown, setSelectDown] = useState(null)

  // Controller input state
  const [input, setInput] = useState(initialInputState)

  // Raycast targets
  const rayTarget = useRef([])

  const getInteractableByType = (object3D, typeName) => {
    return interactable.current
      .filter((object) => object.object3D === object3D && object.types.includes(typeName))
      .shift()
  }

  const getInteractableCallback = (fromInteractable, typeName) => {
    if (!fromInteractable) {
      console.log("fromInteractable", { fromInteractable, typeName })
    }
    return fromInteractable.callbacks[fromInteractable.types.indexOf(typeName)]
  }

  const onInputUpdate = () => {
    const { selecting, intersection, controller } = input

    // todo: we want to not gate on intersection here. select-up means that we may not have a intesection.
    if (!intersection) {
      return
    }

    const clickable = getInteractableByType(intersection.object, "click")
    const selectableDown = getInteractableByType(intersection.object, "select-down")
    const selectableUp = getInteractableByType(intersection.object, "select-up")
    const isSelectDown = selecting && intersection && selectDown === null
    const isSelectUp = !selecting && selectableUp

    const isClick = !selecting && clickable && selectDown

    if (isSelectDown) {
      if (selectableDown) {
        const callback = getInteractableCallback(selectableDown, "select-down")
        if (callback) {
          callback({ intersection, controller })
        }
      }
      setSelectDown({
        object: intersection.object,
        clock: Date.now(),
      })
    }

    if (isSelectUp) {
      const callback = getInteractableCallback(selectableUp, "select-up")
      if (callback) {
        callback({ intersection })
      }
    }

    if (isClick) {
      const clickableCallback = getInteractableCallback(clickable, "click")
      if (clickableCallback) {
        clickableCallback({ intersection, controller })
      }
    }

    if (isSelectUp || isClick) {
      setSelectDown(null)
    }
  }

  const getRayTarget = ({ controller, intersection }) => {
    return rayTarget.current.filter(
      (target) => target.intersection.object === intersection.object && target.controller === controller,
    )
  }

  const rayIntersection = (controller, intersections) => {
    intersections.map((intersection) => {
      const target = {
        controller,
        intersection,
      }

      const matchedRayTarget = getRayTarget(target).length !== 0
      const pointerEnter = getInteractableByType(intersection.object, "pointer-enter")

      if (pointerEnter && !matchedRayTarget) {
        const callback = getInteractableCallback(pointerEnter, "pointer-enter")
        if (callback) {
          callback(target)
        }
        rayTarget.current.push(target)
      }

      const pointerMove = getInteractableByType(intersection.object, "pointer-move")
      if (pointerMove) {
        const callback = getInteractableCallback(pointerMove, "pointer-move")
        if (callback) {
          callback(target)
        }
      }
    })
  }

  const onControllerUpdate = useCallback((controller, intersections) => {
    rayIntersection(controller, intersections)

    rayTarget.current = rayTarget.current.filter((target) => {
      const intersectingTarget =
        intersections.filter((intersection) => intersection.object === target.intersection.object).length !== 0

      if (target.controller === controller && !intersectingTarget) {
        // pointer out
        const pointerOut = getInteractableByType(target.intersection.object, "pointer-out")
        if (pointerOut) {
          const callback = getInteractableCallback(pointerOut, "pointer-out")
          if (callback) {
            callback({ controller })
          }
        }
        // remove target
        return false
      } else {
        return true
      }
    })
  })

  useEffect(onInputUpdate, [input])

  return (
    <ControllableContext.Provider
      value={{
        interactable,
        selectDown,
        input,
        setInput,
        onControllerUpdate,
      }}
    >
      {children}
    </ControllableContext.Provider>
  )
}
