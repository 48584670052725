import React, { createContext, useReducer, useEffect, useContext, useRef } from "react"
import * as Sentry from "@sentry/browser"

const settings = {
  sessionType: "immersive-vr",
  headsetTimeout: 12000, // timeout (ms) of headset sleep from visibility state change 'hidden'
}

const initialState = {
  renderer: null,
  contextIsLost: false,
  xr: false,
  session: null,
  sessionActive: false,
  visibility: null,
  headsetTimedOut: false,
}

const SceneStateContext = createContext(initialState)
const SceneDispatchContext = createContext()

const reducer = (state, action) => {
  switch (action.type) {
    case "setXR":
      return {
        ...state,
        xr: action.payload,
      }
    case "setRenderer":
      return {
        ...state,
        renderer: action.payload,
      }
    case "exitXR": {
      if (!state.sessionActive) return state
      return {
        ...state,
        headsetTimedOut: false,
        visibility: null,
        sessionActive: false,
      }
    }
    case "setSession": {
      return {
        ...state,
        session: action.payload,
        sessionActive: !!action.payload,
      }
    }
    case "setSessionVisibility": {
      return {
        ...state,
        visibility: action.payload,
      }
    }
    case "headsetTimedOut": {
      return {
        ...state,
        headsetTimedOut: true,
      }
    }
    case "webGLContextLost":
      // todo: we need an additional check as contextLost gets fired on VR exit
      if (!state.sessionActive) return state
      return {
        ...state,
        contextIsLost: true,
      }
    case "webGLContextRestore":
      return {
        contextIsLost: false,
        ...state,
      }
  }
}

const SceneContainer = ({ children }) => {
  // much of this follows https://github.com/mrdoob/three.js/blob/dev/examples/jsm/webxr/VRButton.js
  // todo: will want to use XR when implemented.
  // track: https://developer.oculus.com/documentation/oculus-browser/latest/concepts/browser-release-notes
  const [state, dispatch] = useReducer(reducer, initialState)
  const headsetTimeoutId = useRef(null)

  const onWebGLContextLost = () => {
    Sentry.addBreadcrumb({
      category: "WebGL",
      message: "WebGL Context Lost",
      level: Sentry.Severity.Fatal,
    })
    dispatch({ type: "webGLContextLost" })
  }

  const onWebGLContextRestored = () => {
    Sentry.addBreadcrumb({
      category: "WebGL",
      message: "WebGL Context Restored",
      level: Sentry.Severity.Warning,
    })
    dispatch({ type: "webGLContextRestore" })
  }

  const onXRSessionEnded = () => {
    state.session.removeEventListener("end", onXRSessionEnded)
    state.session.removeEventListener("visibilitychange", onXRSessionVisibilityChange)
    dispatch({ type: "exitXR" })
  }

  const onXRSessionVisibilityChange = () =>
    dispatch({ type: "setSessionVisibility", payload: state.session.visibilityState })

  const beginXRSession = () => {
    if (state.session && state.renderer) {
      state.renderer.gl.xr.setSession(state.session)
      state.session.addEventListener("end", onXRSessionEnded)
      state.session.addEventListener("visibilitychange", onXRSessionVisibilityChange)
    }
  }

  const initializeXR = () => {
    if ("xr" in navigator) {
      navigator.xr.isSessionSupported(settings.sessionType).then((supported) => {
        dispatch({ type: "setXR", payload: supported })
      })
    }
  }

  const handleVisibilityChange = () => {
    if (!state.session) {
      return
    }

    if (headsetTimeoutId.current !== null) {
      window.clearTimeout(headsetTimeoutId.current)
      headsetTimeoutId.current = null
    }

    switch (state.session.visibilityState) {
      case "hidden":
        // when user takes off headset

        // When Oculus headset sleeps (with "beep" sound), we will exit users out of VR.
        // This is done because audio stops playing unless user exits and re-enters.
        headsetTimeoutId.current = window.setTimeout(() => {
          dispatch({ type: "exitXR" })
        }, settings.headsetTimeout)
        break
      case "visible":
      // when user puts headset on.
    }
  }

  const handleContextLoss = () => {
    if (state.contextIsLost) {
      // We will reload the page if the WebGL context is lost in a attempt to restore the session.
      window.location.reload(false)
    }
  }

  const webGLContextListeners = () => {
    if (state.renderer) {
      state.renderer.gl.domElement.addEventListener("webglcontextlost", onWebGLContextLost)
      state.renderer.gl.domElement.addEventListener("webglcontextrestored", onWebGLContextRestored)

      return () => {
        if (!state.renderer.gl) return
        state.renderer.gl.domElement.removeEventListener("webglcontextlost", onWebGLContextLost)
        state.renderer.gl.domElement.removeEventListener("webglcontextrestored", onWebGLContextRestored)
      }
    }
  }

  const exitXRSession = () => {
    if (!state.sessionActive && state.session) {
      state.session
        .end()
        .catch((err) => console.warn(err.message))
        .finally(() => dispatch({ type: "setSession", payload: null }))
    }
  }

  useEffect(beginXRSession, [state.session, state.renderer])
  useEffect(handleVisibilityChange, [state.visibility])
  useEffect(handleContextLoss, [state.contextIsLost])
  useEffect(webGLContextListeners, [state.renderer])
  useEffect(initializeXR, [state.renderer])
  useEffect(exitXRSession, [state.sessionActive])

  return (
    <SceneStateContext.Provider value={state}>
      <SceneDispatchContext.Provider value={dispatch}>{children}</SceneDispatchContext.Provider>
    </SceneStateContext.Provider>
  )
}

const useSceneState = () => {
  const context = useContext(SceneStateContext)
  if (context === undefined) {
    throw new Error("useSceneState must be within SceneDispatchContext")
  }
  return context
}

const useSceneDispatcher = () => {
  const context = useContext(SceneDispatchContext)
  if (context === undefined) {
    throw new Error("useSceneDispatcher must be within SceneContainer")
  }
  return context
}

const useScene = () => [useSceneState(), useSceneDispatcher()]

export { SceneContainer, useScene }
