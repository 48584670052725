import { parsePhoneNumber } from "libphonenumber-js"

import { enums } from "./enums"

import type { NormalizedHeadsetRequest, NormalizedInstructorSpecialty } from "./schema"
import type * as model from "./model"

import type { ReadonlyDeep } from "type-fest"

const emptyPhone = { mobile: "" }

const normalizePhone = (data: Partial<model.generated.Phone> = {}): model.Phone => {
  if (!data.mobile) return emptyPhone

  try {
    const parsed = parsePhoneNumber(data.mobile, "US")
    if (!parsed?.isValid()) return emptyPhone

    return {
      mobile: parsed.format("RFC3966"),
    }
  } catch (err) {
    console.warn("invalid phone number", { mobile: data.mobile, err })
  }
  return emptyPhone
}

const normalizeUserName = (data: Partial<model.generated.UserName> = {}): model.UserName => ({
  prefix: data.prefix as model.namePrefixType,
  first: data.first ?? "",
  middle: data.middle,
  last: data.last ?? "",
  suffix: data.suffix as model.nameSuffixType,
  alias: data.alias,
})

const normalizeUserPreferences = (data: Partial<model.generated.UserPreferences> = {}): model.UserPreferences => ({
  notifications: {
    email: data.notifications?.email ?? false,
    sms: data.notifications?.sms ?? false,
    push: data.notifications?.push ?? false,
  },
})

const normalizeUserInfo = (data: Partial<model.generated.UserInfo> = {}): model.UserInfo => ({
  email: data?.email ?? "",
  name: normalizeUserName(data?.name),
  phone: normalizePhone(data?.phone),
})

const normalizeAddress = (addr: Partial<model.generated.Address> = {}): model.Address | undefined => {
  if (
    !addr.address_line_1 &&
    !addr.address_line_2 &&
    !addr.city &&
    !addr.zip &&
    !addr.state &&
    !addr.country &&
    !addr.company
  ) {
    return undefined
  }
  return mustNormalizeAddress(addr)
}

const mustNormalizeAddress = (addr: Partial<model.generated.Address> = {}): model.Address => {
  return {
    addressLine1: addr.address_line_1 ?? "",
    addressLine2: addr.address_line_2,
    city: addr.city ?? "",
    zip: addr.zip ?? "",
    state: addr.state ?? "",
    country: addr.country,
    company: addr.company,
    extra: addr.extra,
  }
}

const normalizeCoordinates = (coords?: Partial<model.generated.Coordinates>): model.Coordinates | undefined =>
  !coords
    ? coords
    : {
        latitude: coords.latitude ?? 0,
        longitude: coords.longitude ?? 0,
      }

const normalizeDigitalFootprint = (data: Partial<model.generated.DigitalFootprint> = {}): model.DigitalFootprint => ({
  facebook: data.facebook?.trim(),
  instagram: data.instagram?.trim(),
  linkedin: data.linkedin?.trim(),
  twitter: data.twitter?.trim(),
  website: data.website?.trim(),
})

const normalizeTrackingInfo = (data: Partial<model.generated.TrackingInfo>): model.TrackingInfo => ({
  returnNumber: data.return_number,
  returnDate: !data.return_date ? 0 : new Date(data.return_date).getTime(),
  shippingNumber: data.shipping_number,
  shippingDate: !data.shipping_date ? 0 : new Date(data.shipping_date).getTime(),
})

const normalizeHeadsetRequest = (data: Partial<model.generated.HeadsetRequest>): NormalizedHeadsetRequest => {
  if (!data.headset_request_id) {
    throw new Error("missing headset request id")
  }
  return {
    id: data.headset_request_id,
    metadata: data.metadata,
    user: data.user_id ?? "",
    events: data.event_ids,
    status: data.status ?? enums.HeadsetRequestStatus.UNKNOWN,
    type: data.device_type ?? enums.HeadsetRequestDeviceType.UNKNOWN,
    loanType: data.loan_type ?? enums.HeadsetRequestLoanType.UNKNOWN,
    orderDate: data.order_date ? new Date(data.order_date).getTime() : 0,
    userClassification: data.user_classification ?? enums.UserClassification.UNKNOWN,
    headsetsSerialNumbers: data.headsets_serial_numbers,
    expectedDeliveryDate: !data.expected_delivery_date ? 0 : new Date(data.expected_delivery_date).getTime(),
    trackingInfo: normalizeTrackingInfo(data.tracking_info ?? {}),
    name: normalizeUserName(data.name),
    address: normalizeAddress(data.address),
    quantity: data.quantity ?? 1,
    lateFeeAck: !data.late_fee_ack ? 0 : new Date(data.late_fee_ack).getTime(),
  }
}

const normalizeDisplay = (data: Partial<model.generated.Display> = {}): model.Display => ({
  marketing: data.marketing ?? false,
})

const normalizeStreamSettings = (data?: Partial<model.generated.StreamSettings>): undefined | model.StreamSettings => {
  if (!data) return undefined
  if (!data.media_server_url && !data.user_list_disabled) return undefined

  return {
    mediaServerUrl: data.media_server_url ?? "",
    userListDisabled: data.user_list_disabled ?? false,
  }
}

const normalizeDemoMainVideo = (data: Partial<model.generated.DemoMainVideo>): model.NormalizedDemoMainVideo => {
  return {
    url: data.url ?? "",
    settings: {
      ...(data.settings?.fov && { fov: data.settings.fov }),
      ...(data.settings?.vertical_shift && { verticalShift: data.settings.vertical_shift }),
      ...(data.settings?.horizontal_shift && { horizontalShift: data.settings.horizontal_shift }),
    },
  }
}

const denormalizeDemoMainVideo = (data: model.NormalizedDemoMainVideo): model.generated.DemoMainVideo => {
  return {
    url: data.url,
    settings: {
      fov: data.settings.fov,
      vertical_shift: data.settings.verticalShift,
      horizontal_shift: data.settings.horizontalShift,
    },
  }
}

const denormalizeAddress = (addr?: Partial<model.Address>): undefined | model.generated.Address => {
  if (!addr) return undefined
  if (Object.values(addr).findIndex((v) => v !== undefined) === -1) {
    return undefined
  }
  return mustDenormalizeAddress(addr)
}

const mustDenormalizeAddress = (addr: Partial<model.Address>): model.generated.Address => {
  return {
    address_line_1: addr?.addressLine1 ?? "",
    address_line_2: addr?.addressLine2,
    city: addr?.city ?? "",
    state: addr?.state ?? "",
    zip: addr?.zip ?? "",
    company: addr?.company,
    country: addr?.country,
    extra: addr?.extra,
  }
}

const denormalizePhone = (data?: Partial<model.Phone>): undefined | model.generated.Phone => {
  if (!data) return undefined
  if (!data.mobile) return undefined

  return mustDenormalizePhone(data as Required<model.Phone>) // Checked values, we now know it is a full model.Phone.
}

const mustDenormalizePhone = (data: Required<model.Phone>): model.generated.Phone => {
  try {
    const parsed = parsePhoneNumber(data.mobile ?? "", "US")
    if (!parsed?.isValid()) return emptyPhone as model.generated.Phone

    return {
      mobile: parsed.format("RFC3966"),
    }
  } catch (err) {
    console.warn("invalid phone number", { mobile: data.mobile, err })
  }
  return emptyPhone as model.generated.Phone
}

const denormalizeUserName = (data?: Partial<model.UserName>): undefined | model.generated.UserName => {
  if (!data) return undefined
  if (Object.values(data).findIndex((v) => v !== undefined && v !== "") === -1) {
    return undefined
  }
  return mustDenormalizeUserName(data)
}

const mustDenormalizeUserName = (data: Partial<model.UserName>): model.generated.UserName => {
  return {
    prefix: data.prefix ? data.prefix.toString() : undefined,
    first: data.first ?? "",
    middle: data.middle,
    last: data.last ?? "",
    suffix: data.suffix ? data.suffix.toString() : undefined,
    alias: data.alias,
  }
}

const denormalizeUserPreferences = (
  data?: Partial<model.UserPreferences>,
): undefined | model.generated.UserPreferences => {
  if (!data || !data.notifications) return undefined
  if (Object.values(data.notifications).findIndex((v) => v !== undefined) === -1) {
    return undefined
  }
  return mustDenormalizeUserPreferences(data)
}

const mustDenormalizeUserPreferences = (data: Partial<model.UserPreferences>): model.generated.UserPreferences => {
  return {
    notifications: {
      email: data.notifications?.email ?? false,
      sms: data.notifications?.sms ?? false,
      push: data.notifications?.push ?? false,
    },
  }
}

const denormalizeUserInfo = (data?: Partial<model.UserInfo>): undefined | model.generated.UserInfo => {
  if (!data) return undefined

  const name = denormalizeUserName(data.name)
  const phone = denormalizePhone(data.phone)

  if (!data.email && !name && !phone) return undefined

  return mustDenormalizeUserInfo(data)
}

const mustDenormalizeUserInfo = (data: Partial<model.UserInfo>): model.generated.UserInfo => {
  const out: model.generated.UserInfo = {
    email: data?.email ?? "",
    name: mustDenormalizeUserName(data.name ?? {}),
  }

  const phone = denormalizePhone(data.phone)
  if (phone) {
    out.phone = phone
  }

  return out
}

const denormalizeTrackingInfo = (data?: model.TrackingInfo): undefined | model.generated.TrackingInfo => {
  if (!data) return undefined
  if (!data.returnNumber && !data.shippingNumber && !data.returnDate && !data.shippingDate) {
    return undefined
  }

  return {
    return_number: data.returnNumber,
    return_date: !data.returnDate ? undefined : new Date(data.returnDate).toISOString(),
    shipping_number: data.shippingNumber,
    shipping_date: !data.shippingDate ? undefined : new Date(data.shippingDate).toISOString(),
  }
}

const denormalizeStreamSettings = (data?: model.StreamSettings): undefined | model.generated.StreamSettings => {
  if (!data) return undefined
  if (!data.mediaServerUrl && !data.userListDisabled === undefined) return undefined

  return {
    media_server_url: data.mediaServerUrl,
    user_list_disabled: data.userListDisabled,
  }
}

const denormalizeGuestInstructors = (
  data: readonly model.GuestInstructor[],
): undefined | model.generated.GuestInstructor[] => {
  if (!data?.length) return undefined

  return data.map(mustDenormalizeUserInfo)
}

const denormalizeCoordinates = (coords?: Partial<model.Coordinates>): undefined | model.generated.Coordinates => {
  if (!coords?.latitude && !coords?.longitude) return undefined

  const out: model.generated.Coordinates = {}
  if (coords.latitude) {
    out.latitude = coords.latitude
  }
  if (coords.longitude) {
    out.longitude = coords.longitude
  }
  return out
}

const denormalizeDigitalFootprint = (
  data?: Partial<model.DigitalFootprint>,
): undefined | model.generated.DigitalFootprint => {
  if (!data) return undefined
  if (Object.values(data).findIndex((v) => v !== undefined) === -1) {
    return undefined
  }

  const out: model.generated.DigitalFootprint = {}

  if (data.facebook) {
    out.facebook = data.facebook
  }
  if (data.instagram) {
    out.instagram = data.instagram
  }
  if (data.linkedin) {
    out.linkedin = data.linkedin
  }
  if (data.twitter) {
    out.twitter = data.twitter
  }
  if (data.website) {
    out.website = data.website
  }

  return out
}

const mustDenormalizeInstructorSpecialty = (
  data: NormalizedInstructorSpecialty,
): ReadonlyDeep<model.generated.UserSpecialty> => {
  return {
    certification: {
      status: "PENDING",
    },
    child_ids: data.subspecialtyIds,
  }
}

const mustDenormalizeInstructorSpecialties = (
  data: readonly NormalizedInstructorSpecialty[],
): model.generated.UserSpecialties => {
  return data.reduce((prev, cur) => ({ ...prev, [cur.specialty]: mustDenormalizeInstructorSpecialty(cur) }), {})
}

export const mappers = {
  normalizeAddress,
  mustNormalizeAddress,
  normalizeCoordinates,
  normalizeDigitalFootprint,
  normalizeDisplay,
  normalizePhone,
  normalizeUserInfo,
  normalizeUserName,
  normalizeTrackingInfo,
  normalizeHeadsetRequest,
  normalizeUserPreferences,
  normalizeStreamSettings,
  normalizeDemoMainVideo,

  denormalizeAddress,
  mustDenormalizeAddress,
  denormalizePhone,
  mustDenormalizePhone,
  denormalizeUserInfo,
  mustDenormalizeUserInfo,
  denormalizeUserName,
  mustDenormalizeUserName,
  denormalizeTrackingInfo,
  denormalizeUserPreferences,
  mustDenormalizeUserPreferences,
  denormalizeStreamSettings,
  denormalizeGuestInstructors,
  denormalizeDigitalFootprint,
  denormalizeCoordinates,
  mustDenormalizeInstructorSpecialties,
  denormalizeDemoMainVideo,
}
