import { mutationsApi } from "./mutations"

// API Generated using:
// rtk-query-codegen-openapi --hooks --baseQuery ./api.baseQuery.ts ~masclient/openapi.yaml > api.generated.ts
export const api = mutationsApi.enhanceEndpoints({
  endpoints: {
    postEventUserStatusActions: {
      invalidatesTags: (_results, _error, arg) => {
        return [
          { type: "Events", id: "LIST" },
          { type: "Users", id: "LIST" },
          { type: "Events", id: arg.eventId },
          { type: "Users", id: arg.userId },
        ]
      },
    },
    postEventUserRoleAction: {
      invalidatesTags: (_results, _error, arg) => {
        return [
          { type: "Events", id: "LIST" },
          { type: "Users", id: "LIST" },
          { type: "Events", id: arg.eventId },
          { type: "Users", id: arg.userId },
        ]
      },
    },
    updateHeadsetRequest: {
      invalidatesTags: (_results, _error, arg) => {
        return [
          { type: "Events", id: "LIST" },
          { type: "Users", id: arg.user },
          { type: "HeadsetRequests", id: arg.id },
        ]
      },
    },
    deleteHeadsetRequest: {
      invalidatesTags: (_results, _error, arg) => {
        return [
          { type: "Events", id: "LIST" },
          { type: "Users", id: "LIST" },
          { type: "HeadsetRequests", id: "LIST" },
          { type: "HeadsetRequests", id: arg.id },
        ]
      },
    },
    linkHeadsetRequestEvents: {
      invalidatesTags: (_results, _error, arg) => {
        return [
          { type: "Events", id: "LIST" },
          { type: "Users", id: "LIST" },
          { type: "HeadsetRequests", id: arg.headsetRequestId },
        ]
      },
    },
    upsertUserOrganization: {
      invalidatesTags: (_results, _error, arg) => {
        return [
          { type: "Users", id: "LIST" },
          { type: "Users", id: arg.userId },
        ]
      },
    },
    deleteUserOrganization: {
      invalidatesTags: (_results, _error, arg) => {
        return [
          { type: "Users", id: "LIST" },
          { type: "Users", id: arg.userId },
        ]
      },
    },
    updateImage: {
      invalidatesTags: (_results, _error, arg) => {
        return [
          { type: "Images", id: "LIST" },
          { type: "Images", id: arg.id },
        ]
      },
    },
    createJoinCode: {
      invalidatesTags: () => {
        return [{ type: "JoinCodes", id: "LIST" }]
      },
    },

    speedTest: {},
  },
})

export const {
  useWhoAmIQuery,
  useListStreamServersQuery,
  useStartEventStreamServerMutation,
  useTerminateEventStreamServerMutation,

  useListCxrServersQuery: useListCloudXRServersQuery,
  useCreateCxrServerMutation: useCreateCloudXRServerMutation,
  useStartCxrServerMutation: useStartCloudXRServerMutation,
  useStopCxrServerMutation: useStopCloudXRServerMutation,
  useDeleteCxrServerMutation: useDeleteCloudXRServerMutation,
  useWaitCxrServerMutation: useWaitCloudXRServerMutation,
  useUnlinkCxrServerUserMutation: useUnlinkCloudXRServerUserMutation,
  useGetCxrServerInstanceTypesQuery: useGetCloudXRServerInstanceTypesQuery,
  useGetCxrServerAmIsQuery: useGetCloudXRServerAMIsQuery,

  usePostEventUserStatusActionsMutation,

  useGetHeadsetRequestsReportsQuery,

  useListOrganizationsQuery,
  useGetOrganizationQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,

  useListUsersQuery,
  useListPartialUsersQuery,
  useListUsersByUserRoleQuery,
  useListEventUsersQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useVerifyUserMutation,

  useListEventsQuery,
  useListUserEventsQuery,
  useGetEventQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,

  useListInstructorsQuery,
  useGetInstructorQuery,
  useCreateInstructorMutation,
  useUpdateInstructorMutation,
  useDeleteInstructorMutation,

  useListEventDemosQuery,
  useGetEventDemoQuery,

  useListLocationsQuery,
  useGetLocationQuery,
  useCreateLocationMutation,
  useUpdateLocationMutation,
  useDeleteLocationMutation,

  useListSpecialtiesQuery,
  useGetSpecialtyQuery,
  useCreateSpecialtyMutation,
  useUpdateSpecialtyMutation,
  useDeleteSpecialtyMutation,

  useListIndustriesQuery,
  useGetIndustryQuery,
  useCreateIndustryMutation,
  useUpdateIndustryMutation,
  useDeleteIndustryMutation,

  useListDevicesQuery,
  useListUserDevicesQuery,
  useGetDeviceQuery,
  useCreateDeviceMutation,
  useUpdateDeviceMutation,
  useDeleteDeviceMutation,
  usePairDeviceMutation,

  useListImagesQuery,
  useGetImageQuery,
  useCreateImageMutation,
  useUpdateImageMutation,
  useDeleteImageMutation,

  useListFcmTokensQuery,
  useGetFcmTokenQuery,
  useCreateFcmTokenMutation,
  useUpdateFcmTokenMutation,
  useDeleteFcmTokenMutation,

  useListHeadsetRequestsQuery,
  useGetHeadsetRequestQuery,
  useCreateHeadsetRequestMutation,
  useUpdateHeadsetRequestMutation,
  useDeleteHeadsetRequestMutation,
  useListHeadsetRequestHistoryQuery,

  useSendEmailInviteGeneralMutation,
  useSendEmailInviteEventMutation,
  useVerifyEmailMutation,

  useLogoutMutation,

  useCreateJoinCodeMutation,
  useExchangeJoinCodeMutation,

  useSpeedTestQuery,
  useGetEventConnectionsReportQuery,
} = api
