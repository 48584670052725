const theme = {
  color: {
    grey: {
      hex: "#243746",
    },
    darkgray: {
      hex: "#1b2830",
    },
    semigrey: {
      hex: "#141e24",
    },
    lightgrey: {
      hex: "#929ba3",
    },
    light: {
      hex: "#e8f1f2",
    },
    orange: {
      hex: "#ffb645",
      red: 255,
      green: 182,
      blue: 69,
      alpha: 1,
    },
    white: {
      hex: "#ffffff",
      red: 255,
      green: 255,
      blue: 255,
      alpha: 1,
    },
    green: {
      hex: "#43e06a",
      red: 93,
      green: 231,
      blue: 82,
      alpha: 1,
    },
    red: {
      hex: "#ff594f",
      red: 229,
      blue: 84,
      green: 71,
      alpha: 1,
    },
    blue: {
      hex: "#456cae",
    },
    lightBlue: {
      hex: "#5f95ef",
    },
  },
}

export default theme
