import React, { useContext, useCallback } from "react"
import { Box, Flex, Image, Text } from "rebass"
import { Button } from "../libs/ui"
import { AuthContext } from "../containers/Auth"
import Pairing from "./pairing"
import EventCode from "./eventCode"
import { colors } from "../etc/theme"
import { AUTH_TYPES } from "../constants"
import logo from "../../images/logo-small.png"

export const AuthOptions = () => {
  const { authType, setAuthType } = useContext(AuthContext)

  const handleAuthType = useCallback((type) => () => setAuthType(type), [setAuthType])

  if (authType === AUTH_TYPES.EVENT_CODE) return <EventCode />
  if (authType === AUTH_TYPES.PAIRING_CODE) return <Pairing />

  return (
    <Flex
      width="100%"
      maxWidth={480}
      height="100vh"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mx="auto"
    >
      <Box
        width="100%"
        maxWidth={1024}
        bg={colors.gray9}
        py={50}
        my={50}
        mx="auto"
        textAlign="center"
        css={{ borderRadius: 48 }}
      >
        <Image src={logo} />
        <Text fontSize={20} color={colors.white} mt={25}>
          Select an option to continue.
        </Text>

        <Flex flexDirection="column" justifyContent="center" mt={50} px={120}>
          <Button onClick={handleAuthType(AUTH_TYPES.EVENT_CODE)}>Use Passcode from email</Button>
          <Button variant="outline" mt={25} onClick={handleAuthType(AUTH_TYPES.PAIRING_CODE)}>
            Use Immertec Mobile App
          </Button>
        </Flex>
      </Box>
    </Flex>
  )
}

export default AuthOptions
