import React, { useEffect, useCallback } from "react"
import useCanvasUIasInteractable from "../../hooks/useCanvasUIasInteractable"
import useCylindrical from "../../hooks/useCylindrical"

const CanvasMesh = ({
  stage,
  onTexture,
  notDraggableOnCoords,
  verticalPosition = 1,
  angle = 0,
  radius = 6,
  ...props
}) => {
  const { width, height, texture, makeInteractable } = useCanvasUIasInteractable({ stage })

  const { CylindricalComponents, CylindricalMesh, setMeshBox, mesh } = useCylindrical({
    name: "UserList",
    texture,
    height,
    thetaLength: width * 0.15, // todo: will need to sort out conversion for width
    verticalPosition,
    angle,
    radius,
    notDraggableOnCoords,
  })

  useEffect(setMeshBox, [width, height])
  useEffect(() => {
    makeInteractable(mesh)
    onTexture(texture)
  }, [])

  return (
    <>
      <CylindricalMesh {...props} />
      <CylindricalComponents />
    </>
  )
}

const UserList = ({ id = "user-list", fromCanvas, ...props }) => {
  const [canvases, { dispatch }] = fromCanvas

  const setTexture = useCallback((id, texture) => dispatch({ type: "setTexture", payload: { id, texture } }))

  return (
    Object.keys(canvases).includes(id) && (
      <CanvasMesh stage={canvases[id].stage} onTexture={(texture) => setTexture(id, texture)} {...props} />
    )
  )
}

export default UserList
