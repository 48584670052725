import React, { useEffect, useMemo, useRef, useState, useContext } from "react"
import { SceneBridgeContext } from "../../containers/SceneBridge"
import useControllable from "../../hooks/useControllable"
import * as THREE from "three"
import { DraggableContext } from "../../containers/Draggable"
import Resizer from "./Resizer"

const settings = {
  maxScale: 3,
  minScale: 0.5, // 30% of the original size

  // UV zone in which resize controls are active
  resize: {
    x: 0.8,
    y: 0.2,
  },

  // offset between overlay depths
  depthOffset: 0.2,

  // offset between overlay and resize ui widget
  widgetOffset: 0.02,

  // size of widget for x-offset
  widgetSize: 0.21,

  // widget size to arc length multiplier
  // todo: we should be calculating this.
  widgetAngleScale: 0.03,
}

const Overlay = ({ element, name, height, thetaLength, verticalPosition, angle = 0, radius = 5, fps = 15 }) => {
  const meshRef = useRef(null)
  const cylinderRef = useRef(null)
  const cylinderGeometryRef = useRef(null)
  const resizer = useRef(null)
  const resizerGroup = useRef(null)
  const scale = useRef(1)
  const radiusOffset = useRef(0)
  const resizing = useRef(false)
  const dragging = useRef(false)
  const [resizeVisible, setResizeVisible] = useState(true)
  const { setInteractable, clearInteractable } = useControllable()
  const [hasTransformed, setHasTransformed] = useState(false)
  const {
    ui: { shouldResetOverlay, setShouldResetOverlay },
  } = useContext(SceneBridgeContext)

  const { setBox, intersections } = useContext(DraggableContext)

  const [origin, pointerDirection, originDirection, upVec, forwardVec, planedIntersection, meshCenter] = useMemo(() => {
    return [
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(),
      new THREE.Vector3(),
      new THREE.Vector3(0, 1, 0),
      new THREE.Vector3(0, 0, -1),
      new THREE.Vector3(),
      new THREE.Vector3(),
    ]
  }, [])

  const boxHelper = useRef(new THREE.Box3Helper())
  const boundingBox = useRef(new THREE.Box3())

  // Todo:
  // Much of this code has been copied into useCylindrical hook
  // The hope is to continue to move overlay code into hooks where
  //  it can be more easily shared.
  const pointerMove = ({ controller, intersection }) => {
    if ((dragging.current && !hasTransformed) || (resizing.current && !hasTransformed)) {
      setHasTransformed(true)
    }

    // check if were interacting with the bottom right corner and resizing
    const withinResizeArea =
      dragging.current &&
      dragging.current.intersection.uv.x >
        (scale.current < 1 ? settings.resize.x * scale.current : settings.resize.x) &&
      dragging.current.intersection.uv.y < (scale.current < 1 ? settings.resize.y / scale.current : settings.resize.y)

    if (dragging.current !== false && withinResizeArea && !resizing.current) {
      resizing.current = { controller: dragging.current.controller, intersection }
    }

    // Dragging
    if (dragging.current && dragging.current.controller === controller && !resizing.current) {
      // Resets intersection axis so we're working on the same plane as other vectors.
      planedIntersection.set(intersection.point.x, 0, intersection.point.z)
      pointerDirection.subVectors(origin, planedIntersection).normalize()

      // angle between center of overlay to pointer on overlay
      const pointerAngle = pointerDirection.angleTo(originDirection.subVectors(origin, forwardVec).normalize())

      // Calculate sign of the angle since angleTo only gives us up to 180deg
      const sign = Math.sign(
        Math.atan2(upVec.dot(originDirection.cross(pointerDirection)), originDirection.dot(pointerDirection)),
      )

      // Calculate pointer angle from UV, and apply the difference to final rotation and position.
      const overlayAngle = sign * (dragging.current.intersection.uv.x - 0.5) * thetaLength * scale.current

      meshRef.current.rotation.y = sign * (pointerAngle + overlayAngle)
      meshRef.current.position.y =
        intersection.point.y + (dragging.current.intersection.uv.y - 0.5) * -height * scale.current
    }

    // Resize
    if (resizing.current && resizing.current.controller === controller) {
      meshCenter.set(0, meshRef.current.position.y, -radius).applyQuaternion(meshRef.current.quaternion)

      const distanceFromCenter = meshCenter.distanceTo(intersection.point) / 2

      scale.current =
        distanceFromCenter < settings.minScale
          ? settings.minScale
          : distanceFromCenter > settings.maxScale
          ? settings.maxScale
          : distanceFromCenter

      // recreate geometry based on new sizing
      meshRef.current.geometry.dispose()
      meshRef.current.geometry = createGeometry({
        radius: radius - radiusOffset.current,
        height: height * scale.current,
        thetaLength: thetaLength * scale.current,
      })
    }

    // set bounding box
    if (
      (dragging.current && dragging.current.controller === controller) ||
      (resizing.current && resizing.current.controller === controller)
    ) {
      setMeshBox()
      setResizer()
    }
  }

  const setMeshBox = () => {
    // send bounding box to track overlaps
    meshRef.current.geometry.computeBoundingBox()
    meshRef.current.updateMatrixWorld(true)
    boundingBox.current.copy(meshRef.current.geometry.boundingBox).applyMatrix4(meshRef.current.matrixWorld)

    boxHelper.current.box = boundingBox.current

    setBox({ mesh: meshRef.current, boundingBox: boundingBox.current })
  }

  const createGeometry = ({ radius, height, thetaLength }) => {
    return new THREE.CylinderGeometry(radius, radius, height, 10, 1, true, -Math.PI + thetaLength / 2, -thetaLength)
  }

  const resetOverlay = () => {
    scale.current = 1
    meshRef.current.rotation.y = angle
    meshRef.current.position.set(0, verticalPosition, 0)
    meshRef.current.geometry.dispose()
    meshRef.current.geometry = createGeometry({
      radius,
      height,
      thetaLength,
    })
    setMeshBox()
    setResizer()
    setShouldResetOverlay(false)
  }

  const setResizer = () => {
    const rotationY = meshRef.current.rotation.y - (thetaLength / 2) * scale.current
    const positionY = meshRef.current.position.y - (height / 2) * scale.current
    resizer.current.position.set(
      0,
      positionY + settings.widgetSize,
      -radius + radiusOffset.current + settings.widgetOffset,
    )
    resizerGroup.current.rotation.y = rotationY + settings.widgetAngleScale
  }

  const spaceOverlay = () => {
    if (intersections.length === 0) {
      radiusOffset.current = 0
    }

    intersections.map((intersection, index) => {
      if (intersection.mesh === meshRef.current) {
        radiusOffset.current = (intersections.length - index) * settings.depthOffset
        meshRef.current.geometry.dispose()
        meshRef.current.geometry = createGeometry({
          radius: radius - radiusOffset.current,
          height: height * scale.current,
          thetaLength: thetaLength * scale.current,
        })
        setResizer()
      }
    })
  }

  useEffect(spaceOverlay, [intersections])

  useEffect(resetOverlay, [shouldResetOverlay, verticalPosition, angle])

  const videoTexture = useMemo(() => {
    if (element) {
      const videoTexture = new THREE.Texture(element.current)
      videoTexture.generateMipmaps = false
      videoTexture.minFilter = THREE.LinearFilter
      videoTexture.maxFilter = THREE.LinearFilter
      videoTexture.encoding = THREE.sRGBEncoding
      return videoTexture
    }
  }, [element])

  useEffect(() => {
    // Update video texture at set fps rather than every VR frame to save performance.
    // https://github.com/mrdoob/three.js/issues/13379
    if (!videoTexture || !element) {
      return
    }
    const intervalId = window.setInterval(() => {
      if (element.current && element.current.readyState >= element.current.HAVE_CURRENT_DATA) {
        videoTexture.needsUpdate = true
      }
    }, 1000 / fps)

    return () => {
      window.clearInterval(intervalId)
    }
  }, [videoTexture, element])

  useEffect(() => {
    setShouldResetOverlay(true)

    setInteractable(meshRef.current, "select-down", ({ controller, intersection }) => {
      dragging.current = { controller, intersection }
      setInteractable(cylinderRef.current, "pointer-move", pointerMove)
    })
    setInteractable(meshRef.current, "select-up", () => {
      resizing.current = false
      dragging.current = false
      clearInteractable(cylinderRef.current, "pointer-move")
    })
    setInteractable(meshRef.current, "pointer-enter", () => setResizeVisible(true))
    setInteractable(meshRef.current, "pointer-out", () => {
      if (!resizing.current) setResizeVisible(false)
    })

    return () => {
      clearInteractable(meshRef.current, "select-down")
      clearInteractable(meshRef.current, "select-up")
      clearInteractable(meshRef.current, "pointer-enter")
      clearInteractable(meshRef.current, "pointer-out")
    }
  }, [])

  const material = useMemo(() => {
    const mat = new THREE.MeshBasicMaterial()
    mat.side = THREE.FrontSide
    mat.map = videoTexture
    return mat
  })

  const mesh = useMemo(() => {
    const mesh = new THREE.Mesh(createGeometry({ radius, height, thetaLength }), material)
    mesh.renderOrder = 1
    return mesh
  }, [radius, height, thetaLength])

  return (
    <>
      <primitive object={mesh} material={material} ref={meshRef} name={`overlay-${name}`} />
      <group ref={resizerGroup}>
        <Resizer ref={resizer} visible={resizeVisible} />
      </group>
      <mesh name="raycast-target" ref={cylinderRef} matrixAutoUpdate={false}>
        <cylinderBufferGeometry
          ref={cylinderGeometryRef}
          attach="geometry"
          // we also add a bit to the radius so that raycasting doesn't get interupted by this geometry.
          args={[radius + 0.1, radius + 0.1, 20, 30, 30, true]}
        />
        <meshBasicMaterial
          side={THREE.BackSide}
          attach="material"
          args={[{ color: 0x304b63, wireframe: true }]}
          visible={false}
        />
      </mesh>
      <box3Helper args={[boundingBox.current, Math.random() * 0xffffff]} ref={boxHelper} visible={false} />
    </>
  )
}

export default Overlay
