import React, { useEffect, useState } from "react"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import logger from "../../etc/logger"

export const GLTFModel = ({ path, file, ...props }) => {
  const [model, setModel] = useState(null)

  useEffect(() => {
    const loader = new GLTFLoader().setPath(path)
    loader.load(
      file,
      (gltf) => {
        setModel(gltf.scene)
      },
      (xhr) => {
        logger.debug("Loading: " + path + file + ": " + (xhr.loaded / xhr.total) * 100 + "% loaded")
      },
      (error) => {
        throw new Error(error)
      },
    )
  }, [])

  return <>{model && <primitive object={model} {...props} />}</>
}
