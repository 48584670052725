import { Device } from "mediasoup-client"
import EventEmmiter from "events"
import config from "../etc/config"
import logger from "../etc/logger"

/**
 * Class MediaClient
 * =================
 *
 * A helper class to work with MediaSoup Client
 * Requires a socket client and routerRtpCapabilities
 */
class MediaClient extends EventEmmiter {
  /**
   * @param socketClient
   * @param routerRtpCapabilities
   */
  constructor(socketClient, routerRtpCapabilities) {
    super() // invoke superclass's constructor
    this.socket = socketClient
    this.routerRtpCapabilities = routerRtpCapabilities
    this.device = null
    this.sendTransports = {}
    this.receiveTransport = null
    this.consumers = {}
  }

  /**
   * Creates/returns MediaSoup Device
   *
   * @returns {Device}
   */
  async getDevice() {
    if (!this.device) {
      this.device = new Device()
      const rtpCapabilities = this.routerRtpCapabilities
      await this.device.load({ routerRtpCapabilities: rtpCapabilities })
      logger.debug("Device handler:", this.device.handlerName)
      logger.debug("Device rtpCapabilities:", this.device.rtpCapabilities)
    }

    return this.device
  }

  /**
   * @returns {Promise<null>}
   */
  async getSendTransport(name = "main") {
    if (!this.sendTransports[name]) {
      logger.debug(`sendTransport "${name}" wasn't found. Creating...`)
      this.sendTransports[name] = await this.createTransport("send")
    }

    return this.sendTransports[name]
  }

  /**
   * @returns {Promise<null>}
   */
  async getReceiveTransport() {
    if (!this.receiveTransport) {
      this.receiveTransport = await this.createTransport("receive")
    }

    return this.receiveTransport
  }

  /**
   * Create corresponding MediaSoup transport
   *
   * @param type
   * @returns {Device.createSendTransport|Device.createRecvTransport}
   */
  createTransport(type = "send") {
    return new Promise((resolve) => {
      this.socket.emit("transport-create", type, async ({ id, iceParameters, iceCandidates, dtlsParameters }) => {
        logger.debug(`Transport created: ${id} [${type}].`)
        const device = await this.getDevice()
        let transport = null
        if (type === "send") {
          transport = device.createSendTransport({
            id,
            iceParameters,
            iceCandidates,
            dtlsParameters,
            iceServers: config.media.iceServers,
            proprietaryConstraints: config.media.proprietaryConstraints,
          })
        } else {
          transport = device.createRecvTransport({
            id,
            iceParameters,
            iceCandidates,
            dtlsParameters,
            iceServers: config.media.iceServers,
          })
        }

        transport.on("connect", ({ dtlsParameters }, callback, errback) => {
          this.socket.emit("transport-connect", { transportId: transport.id, dtlsParameters }, (err) => {
            if (err) {
              errback(err)
            } else {
              logger.debug("Transport connected.")
              callback()
            }
          })
        })

        if (type === "send") {
          // Set transport "produce" event handler.
          transport.on("produce", async ({ kind, rtpParameters, appData }, callback, errback) => {
            logger.debug("RTP Parameters:", rtpParameters)
            // Here we must communicate our local parameters to our remote transport.
            this.socket.emit(
              "producer-create",
              { transportId: transport.id, kind, rtpParameters, appData },
              (err, id) => {
                if (err) {
                  errback(err)
                } else {
                  logger.debug(`Producer created: ${id} [${kind}]`)
                  callback({ id })
                }
              },
            )
          })
        }

        return resolve(transport)
      })
    })
  }

  /**
   * Closes a sendTransport by its name, which stops tracks from being streamed
   * This will close all producers/consumers related to this transport
   */
  closeTransport(type = "send", name = "main") {
    if (type === "send" && this.sendTransports[name] && !this.sendTransports[name].closed) {
      logger.debug(`sendTransport "${name}" closed.`)
      this.socket.emit("transport-close", this.sendTransports[name].id)
      this.sendTransports[name].close()
      this.sendTransports[name] = null
    }
    if (type === "receive" && this.receiveTransport && !this.receiveTransport.closed) {
      logger.debug("receiveTransport closed.")
      this.socket.off("consumer-new")
      this.socket.off("consumer-close")
      this.receiveTransport.close()
      this.receiveTransport = null
    }
  }

  /**
   * Subscribes peer to tracks from other peers
   * After subscription, you need to listen for tracks like:
   * MediaClient.on('newTrack', track => {...})
   *
   * @param subscribeStats
   * @param consume IDs of tracks that needs to be consumed (use empty array to consume all)
   * @returns {Promise<void>}
   */
  async subscribeToTracks(subscribeStats = { video: false, audio: false }, consume = []) {
    let receiveTransport = null
    if (consume) receiveTransport = await this.getReceiveTransport()
    const device = await this.getDevice()
    // Tell media server that we're ready to consume media stream
    this.socket.emit("media-ready", { rtpCapabilities: device.rtpCapabilities, consume })

    if (!consume) return

    this.socket.on("consumer-new", async (data, cb) => {
      logger.debug("Got new consumer", data)
      const consumer = await receiveTransport.consume({
        id: data.id,
        producerId: data.producerId,
        kind: data.kind,
        rtpParameters: data.rtpParameters,
        appData: data.appData,
      })
      this.consumers[consumer.id] = consumer
      consumer.on("transportclose", () => {
        logger.debug(`Consumer "${consumer.id}" closed.`)
        this.emit("removeTrack", data)
      })

      cb()
      this.emit("newTrack", consumer.track, data)

      // Stats stuff
      const id = data.appData.streamId || consumer.id
      if (subscribeStats[consumer.kind]) this.subscribeToStats(consumer, id)
    })

    this.socket.on("consumer-close", (data) => {
      const consumer = this.consumers[data.id]
      if (consumer) {
        consumer.close()
        logger.debug(`Consumer "${consumer.id}" closed:`, data)
        this.emit("removeTrack", data)
      }
    })
  }

  /**
   * @param {import('mediasoup-client').types.Producer | import('mediasoup-client').types.Consumer} source
   * @param trackId
   * @param interval
   */
  async subscribeToStats(source, trackId, interval = 1000) {
    let prevStats = null
    const kind = source.producerId ? "consumer" : "producer"
    const statsInterval = setInterval(async () => {
      if (source.closed) {
        clearInterval(statsInterval)
        return
      }
      const stats = await source.getStats()
      const calculatedStats = this.buildFromStats(stats, prevStats, kind)
      calculatedStats.id = source.id
      calculatedStats.trackId = trackId
      calculatedStats.kind = kind
      prevStats = stats
      this.emit("stats", calculatedStats)
    }, interval)
  }

  /**
   * Adds specified track to the send transport and produces it
   *
   * @param {MediaStreamTrack} track
   * @param {Object} appData
   * @param {Array<RTCRtpEncodingParameters>} encodings
   * @param codecOptions
   * @param gatherStats
   * @param transportName
   * @param stopTracks
   * @returns {import('mediasoup-client').types.Producer}
   */
  async produce({
    track,
    appData = {},
    encodings = [],
    codecOptions = {},
    gatherStats = false,
    transportName = "main",
    stopTracks = true,
  }) {
    const device = await this.getDevice()
    if (!device.canProduce(track.kind)) {
      logger.error(`Cannot produce ${track.kind}`)
      return null
    }
    const sendTransport = await this.getSendTransport(transportName)
    // This will invoke transport's "connect" event as well
    const producer = await sendTransport.produce({
      track,
      appData,
      encodings,
      codecOptions,
      stopTracks,
    })
    // Stats stuff
    const trackId = appData.streamId || producer.id
    if (gatherStats) this.subscribeToStats(producer, trackId)
    return producer
  }

  /**
   * Closes specified producer, since stops its media from streaming
   * Note, this will end the track related to the producer
   *
   * @param {import('mediasoup-client').types.Producer} producer
   */
  closeProducer(producer) {
    if (producer && producer.id && !producer.closed) {
      logger.debug(`Producer closed: ${producer.id} [${producer.kind}]`)
      this.socket.emit("producer-close", producer.id)
      producer.close()
    }
  }

  /**
   * Creates a custom stats object only with values we need
   *
   * @param stats
   * @param prevStats
   * @param {'consumer'|'producer'} sourceKind
   */
  buildFromStats(stats, prevStats, sourceKind = "producer") {
    const calculatedStats = {
      video: {},
      iceCandidate: { local: null, remote: null },
    }
    stats.forEach((report) => {
      // if (report.isRemote) return;
      // console.debug(report);
      // Add some producer specific stats.
      if (sourceKind === "producer" && report.type === "outbound-rtp") {
        calculatedStats.qualityLimitationReason = report.qualityLimitationReason
        calculatedStats.qualityLimitationResolutionChanges = report.qualityLimitationResolutionChanges
        calculatedStats.framesEncoded = report.framesEncoded
        calculatedStats.keyFramesEncoded = report.keyFramesEncoded
        calculatedStats.totalEncodeTime = report.totalEncodeTime
        calculatedStats.totalPacketSendDelay = report.totalPacketSendDelay
      }

      // Add some consumer specific stats.
      if (sourceKind === "consumer" && report.type === "inbound-rtp") {
        calculatedStats.framesDecoded = report.framesDecoded
        calculatedStats.keyFramesDecoded = report.keyFramesDecoded
        calculatedStats.totalDecodeTime = report.totalDecodeTime
        calculatedStats.totalInterFrameDelay = report.totalInterFrameDelay
        calculatedStats.jitter = report.jitter
      }

      // Add some common stats.
      if (
        (sourceKind === "producer" && report.type === "outbound-rtp") ||
        (sourceKind === "consumer" && report.type === "inbound-rtp")
      ) {
        const bytesVar = sourceKind === "producer" ? "bytesSent" : "bytesReceived"
        const now = report.timestamp
        const bytes = report[bytesVar]
        let bitrate = 0
        if (prevStats && prevStats.has(report.id)) {
          const prevReport = prevStats.get(report.id)
          bitrate = (8 * (bytes - prevReport[bytesVar])) / ((now - prevReport.timestamp) / 1000)
        }
        // calculatedStats.bytesSent = bytes;
        calculatedStats.bitrate = Math.trunc(bitrate)
        calculatedStats.timestamp = report.timestamp
        if (report.frameWidth) {
          calculatedStats.frameWidth = report.frameWidth
        }
        if (report.frameHeight) {
          calculatedStats.frameHeight = report.frameHeight
        }
        if (report.framesPerSecond) {
          calculatedStats.framesPerSecond = report.framesPerSecond
        }
      }
      if (report.type === "media-source" && report.kind === "video") {
        calculatedStats.video.width = report.width
        calculatedStats.video.height = report.height
        calculatedStats.video.framesPerSecond = report.framesPerSecond
        // If it's older browser API need to get the following stats from media-source report type.
        if (!calculatedStats.framesPerSecond) {
          calculatedStats.framesPerSecond = report.framesPerSecond
        }
      }
      if (report.type === "track" && report.kind === "video") {
        calculatedStats.video.frameWidth = report.frameWidth
        calculatedStats.video.frameHeight = report.frameHeight
        // If it's older browser API need to get the following stats from track report type.
        if (!calculatedStats.frameWidth) {
          calculatedStats.frameWidth = report.frameWidth
        }
        if (!calculatedStats.frameHeight) {
          calculatedStats.frameHeight = report.frameHeight
        }
        if (report.jitterBufferDelay) {
          calculatedStats.jitterBufferDelay = report.jitterBufferDelay
        }
      }
      if (report.type === "local-candidate" || report.type === "remote-candidate") {
        const candidateType = report.type === "local-candidate" ? "local" : "remote"
        const priority = report.priority || 0
        if (
          calculatedStats.iceCandidate[candidateType] &&
          calculatedStats.iceCandidate[candidateType].priority > priority
        ) {
          return
        }
        calculatedStats.iceCandidate[candidateType] = {
          type: report.candidateType,
          protocol: report.protocol,
          ip: report.ip || report.address,
          port: report.port,
          networkType: report.networkType,
          priority: priority,
        }
      }
    })

    return calculatedStats
  }
}

export default MediaClient
