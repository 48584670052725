import logger from "loglevel"
import config from "./config"

// Use console.debug on debug messages
const methodFactory = logger.methodFactory
logger.methodFactory = (methodName, logLevel, loggerName) => {
  if (methodName === "debug") {
    return console.debug
  }
  return methodFactory(methodName, logLevel, loggerName)
}

logger.setLevel(config.logger.level)

export default logger
