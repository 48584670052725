import React, { useRef, useEffect, useMemo } from "react"
import useImage from "use-image"
import { Image, Group } from "react-konva"

const CanvasImage = ({ src, width, height, onLoaded, ...props }) => {
  const ref = useRef(null)

  const [image] = useImage(src, "anonymous")

  const onImageLoaded = () => {
    if (image) {
      ref.current.getLayer().batchDraw()
      if (onLoaded) {
        onLoaded()
      }
    }
  }

  useEffect(onImageLoaded, [image])

  const imageElement = useMemo(
    () => <Image ref={ref} image={image} width={width} height={height} />,
    [image, width, height, ref],
  )

  return <Group {...props}>{imageElement}</Group>
}

export default CanvasImage
