import React, { useContext } from "react"
import { Box, Text, Link, Flex } from "rebass"
import { AuthContext } from "../../containers/Auth"
import { UserContext } from "../../containers/User"
import { colors } from "../../etc/theme"
import pairingPhone from "../../../images/pairing-phone.png"

const PairingCode = () => {
  const { setAuthType, pairingCode } = useContext(AuthContext)
  const { testEvents, setEvent } = useContext(UserContext)

  return (
    <Flex width="480px" height="100vh" alignItems="center" margin="0 auto" flexDirection="column" textAlign="center">
      <Box
        width="100%"
        m="25px 0"
        flex={1}
        css={{ background: `url(${pairingPhone}) no-repeat center`, backgroundSize: "contain" }}
      />

      <Text fontSize="30px" color={colors.primary} fontWeight={600}>
        Headset Pairing Code
      </Text>

      <Box
        width="70%"
        m="30px 0"
        p="8px 0 2px"
        fontSize="45px"
        css={{
          minHeight: "65px",
          background: "#fff",
          borderRadius: "2px",
          letterSpacing: "8px",
          color: "#243746",
          fontWeight: 600,
        }}
      >
        {pairingCode}
      </Box>

      <Text fontSize="24px" fontWeight={600} mb="20px">
        Open the Immertec mobile app. Tap &ldquo;More&ldquo; on the tab bar and select &ldquo;Pair Headset&ldquo;.
      </Text>

      {testEvents.length > 0 && (
        <Box fontSize="11px" opacity="0.7">
          <Text my="5px">OR</Text>
          {testEvents.map((event, i) => (
            <Box key={`event-${i}`} mb="5px">
              <Link
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  setEvent(event)
                }}
              >
                Join {event.title}
              </Link>
            </Box>
          ))}
        </Box>
      )}

      <Flex
        alignItems="center"
        color={colors.gray5}
        py={50}
        onClick={() => setAuthType(null)}
        css={{ cursor: "pointer" }}
      >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.70711 14.7071C9.31658 15.0976 8.68342 15.0976 8.2929 14.7071L4.29289 10.7071C3.90237 10.3166 3.90237 9.68342 4.29289 9.29289L8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289C10.0976 5.68342 10.0976 6.31658 9.70711 6.70711L7.41421 9L15 9C15.5523 9 16 9.44772 16 10C16 10.5523 15.5523 11 15 11H7.41421L9.70711 13.2929C10.0976 13.6834 10.0976 14.3166 9.70711 14.7071Z"
            fill={colors.gray5}
          />
        </svg>
        &nbsp;Cancel
      </Flex>
    </Flex>
  )
}

export default PairingCode
