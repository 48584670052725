import React, { useEffect, useState, createContext, useContext, useCallback } from "react"
import { AuthContext } from "./Auth"
import { enums, skipToken, useListEventsQuery } from "../libs/api"

// Context stores information about the user. This includes account details, login and room state.
export const UserContext = createContext({})

const UserContainer = ({ children }) => {
  const { authToken, isGuestMode } = useContext(AuthContext)

  const [event, setEvent] = useState(null)
  const [passcodeEventId, setPasscodeEventId] = useState(localStorage.getItem("passcodeEventId"))
  const [testEvents, setTestEvents] = useState([])

  const todayStartTime = new Date(new Date().setUTCHours(0, 0, 0, 0)).toISOString()

  const { data: events } = useListEventsQuery(
    isGuestMode || authToken ? { filter: { self: true, endAfter: todayStartTime } } : skipToken,
  )

  const handlePasscodeEventId = useCallback(
    (value) => {
      if (value) {
        localStorage.setItem("passcodeEventId", value)
      } else {
        localStorage.removeItem("passcodeEventId")
      }
      setPasscodeEventId(value)
    },
    [passcodeEventId],
  )

  useEffect(() => {
    if (!events?.length) return

    const eventTimeGap = 1000 * 60 * 60 * 24 // 1 day
    const now = Date.now()
    const testEvents = events.filter(
      (event) => event.status === enums.EventStatus.LIVE && event.startTime - now < eventTimeGap && event.endTime > now,
    )
    setTestEvents(testEvents)
  }, [events])

  return (
    <UserContext.Provider
      value={{
        event,
        isGuestMode,
        passcodeEventId,
        setEvent,
        setPasscodeEventId: handlePasscodeEventId,
        testEvents,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserContainer
