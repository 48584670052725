import React, { useRef, useMemo, useEffect, useState } from "react"
import useControllable from "./useControllable"
import * as THREE from "three"

// Todo:
// Much of this code is from overlay.js, but stripped down to only
// move the UI element. The hope is to continue to move overlay code
// into this or other hooks where it can be more easily shared.

const useCylindrical = ({
  texture,
  radius,
  height,
  thetaLength,
  verticalPosition,
  angle,
  name = "cylindrical-mesh",
  notDraggableOnCoords = [0, 0, 0, 0],
}) => {
  const { setInteractable, clearInteractable } = useControllable()
  const mesh = useRef(null)
  const boxHelper = useRef(new THREE.Box3Helper())
  const boundingBox = useRef(new THREE.Box3())
  const scale = useRef(1)
  const [dragging, setDragging] = useState(false)
  const [hasTransformed, setHasTransformed] = useState(false)

  const [origin, pointerDirection, originDirection, upVec, forwardVec, planedIntersection] = useMemo(() => {
    return [
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(),
      new THREE.Vector3(),
      new THREE.Vector3(0, 1, 0),
      new THREE.Vector3(0, 0, -1),
      new THREE.Vector3(),
    ]
  }, [])

  const createGeometry = ({ radius, height, thetaLength }) => {
    return new THREE.CylinderGeometry(radius, radius, height, 10, 1, true, -Math.PI + thetaLength / 2, -thetaLength)
  }

  const material = useMemo(() => {
    const mat = new THREE.MeshBasicMaterial()
    mat.transparent = true
    mat.side = THREE.FrontSide
    mat.map = texture
    return mat
  })

  useEffect(() => {
    const params = { radius, height, thetaLength }
    if (!mesh.current) {
      mesh.current = new THREE.Mesh(createGeometry(params), material)
      mesh.current.name = name
      mesh.current.position.y = verticalPosition
    } else {
      mesh.current.geometry.dispose()
      mesh.current.geometry = createGeometry(params)
    }

    if (!hasTransformed) {
      mesh.current.rotation.y = angle
    }
  }, [radius, height, thetaLength, angle])

  const setMeshBox = () => {
    // send bounding box to track overlaps
    mesh.current.geometry.computeBoundingBox()
    mesh.current.updateMatrixWorld(true)
    boundingBox.current.copy(mesh.current.geometry.boundingBox).applyMatrix4(mesh.current.matrixWorld)

    boxHelper.current.box = boundingBox.current
  }

  const pointerMove = ({ controller, intersection }) => {
    if (dragging && !hasTransformed) {
      setHasTransformed(true)
    }

    if (dragging && dragging.controller === controller) {
      // Resets intersection axis so we're working on the same plane as other vectors.
      planedIntersection.set(intersection.point.x, 0, intersection.point.z)
      pointerDirection.subVectors(origin, planedIntersection).normalize()

      // angle between center of overlay to pointer on overlay
      const pointerAngle = pointerDirection.angleTo(originDirection.subVectors(origin, forwardVec).normalize())

      // Calculate sign of the angle since angleTo only gives us up to 180deg
      const sign = Math.sign(
        Math.atan2(upVec.dot(originDirection.cross(pointerDirection)), originDirection.dot(pointerDirection)),
      )

      // Calculate pointer angle from UV, and apply the difference to final rotation and position.
      const overlayAngle = sign * (dragging.intersection.uv.x - 0.5) * thetaLength * scale.current

      mesh.current.rotation.y = sign * (pointerAngle + overlayAngle)
      mesh.current.position.y = intersection.point.y + (dragging.intersection.uv.y - 0.5) * -height * scale.current
    }

    if (dragging && dragging.controller === controller) {
      setMeshBox()
    }
  }

  useEffect(() => {
    if (dragging) {
      // TODO: Consider object height.
      const [x1, x2, y1, y2] = notDraggableOnCoords
      const clickX = dragging.intersection.uv.x
      const clickY = dragging.intersection.uv.y
      // Do not activate dragging if the click was made at non-dragging coordinates
      if (clickX >= x1 && clickX <= x2 && clickY >= y1 && clickY <= y2) return
      setInteractable(cylinder, "pointer-move", pointerMove)
      cylinder.layers.enableAll()
    } else {
      clearInteractable(cylinder, "pointer-move")
      cylinder.layers.disableAll()
    }
  }, [dragging])

  useEffect(() => {
    setMeshBox()

    setInteractable(mesh.current, "select-down", setDragging)
    setInteractable(mesh.current, "select-up", () => {
      setDragging(false)
    })

    return () => {
      clearInteractable(mesh.current, "select-down")
      clearInteractable(mesh.current, "select-up")
    }
  }, [])

  const cylinderGeometry = useMemo(() => {
    return new THREE.CylinderBufferGeometry(radius + 0.1, radius + 0.1, 20, 30, 30, true)
  }, [radius])

  const cylinder = useMemo(() => {
    const material = new THREE.MeshBasicMaterial({
      side: THREE.BackSide,
      color: 0xff0000,
      wireframe: true,
      visible: false,
    })

    return new THREE.Mesh(cylinderGeometry, material)
  }, [cylinderGeometry])

  const CylindricalMesh = (props) => {
    if (!mesh.current) {
      return null
    }
    return <primitive name={name} object={mesh.current} material={material} {...props} />
  }

  const CylindricalComponents = () => {
    return (
      <>
        <primitive
          object={cylinder}
          geometry={cylinderGeometry}
          name="usecylindrical-raycast-target"
          matrixAutoUpdate={false}
        />
        <box3Helper args={[boundingBox.current, Math.random() * 0xffffff]} ref={boxHelper} visible={false} />
      </>
    )
  }
  return {
    CylindricalMesh,

    // todo: CylindricalComponents should be shared between all other instances
    // of this hook. This can be moved into the Draggable context.
    CylindricalComponents,
    setMeshBox,
    mesh,
  }
}

export default useCylindrical
