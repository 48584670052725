import React, { createContext, useReducer, useEffect, useContext, useRef } from "react"
import { MediaContext } from "./Media"
import { AuthContext } from "./Auth"
import { getDeviceUUID } from "../helpers/CommonHelper"

const initialState = {
  granted: false,
  goWithout: false,
  micTrack: null,
  muted: true,
  muteLockedByModerator: false,
  error: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case "toggleMute":
      return {
        ...state,
        muted: action.payload.mute,
        muteLockedByModerator: action.payload.muteLockedByModerator,
      }
    case "granted":
      return {
        ...state,
        granted: !!action.payload,
        micTrack: action.payload,
      }
    case "error":
      return {
        ...state,
        granted: false,
        error: action.payload,
      }
    case "goWithout":
      return {
        ...state,
        goWithout: true,
      }
  }
}

export const MicContext = createContext()

// TODO: Refactor this when moving to redux toolkit.
const MicContainer = ({ children }) => {
  const { socket, mediaClient } = useContext(MediaContext)
  const [state, dispatch] = useReducer(reducer, initialState)
  const { user } = useContext(AuthContext)
  const micMuted = useRef(true)

  const request = async () => {
    // Clear the error.
    dispatch({ type: "error", payload: null })

    let stream
    try {
      stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      let audioCtx = null
      let audioDest = null
      let source = null
      let gainNode = null
      const AudioContext = window.AudioContext || window.webkitAudioContext || null

      if (AudioContext) {
        audioCtx = new AudioContext()
        audioDest = audioCtx.createMediaStreamDestination()
        source = audioCtx.createMediaStreamSource(stream)
        gainNode = audioCtx.createGain()
        source.connect(gainNode)
        gainNode.connect(audioDest)
        gainNode.gain.value = 0.8
      }

      const micTrack = stream.getAudioTracks()[0]
      dispatch({ type: "granted", payload: micTrack })

      const onTrackEnded = () => {
        micTrack.removeEventListener("ended", onTrackEnded)
        if (source) source.disconnect()
        if (gainNode) gainNode.disconnect()
        if (audioCtx) audioCtx.close()
        if (micMuted.current) {
          toggleMute(true)
        } else {
          // NOTE: For some reason the mic track gets ended itself sometimes which looks like a hardware issue.
          //       As a workaround we just request for the mic again.
          // TODO: We need to check if the headset is active (once we move to redux), otherwise it leads to mic permission error.
          toggleMute(false)
        }
      }

      micTrack.addEventListener("ended", onTrackEnded)
    } catch (e) {
      dispatch({ type: "error", payload: e.message })
      console.error("Mic permission request failed!")
      toggleMute(true)
    }
  }

  const toggleMute = (doMute, muteLockedByModerator = false, isServerResponse = false) => {
    const mute = doMute ?? !state.muted
    const isMicTrackAlive = state.micTrack?.readyState === "live"

    if (isServerResponse) {
      // Update local state if it's a response from the media server.
      dispatch({ type: "toggleMute", payload: { mute, muteLockedByModerator } })
    } else if (socket.current) {
      if (!mute && !isMicTrackAlive) {
        request()
      } else {
        socket.current.emit("peer-mute-toggle", socket.current.id, mute)
      }
    }
  }

  useEffect(() => {
    micMuted.current = state.muted
  }, [state.muted])

  useEffect(() => {
    const produce = async (track) => {
      await mediaClient.current.produce({
        track,
        appData: {
          streamId: user ? `viewer-${user.name.last}`.split(" ").join("-").toLowerCase() : `guest-${getDeviceUUID()}`,
        },
        stopTracks: false,
      })

      if (!micMuted.current && socket.current) {
        socket.current.emit("peer-mute-toggle", socket.current.id, micMuted.current)
      }
    }

    const isMicTrackAlive = state.micTrack?.readyState === "live"
    if (state.granted && isMicTrackAlive && mediaClient.current) {
      produce(state.micTrack)
    }
  }, [state.micTrack, mediaClient.current])

  useEffect(() => {
    if (!state.goWithout && state.error) {
      // User continues despite mic error
      dispatch({ type: "goWithout" })
    }
  }, [state.error, state.goWithout])

  useEffect(() => {
    if (!socket.current?.connected) {
      return
    }
    socket.current.on("peer-mute-toggle", ({ peerId, mute, muteLockedByModerator }) => {
      if (peerId === socket.current.id) {
        toggleMute(mute, muteLockedByModerator, true)
      }
    })
    socket.current.on("room-joined", ({ peers }) => {
      const peer = peers.find((p) => p.id === socket.current.id)
      // Keep local mic state muted by default.
      toggleMute(true, peer.muteLockedByModerator, true)
    })
  }, [socket.current?.connected])

  return (
    <MicContext.Provider
      value={{
        ...state,
        request,
        toggleMute: () => toggleMute(),
      }}
    >
      {children}
    </MicContext.Provider>
  )
}

export default MicContainer
