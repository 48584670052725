import React from "react"
import { Image, Box, Flex, Text } from "rebass"
import { displayFullName } from "../../helpers/displayFullName"
import { skipToken, useGetInstructorQuery } from "../../libs/api"
import { colors } from "../../etc/theme"

import type { model } from "../../libs/api"

type GuestInstructorExtended = model.GuestInstructor & { photo?: string }

type InstructorByIdProps = {
  instructors?: readonly string[]
  guestInstructors?: readonly GuestInstructorExtended[]
  photoOnly?: boolean
  size?: number
}

export const InstructorById: React.FC<InstructorByIdProps> = ({
  instructors,
  guestInstructors,
  photoOnly,
  size = 72,
}) => {
  // API:
  const { data, isLoading } = useGetInstructorQuery(instructors?.[0] ?? skipToken)

  const instructor = data ?? guestInstructors?.[0]
  const displayName = instructor?.name ? displayFullName(instructor.name, true) : ""
  const initials = displayName.split(" ")

  if (isLoading || !instructor) return <>...</>

  if (photoOnly) {
    return (
      <Box
        width={size}
        height={size}
        bg={colors.gray3}
        css={{ clipPath: `circle(${size / 2}px at center)`, overflow: "hidden" }}
      >
        {instructor?.photo ? (
          <Image
            variant="avatar"
            width={size}
            height={size}
            src={instructor.photo}
            alt={displayFullName(instructor.name)}
            css={{ objectFit: "cover" }}
          />
        ) : (
          <Flex width={size} height={size} justifyContent="center" alignItems="center" bg={colors.gray6}>
            <Text fontSize="28px" color={colors.gray12}>
              {initials[0].charAt(0)}
              {initials[1].charAt(0)}
            </Text>
          </Flex>
        )}
      </Box>
    )
  }

  return <>{displayName}</>
}
