import React from "react"
import { Group } from "react-konva"
import CanvasImage from "./CanvasImage"

const CanvasImageRounded = ({ src, onLoaded, strokeWidth = 0, strokeStyle = "#ffffff", ...props }) => {
  return (
    <Group
      x={props.x}
      y={props.y}
      clipFunc={(ctx) => {
        ctx.arc(props.width / 2, props.height / 2, props.width / 2, 0, Math.PI * 2)
        if (strokeWidth) {
          ctx.lineWidth = strokeWidth
          ctx.strokeStyle = strokeStyle
          ctx.stroke()
        }
      }}
    >
      <CanvasImage src={src} width={props.width} height={props.height} onLoaded={onLoaded} />
    </Group>
  )
}

export default CanvasImageRounded
